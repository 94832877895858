import React, { FC } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

interface IMenu {
  items: Array<{ id: string, name: string, onClickCb: () => void }>,
  open: boolean,
  anchorEl: null | HTMLElement,
  handleClose: () => void,
  row?: { [key: string]: string }
}

export const MenuComponent: FC<IMenu> = (props) => {
  const { items, open, anchorEl, handleClose, row } = props;

  const handleItemClick = (callback: (selectedItemValue: { [key: string]: string } | undefined) => void) => {
    callback(row || undefined);
    handleClose();
  };

  return (
    <div>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {items.map(item => <MenuItem key={item.id} onClick={() => handleItemClick(item.onClickCb)}>{item.name}</MenuItem>)}
      </Menu>
    </div>
  );
}
