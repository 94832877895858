import styled from "@emotion/styled";
import { Link } from "react-router-dom";

interface IFormProp {
  align?: string;
}

export const AuthWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 1rem 4rem;
`;

export const LoginHeader = styled.div`
  align-self: center;
  margin-bottom: 1rem;
`;
export const LoginInput = styled.div`
  display: block;
  margin: 0.5rem 0;
`;
export const LoginLink = styled(Link)`
  cursor: pointer;
  margin-bottom: 1rem;
  text-decoration: none;
`;

export const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  height: auto;
  align-items: ${(props: IFormProp) => (props.align ? props.align : "")};
`;
export const LoginButton = styled.div`
  margin-top: 1rem;
`;

export const EmailConfirmation = styled.form`
  display: flex;
  flex-direction: column;
`;

export const RestButton = styled.div`
  margin: 1rem 0 0 5rem;
`;

export const ResetSuccessWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
`;

export const PlatformInputWrapper = styled.div`
  display: flex;
  align-items: center;
`;
