import { FC, useState } from "react";
import { styled, Theme, CSSObject } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { ImgComponent } from "components";
import DataConnections from "asset/images/data-connections.png";
import Containers from "asset/images/containers.png";
import { Panel } from "./panel.component";
import { IContainersResponse } from "interfaces/sql-editor.interface";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerFooter = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

interface SideBarProps {
  containers: IContainersResponse[]
}

export const Sidebar: FC<SideBarProps> = ({ containers }) => {
  const [open, setOpen] = useState(true);

  const dataConnectionsSubItems = [
    {
      id: '1',
      name: 'oracle-dw',
      icon: <InboxIcon />
    },
    {
      id: '2',
      name: 'sqlserver-legacy',
      icon: <MailIcon />
    },
    {
      id: '3',
      name: 'snowflake_cloud',
      icon: <InboxIcon />
    }
  ];

  return (
    <Drawer
      variant="permanent" 
      open={open} 
      PaperProps={{
        style: {
          position: "static",
          justifyContent: 'space-between'
        }
      }}
    >
      <Panel
        text='Data Connections'
        icon={<ImgComponent src={DataConnections} size="small" />}
        open={open}
        items={dataConnectionsSubItems}
      />
      <Panel
        text='Containers'
        icon={<ImgComponent src={Containers} size="small" />}
        open={open}
        items={containers}
      />
      <DrawerFooter>
        <IconButton onClick={() => setOpen(!open)}>
          {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </DrawerFooter>
    </Drawer>
  )
}
