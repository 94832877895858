import React, { FC, useState } from "react";
import { SchemaList } from "./schema-list.component";
import {
  ControlLabel,
  SchemaListGrid,
  CustomCheckBox,
} from "../data-hub-schema.styles";
import { FormGroup } from "@mui/material";

interface ISchemaProps {
  onItemClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onTableSelect: (table: string, schemaString: string) => void;
  schemas: {
    schemaName: string;
    tables: string[];
  }[];
}

export const SchemaListContainer: FC<ISchemaProps> = ({
  onItemClick,
  onTableSelect,
  schemas,
}) => {
  const [defaultChecked, setDefaultChecked] = useState<boolean>(true);

  return (
    <SchemaListGrid>
      <FormGroup>
        <ControlLabel
          label="Name"
          control={
            <CustomCheckBox
              checked={defaultChecked}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setDefaultChecked(() => e.target.checked)
              }
            />
          }
        />
      </FormGroup>
      {schemas.map(({ schemaName, tables }) => (
        <SchemaList
          schemaName={schemaName}
          tables={tables}
          allChecked={defaultChecked}
          onItemClick={onItemClick}
          onTableSelect={onTableSelect}
        />
      ))}
    </SchemaListGrid>
  );
};
