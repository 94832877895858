import React, { FC, useState, useEffect } from 'react';
import { OrganisationWrapper, NewOrganisationButton } from './organisation.style';
import { AddIcon } from 'asset/svg-icon/add-icon.component';
import { Button } from 'components';
import { Loader } from 'components/mui-components/mui-components.index';
import { TableComponent } from 'components/table/table.component';
import { useAppDispatch } from 'store/hooks';
import { setIsError } from 'store/slice/error.slice';
import { useOrganisationsListQuery } from 'store/api/organisations-api.slice';
import { AddOrganisation } from './organisation-add.component';

export const Organisations: FC = () => {
  const dispatch = useAppDispatch();
  const [showAddOrganisation, setShowAddOrganisation] = useState<boolean>(false);
  const { data: organisations, isLoading, isError, isSuccess } = useOrganisationsListQuery(undefined);

  useEffect(() => {
    if (isError) dispatch(setIsError(isError));
  }, [isError, dispatch]);

  return (
    <React.Fragment>
      <OrganisationWrapper>
        <NewOrganisationButton>
          <Button
            type="button"
            buttonType="primary"
            title={<><AddIcon /> New Organisation</>}
            space="medium"
            onClickCb={() => setShowAddOrganisation(true)}
          />
        </NewOrganisationButton>
        {isLoading && <Loader />}
        {isSuccess && <TableComponent title='Organisations' tableData={organisations} />}
      </OrganisationWrapper>
      <AddOrganisation open={showAddOrganisation} setOpen={setShowAddOrganisation} />
    </React.Fragment>
  )
}
