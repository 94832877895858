import React, { FC } from 'react';
import { Button } from 'components';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';

interface ISuccess {
  show: boolean
}

export const Success: FC<ISuccess> = ({ show }) => {
  const handleClose = () => {};

  return (
    <Dialog
      open={show}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
        <DialogContentText id="alert-dialog-description" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 5 }}>
          <CheckBoxRoundedIcon color='primary' sx={{ fontSize: 70, color: '#0298f2', marginRight: 3 }} /> Environment successfully deployed
        </DialogContentText>
        <Button 
          onClickCb={handleClose} 
          type="submit"
          buttonType="primary"
          title="Go to Admin Portal"
          space="large" />
      </DialogContent>
    </Dialog>
  )
}
