import React, { FC } from "react";
import { MultiElementTable } from "components/multi-element-table/multi-element-table.component";
import { TabsComponent } from "components/tabs/tabs.component";
import { IPreviewData, ITableColumn } from "interfaces/data-source.interface";

interface ISchemaDetails {
  schemaDetails: ITableColumn[];
  tableDetails: IPreviewData;
}

export const SchemaDetails: FC<ISchemaDetails> = ({
  schemaDetails,
  tableDetails,
}) => {
  const { columns, rows } = tableDetails;
  const mappedRowData = rows.map(({ row }) =>
    row.reduce((acc: { [key: string]: any }, curr, index) => {
      acc[columns[index]] = curr;
      return acc;
    }, {})
  );

  const tabs = [
    {
      title: "Schema View",
      content: (
        <MultiElementTable
          data={schemaDetails || []}
          columns={Object.keys(schemaDetails[0]).map((title) => ({ title }))}
          itemsPerPage={10}
        />
      ),
    },
    {
      title: "Table View",
      content: (
        <MultiElementTable
          data={mappedRowData}
          columns={columns.map((title) => ({ title }))}
          itemsPerPage={10}
        />
      ),
    },
  ];

  return (
    <div style={{ overflowX: "scroll", minHeight: "38rem" }}>
      <TabsComponent tabs={tabs} />
    </div>
  );
};
