import styled from "@emotion/styled";

export const ZForm = styled.form`
  width: 100%;
  padding: 1rem;
`;
export const ZFormUserSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.15rem;
  margin-bottom: 0.75rem;
`;

export const ButtonSection = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 95%;
  gap: 0.5rem;
`;

export const InputSection = styled.div`
  width: 100%;
  & > input {
    padding: 12px;
  }
`;
