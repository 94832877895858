import styled from "@emotion/styled";

interface IleftMenuStyle {
  active: boolean;
}

export const SidebarWrapper = styled.div`
  order: 0;
  flex: 0 0 calc(18%);
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #f5f5f5;
`;

export const StyledSidebar = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  transition: box-shadow .1s ease, transform .1s ease, -webkit-box-shadow .1s ease, -webkit-transform .1s ease;
`;

export const LeftMenuItemContainer = styled.div`
  padding: 0.75em 1em;
  display: block;
  transition: background-color 0.15s;
  border-radius: 5px;
  background-color: ${({ active }: IleftMenuStyle) => (active ? "#096c2" : "")};
`;

export const LeftMenuItemSection = styled.div`
  display: flex;
  font-size: 1.2em;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
`;

export const LeftMenuChildContent = styled.div`
  padding-top: 0.25em;
  height: auto;
  overflow: hidden;
`;
