import azure_blob from "asset/images/data-sources/azure_blob.png";
import my_sql_logo from "asset/images/data-sources/my_sql.png";
import oracle_logo from "asset/images/data-sources/oracle.png";
import snowflake_logo from "asset/images/data-sources/snowflake.png";
import sales_force_logo from "asset/images/data-sources/sales_force.png";
import aws_logo from "asset/images/data-sources/AWS.png";
import azure_logo from "asset/images/data-sources/Azure.png";
import elastic_logo from "asset/images/data-sources/elastic.png";
import glue_logo from "asset/images/data-sources/glue.png";
import google_logo from "asset/images/data-sources/google.png";
import greenp_logo from "asset/images/data-sources/greenp.png";
import hive_logo from "asset/images/data-sources/hive.png";
import ibm_logo from "asset/images/data-sources/ibm.png";
import kalka_logo from "asset/images/data-sources/kalka.png";
import local_logo from "asset/images/data-sources/local.png";
import marketo_logo from "asset/images/data-sources/marketo.png";
import monday_logo from "asset/images/data-sources/monday.png";
import mongo_logo from "asset/images/data-sources/mongo.png";
import mSQL_logo from "asset/images/data-sources/mSQl.png";
import mycrm_logo from "asset/images/data-sources/mycrm.png";
import netezza_logo from "asset/images/data-sources/netezza.png";
import openW_logo from "asset/images/data-sources/openW.png";
import otherJD from "asset/images/data-sources/otherJD.png";
import postgres from "asset/images/data-sources/postgres.png";
import redshift from "asset/images/data-sources/redshift.png";
import restAPI from "asset/images/data-sources/restAPI.png";
import snowflick from "asset/images/data-sources/snowflick.png";
import sqlite from "asset/images/data-sources/sqlite.png";
import teradata from "asset/images/data-sources/teradata.png";
import vertica from "asset/images/data-sources/vertica.png";
import xero from "asset/images/data-sources/xero.png";
import iceberg from "asset/images/data-sources/iceberg.png";
import deltaio from "asset/images/data-sources/deltaio.png";
import localFileSystem from "asset/images/data-sources/local.png";
import hadoop from "asset/images/data-sources/hadoop.png";
import cassandra from "asset/images/data-sources/cassandra.png";
import sapHana from "asset/images/data-sources/sap.png";
import mySql from "asset/images/data-sources/my_sql.png";
import dynamics from "asset/images/data-sources/ms_dynamics_365.png";
interface IDSIcon {
  [key: string]: string;
}

export const DataSourcIconList: IDSIcon = {
  Db2: ibm_logo,
  GenericJdbc: otherJD,
  Greenplum: greenp_logo,
  Mongodb: mongo_logo,
  MsSql: mSQL_logo,
  MySql: mySql,
  Netezza: netezza_logo,
  Oracle: oracle_logo,
  Postgres: postgres,
  Redshift: redshift,
  SnowFlake: snowflake_logo,
  Sqlite: sqlite,
  Teradata: teradata,
  Vertica: vertica,
  AzureBlob: azure_blob,
  SalesForce: sales_force_logo,
  AWS: aws_logo,
  ElasticSearch: elastic_logo,
  Glue: glue_logo,
  Google: google_logo,
  Hive: hive_logo,
  Kafka: kalka_logo,
  LocalFileSystem: local_logo,
  Marketo: marketo_logo,
  MyCrm: mycrm_logo,
  OpenWeatherApi: openW_logo,
  monday: monday_logo,
  xero: xero,
  SnowFlick: snowflick,
  SapHana: sapHana,
  Cassandra: cassandra,
  Iceberg: iceberg,
  Deltaio: deltaio,
  localFileSystem: localFileSystem,
  Hadoop: hadoop,
  Restapi: restAPI,
  Dynamics: dynamics,
};
