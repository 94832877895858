import React, { FC } from "react";
import { TextareaWrapper } from "./textarea.style";
import type { Space } from "utils/style-type";

interface ITextareaBox {
  placeholder: string;
  value: string;
  onChangeCb: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  size: Space;
  rows?: number;
  cols?: number;
  onBlurCb?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  isInValid?: boolean;
  name?: string;
}

export const TextareaBox: FC<ITextareaBox> = ({
  placeholder,
  value,
  onChangeCb,
  size,
  rows = 3,
  cols = 30,
  onBlurCb,
  isInValid,
  name,
}) => (
  <TextareaWrapper
    rows={rows}
    cols={cols}
    placeholder={placeholder}
    value={value}
    onChange={onChangeCb}
    onBlur={onBlurCb}
    space={size}
    isInValid={isInValid}
    name={name}
  />
);
