import styled from "@emotion/styled";

export const SsoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  margin: 1.5rem 0;
`;

export const SsoHeader = styled.div`
  position: relative;
`;

export const SsoBorder = styled.div`
  border: 1px solid #d7d7d7;
  width: 35rem;
`;
export const SsoHeaderText = styled.div`
  box-sizing: border-box;
  position: absolute;
  z-index: 99;
  background: #fff;
  top: -16px;
  left: 46%;
  padding: 0.2rem 0.5rem;
`;
