import styled from "@emotion/styled";
import { Dialog } from "@mui/material";

interface IStyleProp {
  type: string;
}

export const StatusDialog = styled(Dialog)(({ type }: IStyleProp) => ({
  "& .MuiPaper-root": {
    backgroundColor: `${type}`,
    padding: "8px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "8px",
    minWidth: "10rem",
  },
}));
