import { FC } from 'react';
import { DockContainer, DockBody } from './query-result-dock.style';
import { TabsComponent } from 'components/tabs/tabs.component';
import { Result } from './result.component';
import { QueryPlan } from './query-plan.component';
import { History } from './history.component';

interface QueryResultDockProps {
  sql: string
}

export const QueryResultDock: FC<QueryResultDockProps> = ({ sql }) => {
  return (
    <DockContainer>
      <DockBody>
        <TabsComponent
          tabs={[
            { title: "Run", content: <Result sql={sql} /> },
            { title: "Query Plan", content: <QueryPlan sql={sql} /> },
            { title: "History", content: <History /> }
          ]}
        />
      </DockBody>
    </DockContainer>
  );
}
