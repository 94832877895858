import { FC, useEffect } from "react";
import { CssBaseline } from "@mui/material";
import { Sidebar } from "./sidebar/sidebar.component";
import { Builder } from "./builder/builder.component";
import { useAppDispatch } from "store/hooks";
import { setIsError } from "store/slice/error.slice";
import { useGetContainersListQuery } from "store/api/sql-editor.api";
import { SqlBuilderPageWrapper } from "./sql-builder.style";

export const SqlBuilder: FC = () => {
  const dispatch = useAppDispatch();
  const {
    data: containers,
    isLoading: isContainersLoading,
    isError: isContainersError,
  } = useGetContainersListQuery(undefined);

  useEffect(() => {
    dispatch(setIsError(isContainersError))
  }, [isContainersError])

  return (
    <SqlBuilderPageWrapper>
      <CssBaseline />
      {!isContainersLoading && <Sidebar containers={containers || []} />}
      {!isContainersLoading && <Builder />}
    </SqlBuilderPageWrapper>
  )
}
