import styled from "@emotion/styled";
import Box from "@mui/material/Box";

export const EditorWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  overflow: hidden;
  width: 100%;
  height: 100%;
`;

export const EditorContainer = styled.div`
  order: 0;
  flex: 0 0 calc(65% - 1.3px);
`
