import styled from "@emotion/styled";
import { Dialog, DialogContent } from "@mui/material";

interface IDhProp {
  selected: boolean;
}

export const DSDialog = styled(Dialog)(() => ({
  "& .MuiPaper-root": {
    padding: "20px",
    minHeight: "40rem",
    overflowY: "hidden",
  },
}));

export const DSDialogContent = styled(DialogContent)(() => ({
  "& .MuiDialogContent-root": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export const DHTypeButtons = styled.div`
  display: flex;
  gap: 0.5rem;
  justify-content: space-evenly;
`;

export const DataSourceItemContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr; /* Two rows with equal height */
  grid-gap: 4px;
  cursor: pointer;
  align-items: baseline;
  justify-items: center;
  padding: 4px;

  &:hover {
    border: 1px solid #22a5f7;
    border-radius: 5px;
  }

  background: ${({ selected }: IDhProp) =>
    selected ? "#f2f2f2" : "transparent"};
  border: ${({ selected }: IDhProp) =>
    selected ? "1px solid #f2f2f2" : "none"};
`;

export const DSItemContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(8, 1fr); /* 8 items in each row */
  grid-template-rows: repeat(4, auto); /* 4 rows */
  gap: 10px;
  margin: 8px 0;
`;

export const DSFormContainer = styled.div`
  display: grid;
  grid-template-rows: auto;
  gap: 10px;
  align-items: baseline;
  justify-items: center;
  padding: 16px;
`;
