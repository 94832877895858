import { FC, useEffect, useState } from "react";
import { useNavigate, Outlet, useParams, useLocation } from "react-router-dom";
import { useGetOrgConfigQuery } from "store/api/orgconfig-api.slice";
import { Loader } from "components/mui-components/mui-components.index";
import { useAppDispatch } from "store/hooks";
import { setOrgDetails } from "store/slice/org-config.slice";
import { AuthProvider } from "../login/auth-provider/auth-provider.component";
import { setErrorMessage, setIsError } from "store/slice/error.slice";

export const OrganisationComponent: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [skip, setSkip] = useState<boolean>(false);
  const userToken = localStorage.getItem("idToken");

  const { org_slug } = useParams();
  const { pathname } = useLocation();

  const {
    data: orgDetails,
    isLoading,
    isSuccess,
    isError,
  } = useGetOrgConfigQuery({ org_slug }, { skip });

  const loginMode = localStorage.getItem("loginMode");

  useEffect(() => {
    if (isError) {
      dispatch(setIsError(true));
      dispatch(setErrorMessage("Organisation not Found ! Please Try again"));
    }

    if (isSuccess) {
      const path = pathname.split("/").pop();
      const { orgId, forgetPasswordEnabled, ssoLoginInfos } = orgDetails;

      localStorage.setItem("orgId", JSON.stringify(orgId));

      dispatch(
        setOrgDetails({
          forgetPasswordEnabled,
          orgId,
          ssoLoginInfos,
        })
      );
      setSkip(() => true);

      if (path === "reset-password") return;

      if (!userToken && !loginMode) {
        navigate("auth/login");
      } else {
        navigate("home");
      }
    }
  }, [isSuccess, isError, orgDetails, userToken, loginMode]);

  return (
    <AuthProvider>
      {isLoading && <Loader />}
      <Outlet />
    </AuthProvider>
  );
};
