import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query";
import {
  setAuthToken,
  setEnableRefresh,
  setLogOut,
} from "../slice/login.slice";
import { IAuthResponse } from "../../interfaces/auth-response.interface";
import { RootState } from "store/store";

const BASE_URL = process.env.NODE_ENV === 'production' ? (window as any)?.env.REACT_APP_API_BASE_URL : process.env.REACT_APP_API_BASE_URL;

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: (headers, getState) => {
    headers.set("X-Request-ID", "f640eb45-05ce-443c-8cd4-bdb4d45cad01");
    headers.set("X-Org-Id", JSON.parse(localStorage.getItem("orgId") || ""));
    headers.set(
      "Authorization",
      `Bearer ${JSON.parse(localStorage.getItem("idToken") || "")}`
    );

    return headers;
  },
});

const baseRefreshQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: (headers) => {
    headers.set("X-Request-ID", "f640eb45-05ce-443c-8cd4-bdb4d45cad01");
    headers.set("X-Org-Id", JSON.parse(localStorage.getItem("orgId") || ""));
    headers.set(
      "Authorization",
      `Bearer ${JSON.parse(localStorage.getItem("refreshToken") || "")}`
    );

    return headers;
  },
});

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result?.error?.status === 404) {
    const loginMode = localStorage.getItem("loginMode");
    let refreshResult: any;

    if (loginMode === "outlook") {
      console.log("logging out for outlook");
      api.dispatch(setEnableRefresh(true));
    } else {
      console.log("sending refresh token");
      // send refresh token to get new access token
      refreshResult = await baseRefreshQuery("auth/refresh", api, extraOptions);
      console.log(refreshResult);
    }

    if (refreshResult?.data) {
      // store the new token
      api.dispatch(setAuthToken(refreshResult?.data as IAuthResponse));
      console.log(refreshResult);
      // retry the original query with new access token
      result = await baseQuery(args, api, extraOptions);
    } else {
      const state = api.getState() as RootState;
      console.log("state", state);
      if (state.loginHeader.loginMode === "outlook") {
        console.log("logging out for outlook");
        api.dispatch(setEnableRefresh(true));
      } else {
        api.dispatch(setLogOut());
      }
    }
  }

  return result;
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Containers'],
  endpoints: (builder) => ({}),
});
