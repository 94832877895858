import React, { FC, useEffect, useState } from "react";
import {
  PublicClientApplication,
  EventType,
  AccountInfo,
  InteractionStatus,
  AuthenticationResult,
} from "@azure/msal-browser";
import { MsalProvider, useMsal } from "@azure/msal-react";
import { getOutlookConfig } from "../../../../utils/outlook-config";
import { useAppSelector } from "../../../../store/hooks";
import { useParams, useNavigate, useLocation } from "react-router-dom";

export const MsalOutlookProvider: FC<any> = ({ children }) => {
  const { accounts } = useMsal();
  const navigate = useNavigate();

  const location = useLocation();
  const [authResult, setAuthResult] = useState<AuthenticationResult | null>(
    null
  );
  const { orgOutlookConfig } = useAppSelector((state) => state.orgSlice);
  const { org_slug } = useParams();

  const msalConfig = getOutlookConfig(orgOutlookConfig || {}, org_slug || "");

  const msalInstance = new PublicClientApplication(msalConfig);
  // Default to using the first account if no account is active on page load

  const instancInit = async () => msalInstance.initialize();

  instancInit();

  const tokenExpiry = localStorage.getItem("expiry") as Date | null;

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  function calculateTimeLeft() {
    const expirationTime = new Date(tokenExpiry || 0).getTime();
    const difference = expirationTime - new Date().getTime();
    return Math.max(0, Math.floor(difference / 1000)); // Convert milliseconds to seconds and ensure it's not negative
  }

  // Update time left every second
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const acquireMsalToken = async () => {
    console.log("Refreshing token");
    try {
      instancInit();
      const account = msalInstance.getActiveAccount() || accounts[0];
      const tokenRequest = {
        account: account,
        scopes: ["User.read"],
      };
      const token = await msalInstance.acquireTokenSilent(tokenRequest);
      console.log("Token Acquired:", token.accessToken);
      localStorage.setItem("idToken", JSON.stringify(token.idToken));
    } catch (error) {
      console.error("Failed to refresh token:", error);
    }
  };

  if (
    !msalInstance.getActiveAccount() &&
    msalInstance.getAllAccounts().length > 0
  ) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }

  msalInstance.addEventCallback((event: any) => {
    if (
      (event.eventType === EventType.LOGIN_SUCCESS ||
        event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
        event.eventType === EventType.SSO_SILENT_SUCCESS) &&
      event.payload.account
    ) {
      msalInstance.setActiveAccount(event.payload.account);
    }
  });

  return <MsalProvider instance={msalInstance}>{children}</MsalProvider>;
};
