import styled from "@emotion/styled";

export const Table = styled.table`
  width: 100%;
  overflow-y: auto;
  border-bottom: 1px solid #d7d7d7;
`;

export const PaginationWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 0.5rem;
`;

export const MultiElementWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
`;


export const ActionWrapper = styled.div`
cursor : pointer;
`