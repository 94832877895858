import styled from "@emotion/styled";

interface InputProp {
  space: string;
  isInValid?: boolean;
}

const getWidth = (size: string) => {
  switch (size) {
    case "small":
      return "8rem";
    case "medium":
      return "12rem";
    case "large":
      return "16rem";
    case "full":
      return "100%";
    case "half":
      return "50%";
    default:
      return "10rem";
  }
};

export const InputWrapper = styled.input`
  display: block;
  width: ${(props: InputProp) => getWidth(props.space)};
  border: 1px solid
    ${(props: InputProp) => (props.isInValid ? "#e0001b" : "#797979")};
  padding: 0.35rem;
  border-radius: 5px;
`;
