import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import { App } from "app.component";
import { LoginContainer } from "pages/login/login-container.component";
import { LoginFormComponent } from "pages/login/login-form/login-form.component";
import { OktaLogin } from "pages/okta/okta-login/okta-login.component";
import { OrganisationComponent } from "pages/organisation/organisation.component";
import { Admin } from "pages/admin/admin.component";
import { EmailReset } from "pages/login/password-reset/emai-reset/email-reset.component";
import { PasswordReset } from "pages/login/password-reset/password-reset.component";
import { ResetSuccess } from "pages/login/password-reset/reset-success/reset-success.component";
import { Platform } from "pages/platform/platform.component";
import { PlatformAuthContainer } from "pages/platform/auth/auth.container.component";
import { PlatformLogin } from "pages/platform/auth/login/platform-login.component";
import { PlatformForgetPassword } from "pages/platform/auth/password-reset/forget-password/forget-password.component";
import { PlatformResetPassword } from "pages/platform/auth/password-reset/reset-password/reset-password.component";
import { PlatformResetPasswordSuccess } from "pages/platform/auth/password-reset/reset-success/reset-success.component";
import { Organisations } from "pages/platform/organisations/organisations-list.component";
import { PlatformOnboarding } from "pages/platform/auth/onboarding/platform-onboarding.component";
import { OutlookAuth } from "pages/login/outlook/outlook.component";
import { ErrorWrapper } from "components/error/error-wrapper.component";
import { HomeComponent } from "pages/home/home.component";
import { LandingPageComponent } from "pages/main-landing-page/landingpage.component";
import { ToolsComponent } from "pages/tools/tools.component";
import { DataHubComponent } from "pages/data-hub/data-hub.component";
import { DataProductsComponent } from "pages/data-products/data-prouducts.component";
import { WorkbenchComponent } from "pages/workbench/workbench.component";
import { SqlEditor } from "pages/workbench/sql-editor/sql-editor.component";
import { SqlBuilder } from "pages/workbench/sql-builder/sql-builder.component";
import { GovernanceComponent } from "pages/governance/governance.component";
import { LakeHouseComponent } from "pages/lakehouse/lakehouse.component";
import { GenzComponent } from "pages/genZ/genz.component";
import { ConfigureSSO } from "pages/admin/configure-sso/configure-sso.component";
import { UserGroup } from "pages/admin/user-group/user-group.component";

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />} errorElement={<ErrorWrapper />}>
      <Route
        path="org/:org_slug"
        element={<OrganisationComponent />}
        errorElement={<ErrorWrapper />}
      >
        <Route path="auth" element={<LoginContainer />}>
          <Route path="login" element={<LoginFormComponent />} />
          <Route path="okta" element={<OktaLogin />} />
          <Route path="outlook" element={<OutlookAuth />} />
          <Route path="forget-password" element={<EmailReset />} />
          <Route path="reset-password" element={<PasswordReset />} />
          <Route path="reset-password-success" element={<ResetSuccess />} />
        </Route>
        <Route path="home" element={<HomeComponent />}>
          <Route path="" element={<LandingPageComponent />} />
          <Route path="data-hub" element={<DataHubComponent />} />
          <Route path="develop" element={<WorkbenchComponent />} />
          <Route path="develop/sql-editor" element={<SqlEditor />} />
          <Route path="data-products" element={<DataProductsComponent />} />
          <Route path="develop/sql-builder" element={<SqlBuilder />} />
          <Route path="data-products" element={<DataHubComponent />} />
          <Route path="governance" element={<GovernanceComponent />} />
          <Route path="lakehouse" element={<LakeHouseComponent />} />
          <Route path="genz" element={<GenzComponent />} />
          <Route path="admin" element={<Admin />}>
            <Route path="user-groups" element={<UserGroup />} />
            <Route path="sso" element={<ConfigureSSO />} />
          </Route>
          <Route path="tools" element={<ToolsComponent />} />
        </Route>
        <Route path="admin" element={<HomeComponent />} />
      </Route>
      <Route path="platform">
        <Route path="auth" element={<PlatformAuthContainer />}>
          <Route path="login" element={<PlatformLogin />} />
          <Route path="forget-password" element={<PlatformForgetPassword />} />
          <Route path="reset-password" element={<PlatformResetPassword />} />
          <Route path="reset-password-success" element={<PlatformResetPasswordSuccess />} />
          <Route path="onboarding" element={<PlatformOnboarding />} />
        </Route>
        <Route element={<Platform />}>
          <Route path="organisations" element={<Organisations />} />
        </Route>
      </Route>
    </Route>
  )
);
