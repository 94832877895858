import { IFormTemplate } from "../../interfaces/form-template.interface";
export interface IProviderFormControl {
  label: string;
  placeholder: string;
  value: string;
  type: string;
  regex: RegExp;
  key: string;
}

interface IProviderForm {
  [key: string]: IFormTemplate[];
}

interface IProviderName {
  [key: string]: string;
}

export const SSOProviderTemplates: IProviderForm = {
  "Okta Auth": [
    {
      label: "Name",
      name: "name",
      type: "input",
    },
    {
      label: "Okta Domain",
      name: "oktaDomain",
      type: "input",
    },
    {
      label: "Okta Client Id",
      name: "oktaClientId",
      type: "input",
    },
    {
      label: "User Level",
      name: "userLevel",
      type: "input",
    },
  ],
  "Office 365 (Auth)": [
    {
      label: "Name",
      name: "name",
      type: "input",
    },
    {
      label: "Outlook Client Id",
      name: "outlookClientId",
      type: "input",
    },
    {
      label: "Outlook Tenant Id",
      name: "outlookTenantId",
      type: "input",
    },
    {
      label: "User Level",
      name: "userLevel",
      type: "input",
    },
  ],
};

export enum PROVIDER_TEXT {
  TEXT1 = "Allow your Zetaris organisation’s authentication handling to be passed to your existing provider.",
  TEXT2 = "Users that are allowed access to the Zetaris application within their SSO identity provider, would be able to access their Zetaris organisation.",
}

export const ProviderName: IProviderName = {
  "Okta Auth": "Okta",
  "Office 365 (Auth)": "Outlook",
};
