import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Security } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { RootState } from "../../../../store/store";
import { useAppSelector } from "../../../../store/hooks";
import { initOktaConfig } from "../../../../utils/constants/okta-constants";
import { getOktaConfig } from "../../../../utils/okta-config";

export const OktaAuthProvider: FC<any> = ({ children }) => {
  const navigate = useNavigate();
  const { orgOktaConfig } = useAppSelector(
    (state: RootState) => state.orgSlice
  );

  const oktaAuth = new OktaAuth(getOktaConfig(orgOktaConfig || initOktaConfig));

  const customAuthHandler = () => {
    navigate("auth/okta");
  };

  const restoreOriginalUri = async (_oktaAuth: any, originalUri: string) => {
    navigate(toRelativeUrl(originalUri || "", window.location.origin));
  };
  
  return (
    <Security
      oktaAuth={oktaAuth}
      onAuthRequired={customAuthHandler}
      restoreOriginalUri={restoreOriginalUri}
    >
      {children}
    </Security>
  );
};
