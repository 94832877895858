import React, { FC, useEffect, createContext, useContext } from "react";
import { useNavigate } from "react-router-dom";

interface IZetProvider {
  children: React.ReactNode;
}

const ZetContext = createContext({});

// Custom hook to use the Zet context
export const useZet = () => useContext(ZetContext);

export const ZetProvider: FC<IZetProvider> = ({ children }) => (
  <ZetContext.Provider value={{}}>{children}</ZetContext.Provider>
);
