import { FC } from "react";
import { SidebarWrapper, StyledSidebar } from "./sidebar.style";
import { LeftMenuItem } from "./left-menu-item/left-menu-item.component";
import { ILeftMenu } from "interfaces/left-menu-item.interface";

interface ISidebarWrapperProps {
  menuItems: ILeftMenu[];
}

export const Sidebar: FC<ISidebarWrapperProps> = ({ menuItems }) => (
  <SidebarWrapper>
    <StyledSidebar>
      {menuItems.map((item, index) => (
        <LeftMenuItem key={index} {...item} />
      ))}
    </StyledSidebar>
  </SidebarWrapper>
);
