import { apiSlice } from "./api.slice";
import { IDataHubListResponse } from "interfaces/datahub-list.interface";
import {
  IDSPreviewPayload,
  ISchemaPreviewRespomse,
} from "interfaces/data-source.interface";
import { create } from "domain";

export const DataSourceListSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getDataSourceList: build.query<IDataHubListResponse[], any>({
      query: () => ({
        url: "/datasource/datasources",
        method: "GET",
      }),
      keepUnusedDataFor: 60,
    }),
    previewSchema: build.mutation<ISchemaPreviewRespomse, IDSPreviewPayload>({
      query: (payload) => ({
        url: `/datasource/schema-preview`,
        method: "POST",
        body: payload,
      }),
    }),
    createDataSource: build.mutation<any, any>({
      query: (payload) => ({
        url: `/datasource/datasources`,
        method: "POST",
        body: payload,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetDataSourceListQuery,
  usePreviewSchemaMutation,
  useCreateDataSourceMutation,
} = DataSourceListSlice;
