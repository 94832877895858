import { FC, useState, useEffect, FormEvent } from 'react'
import { EmailConfirmation, LoginHeader, LoginInput, RestButton } from '../../login.style'
import { emailConfirmationFields } from '../../../../utils/login-fields'
import { Button, InputBox, Text } from '../../../../components'
import { RESET } from '../../../../utils/constants/message';
import { useResetMailMutation } from '../../../../store/api/reset-password-api.slice';
import { EMAIL_REGEX } from '../../../../utils/constants/validation';


export const EmailReset: FC = () => {
    const [email, setEmail] = useState<string>('');
    const [isEmailValid, setIsEmailValid] = useState<boolean>(false);
    
    const [ createPost, {isError, isLoading, isSuccess}] = useResetMailMutation();

    useEffect(() =>{
        console.log( {isError, isLoading, isSuccess})
    }, [isError, isLoading, isSuccess])

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        createPost({email : email})
        console.log("is", isSuccess)
    }

    const handleInput = (e: React.ChangeEvent<HTMLInputElement>, title: string) => {
        const { value } = e.target;
        setEmail(() => value);
        setIsEmailValid(() => EMAIL_REGEX.test(email));
    };

    return (
        <>
            {isSuccess ? (
                <Text size="small" weight={200} text={RESET.EMAIL_CONFIRMATION} />
            ) : (
                <><LoginHeader>
                    <Text size="medium" weight={200} text={RESET.EMAIL_HEADER} />
                </LoginHeader><EmailConfirmation onSubmit={handleSubmit}>

                        {emailConfirmationFields.map((field) => (
                            <LoginInput key={field.title}>
                                <Text size={field.textSize} weight={field.weight} text={field.title} />
                                <InputBox
                                    type={field.inputType}
                                    size={field.inputSize}
                                    placeholder=""
                                    value={email}
                                    onChangeCb={(e) => handleInput(e, field.title)} />
                            </LoginInput>
                        ))}
                         {isError && email ? (<Text size="small" color='#e0001b' weight={200} text={RESET.EMAIL_DOES_NOT_EXIST} />): ("")}
                        <RestButton>
                            <Button
                                type="submit"
                                buttonType={isEmailValid ? 'primary' : 'disabled'}
                                title="Reset"
                                space="small" />
                        </RestButton>
                    </EmailConfirmation></>
            )}
           
        </>
    );
};

