import React, { FC, useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { Button, Text, InputBox, SelectBox, TextareaBox } from 'components';
import { FieldWrapper, SelectWrapper } from './save-dialog.style';
import { IContainersResponse } from 'interfaces/sql-editor.interface';
import { CreateContainer } from './create-container/create-container-dialog.component';
import { useAppDispatch } from 'store/hooks';
import { useSaveSqlScriptMutation } from 'store/api/sql-editor.api';
import { setIsError } from 'store/slice/error.slice';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

interface SaveSqlProps {
  open: boolean,
  setOpen: (open: boolean) => void,
  containers: IContainersResponse[],
  sqlScript: string
}

type Inputs = {
  name: string
  description: string
  sql: string
  containerId: string
}

export const SaveSql: FC<SaveSqlProps> = ({
  open, setOpen, containers, sqlScript
}) => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isCreateContainerOpen, setIsCreateContainerOpen] = useState<boolean>(false);
  const handleClose = () => setOpen(false);

  const [
    saveSqlScript,
    {
      data: saveSqlResponse,
      isLoading: isSaveScriptLoading,
      isError: isSaveScriptError,
      isSuccess: isSaveScriptSuccess
    }
  ] = useSaveSqlScriptMutation();

  const {
    control,
    handleSubmit,
  } = useForm<Inputs>({
    defaultValues: {
      name: "",
      description: "",
      sql: "",
      containerId: ""
    },
  })

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    // add the sql script to the form data
    data.sql = sqlScript;

    const editorId = searchParams.get('id');
    if (editorId) {
      // update the script here in case of edit
    }

    // else create a new script
    if (sqlScript) saveSqlScript(data)
  }

  useEffect(() => {
    if (isSaveScriptSuccess) {
      if (saveSqlResponse.id) {
        setSearchParams({
          id: saveSqlResponse.id
        })
      }
      handleClose();
    }
    if (isSaveScriptError) dispatch(setIsError(isSaveScriptError));
  }, [isSaveScriptSuccess, isSaveScriptError])

  const getContainerOptions = () => {
    const options = [
      {
        id: 'RbJ5eEhCLM',
        label: 'Select Container',
        value: ''
      }
    ]

    containers.map(container => options.push(
      {
        id: container.id,
        label: container.name,
        value: container.id
      }
    ))

    return options;
  }

  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth='sm'
      >
        <DialogTitle id="customized-dialog-title" sx={{ textAlign: 'center' }}>
          <Text size='large' weight='normal' text='Save SQL Script' marginBottom={0.01} className='align-center' />
        </DialogTitle>
        <DialogContent dividers>
          <form id='create-sql-script-form' onSubmit={handleSubmit(onSubmit)}>
            <FieldWrapper>
              <Text
                size='medium'
                weight='normal'
                text='Select Container'
              />
              <SelectWrapper>
                <Controller
                  name="containerId"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, ref, ...field } }) => (
                    <SelectBox
                      options={getContainerOptions()}
                      size='full'
                      onChangeCb={onChange}
                      {...field}
                    />
                  )}
                />
                <IconButton
                  aria-label="add"
                  onClick={() => setIsCreateContainerOpen(true)}
                  color="primary"
                >
                  <AddCircleIcon />
                </IconButton>
              </SelectWrapper>
            </FieldWrapper>
            <FieldWrapper>
              <Text
                size='medium'
                weight='normal'
                text='SQL Script Name'
              />
              <Controller
                name="name"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, ref, ...field } }) => (
                  <InputBox
                    type='text'
                    size='full'
                    placeholder=""
                    onChangeCb={onChange}
                    {...field}
                  />
                )}
              />
            </FieldWrapper>
            <FieldWrapper>
              <Text
                size='medium'
                weight='normal'
                text='Description'
              />
              <Controller
                name="description"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, ref, ...field } }) => (
                  <TextareaBox
                    size='full'
                    placeholder=""
                    rows={5}
                    onChangeCb={onChange}
                    {...field}
                  />
                )}
              />
            </FieldWrapper>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            type="button"
            buttonType="outline-disabled"
            space="xsmall"
            title='Cancel'
            onClickCb={handleClose}
          />
          <Button
            type="submit"
            buttonType="primary"
            space="xsmall"
            title="Save"
            form='create-sql-script-form'
            disabled={isSaveScriptLoading}
          />
        </DialogActions>
      </BootstrapDialog>
      <CreateContainer open={isCreateContainerOpen} setOpen={setIsCreateContainerOpen} />
    </React.Fragment>
  );
}
