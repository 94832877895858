import styled from "@emotion/styled";

interface TextareaProp {
  space: string;
  isInValid?: boolean;
}

const getWidth = (space: string) => {
  switch (space) {
    case "small":
      return "8rem";
    case "medium":
      return "12rem";
    case "large":
      return "16rem";
    case "full":
      return "100%";
    default:
      return "10rem";
  }
};

export const TextareaWrapper = styled.textarea`
  display: block;
  width: ${(props: TextareaProp) => getWidth(props.space)};
  border: 1px solid
    ${(props: TextareaProp) => (props.isInValid ? "#e0001b" : "#797979")};
  padding: 0.35rem;
  border-radius: 5px;
`;
