import { FC, useState, useEffect } from 'react';
import { MultiElementTable } from 'components/multi-element-table/multi-element-table.component';
import { useRunSqlScriptMutation } from 'store/api/sql-editor.api';

interface ResultProps {
  sql: string
}

export const Result: FC<ResultProps> = ({ sql }) => {
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [
    runSqlScript,
    {
      data,
      isLoading,
      isSuccess,
      isError
    }
  ] = useRunSqlScriptMutation();

  useEffect(() => {
    runSqlScript({
      sql: sql
    });
  }, [sql]);

  interface DataObject {
    [key: string]: string;
  }

  useEffect(() => {
    if (isSuccess) {
      const tableHeaders = data.headers.map((header: string) => ({
        title: header,
      }))
      setColumns(tableHeaders)
  
      const tableData = data.data.map((item: string) => {
        return data.headers.reduce((obj: DataObject, key: string, index: number) => {
          obj[key] = item[index];
          return obj;
        }, {} as DataObject);
      })
      setRows(tableData)
    }
  }, [isSuccess])
  
  return (
    <MultiElementTable
      data={rows}
      itemsPerPage={10}
      columns={columns}
      textSize='small'
    />
  )
};
