import React, { FC, ReactNode } from "react";
import { PageHeadingContainer } from "./page-heading.style";
import { Text } from "../text/text.component";

interface IPageHeading {
  Icon: any;
  title: string;
  iconProps?: any;
  isImg?: boolean;
}

export const PageHeading: FC<IPageHeading> = ({
  Icon,
  title,
  iconProps,
  isImg = false,
}) => (
  <PageHeadingContainer>
    {isImg ? Icon : <Icon {...iconProps} />}
    <Text
      size="xlarge"
      weight="bold"
      color="#333333"
      text={title}
      marginBottom={0.1}
    />
  </PageHeadingContainer>
);
