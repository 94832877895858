import { FC, useState, useMemo, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "store/hooks";
import { Steps } from "./create-data-hub.config";
import { StepperCompoenent } from "components/stepper/stepper.component";
import { resetDBcreate } from "store/slice/data-hub.slice";
import { DSDialog, DSDialogContent } from "./create-data-hub.styles";
import { usePreviewSchemaMutation } from "store/api/dataSource.slice";
import { Loader } from "components/mui-components/mui-components.index";
import { IDSPreviewPayload } from "interfaces/data-source.interface";
import {
  StatusDialogComponent,
  StatusType,
} from "components/status-dialog/status-dialog.component";

interface ICreateDS {
  onCancel: () => void;
  onComplete: () => void;
}

const steps = Object.keys(Steps).reduce((acc, key) => {
  const { description } = Steps[key];

  acc.push(description);

  return acc;
}, [] as string[]);

export const CreateDataSourceContainer: FC<ICreateDS> = ({
  onCancel,
  onComplete,
}) => {
  const dispatch = useAppDispatch();
  const { selectedDataSource } = useAppSelector((state) => state.dataHub);
  const [currStep, setCurrStep] = useState<number>(1);
  const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false);

  const [
    createPost,
    { data: schemaPreview, isLoading, isSuccess, isError, error },
  ] = usePreviewSchemaMutation();

  const commonProps = {
    onCancel: () => {
      dispatch(resetDBcreate());
      onCancel();
    },
    onBack: () => setCurrStep((prev) => prev - 1),
  };

  useEffect(() => {
    if (isSuccess) {
      setCurrStep((prev) => prev + 1);
    }

    if (isError) setShowErrorDialog(true);
  }, [isSuccess, isError]);

  const CurrStepComponent = useMemo(() => {
    const { description, component: Component } = Steps[currStep];

    switch (description) {
      case "Select a DataSource":
        return (
          <Component
            {...commonProps}
            onNext={() => setCurrStep((prev) => prev + 1)}
          />
        );
      case "Enter connection details":
        return (
          <Component
            onNext={(payload: IDSPreviewPayload) => createPost(payload)}
            selectedDB={selectedDataSource}
            {...commonProps}
          />
        );
      case "Review Schema Details":
        return (
          <Component
            {...commonProps}
            schemaDetails={schemaPreview?.schemas}
            onNext={() => {
              dispatch(resetDBcreate());
              onComplete();
            }}
          />
        );
      default:
        <></>;
    }
  }, [currStep]);

  return (
    <DSDialog open fullWidth maxWidth="lg">
      <StepperCompoenent activeStep={currStep - 1} steps={steps} />
      <DSDialogContent>
        {isLoading ? <Loader /> : CurrStepComponent}
        {showErrorDialog && (
          <StatusDialogComponent
            type={StatusType.ERROR}
            text="Error while fetching Schema"
            onClose={() => setShowErrorDialog(false)}
          />
        )}
      </DSDialogContent>
    </DSDialog>
  );
};
