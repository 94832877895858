import { SelectDataSource } from "./select-data-source/select-data-source.component";
import { DataHubFormComponent } from "./data-hub-form/data-hub-form.component";
import { DatahubSchemaComponent } from "./data-hub-schema/data-hub-schema.component";

interface ISteps {
  [key: string]: {
    title: string;
    description: string;
    component: any;
    showStepper: boolean;
  };
}

export const Steps: ISteps = {
  1: {
    title: "Step 1",
    description: "Select a DataSource",
    component: SelectDataSource,
    showStepper: false,
  },
  2: {
    title: "Step 2",
    description: "Enter connection details",
    component: DataHubFormComponent,
    showStepper: true,
  },
  3: {
    title: "Step 3",
    description: "Review Schema Details",
    component: DatahubSchemaComponent,
    showStepper: true,
  },
};
