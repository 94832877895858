import React, { FC, useState,useEffect } from 'react'
import {
  LoginHeader,
  LoginForm,
  LoginInput,
  LoginButton,
  PlatformInputWrapper
} from 'pages/login/login.style'
import { platformOnboarding } from 'utils/login-fields'
import { Text, InputBox, Button } from 'components'
import HelpIcon from '@mui/icons-material/Help'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import { Loader } from 'components/mui-components/mui-components.index'
import { PWD_REGEX } from 'utils/constants/validation'
import { useInitPlatformUserMutation } from 'store/api/platform-auth.api'
import { Success } from './success.component'
import { useAppDispatch } from 'store/hooks'
import { setIsError } from 'store/slice/error.slice'

const PlatformOnboardingFormComponent: FC = () => {
  const [userName, setUserName] = useState<string>("");
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [ createPost, {isError, isLoading, isSuccess}] = useInitPlatformUserMutation();
  const dispatch = useAppDispatch();

  useEffect(() =>{
    if(isSuccess) {
      setShowSuccess(true)
    }
    if (isError) dispatch(setIsError(isError))
  }, [isError, isSuccess, dispatch]);

  const getValue = (title: string) =>
    title === "Administrator Username" ?
      { value: userName, fn: setUserName }
      : title === "Administrator Password" ?
        { value: password, fn: setPassword }
        : { value: confirmPassword, fn: setConfirmPassword };

  const checkValidation = (type: string): boolean => {
    if (type === "Administrator Password") {
      return !PWD_REGEX.test(password) && !!password
    } else if(type === '') {
      return (password !== confirmPassword) && !!confirmPassword
    }

    return false
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();

    createPost({
      email: userName, 
      name: userName, 
      password: password
    })
  };

  return (
    <>
      <LoginHeader>
        <Text size="medium" weight={200} text="Administrator Setup" />
      </LoginHeader>

      {isLoading && <Loader />}

      <LoginForm onSubmit={handleSubmit}>
        {platformOnboarding.map((field) => (
          <LoginInput key={field.title}>
            {field.title &&
            <Text
              size={field.textSize}
              weight={field.weight}
              text={field.title}
            />}
            <PlatformInputWrapper>
              <InputBox
                type={field.inputType}
                size={field.inputSize}
                placeholder={field.placeholder || ''}
                value={getValue(field.title).value}
                onChangeCb={(e) => getValue(field.title).fn(() => e.target.value)}
                isInValid={checkValidation(field.title)}
              />
              {field.isTooltip &&
                <Tooltip title={field.tooltipText}>
                  <IconButton sx={{ padding: '2px' }}>
                    <HelpIcon sx={{ color: '#5bd3a7', fontSize: '0.9rem' }} />
                  </IconButton>
                </Tooltip>}
            </PlatformInputWrapper>
          </LoginInput>
        ))}
        <LoginButton>
          <Button
            type="submit"
            buttonType="primary"
            title="Create"
            space="large"
          />
        </LoginButton>
      </LoginForm>
      <Success show={showSuccess} />
    </>
  )
}

export default PlatformOnboardingFormComponent;
