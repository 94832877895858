import styled from "@emotion/styled";

export const DockContainer = styled.div`
  position: relative;
  width: 100%;
  height: 300px;
  font-family: monospace;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border-top: 1px solid #cbc5c8;
`;

export const DockBody = styled.div`
  flex: 1;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #555;
  }

  &::-webkit-scrollbar-track {
    background-color: #cbc5c8;
  }
`;
