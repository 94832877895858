import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IOrgConfig, SSOConfig } from "../../interfaces/org-config.interface";

const initialState: IOrgConfig = {
  forgetPasswordEnabled: false,
  orgId: null,
  ssoLoginInfos: [],
  orgOktaConfig: { oktaClientId: "", oktaDomain: "" },
  orgOutlookConfig: { outlookClientId: "", outlookTenantId: "" },
};

const orgSlice = createSlice({
  name: "orgSlice",
  initialState,
  reducers: {
    setOrgDetails: (state, action: PayloadAction<IOrgConfig>) => ({
      ...state,
      ...action.payload,
    }),
    setOktaConfig: (state, action: PayloadAction<SSOConfig>) => ({
      ...state,
      orgOktaConfig: action.payload,
    }),
    setOutlookConfig: (state, action: PayloadAction<SSOConfig>) => ({
      ...state,
      orgOutlookConfig: action.payload,
    }),
    resetOrgDetails: () => initialState,
  },
});

export const {
  setOrgDetails,
  setOktaConfig,
  setOutlookConfig,
  resetOrgDetails,
} = orgSlice.actions;

export default orgSlice.reducer;
