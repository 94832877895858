import { FC, useState } from "react";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import {
  LeftMenuItemContainer,
  LeftMenuItemSection,
  LeftMenuChildContent,
} from "../sidebar.style";
import { Text } from "../../../text/text.component";
import { ILeftMenu } from "../../../../interfaces/left-menu-item.interface";
import { useNavigate } from "react-router-dom";
import { ImgComponent } from "components/Img/img.component";

interface ILeftMenuItemProps {
  icon?: string;
  title: string;
  to: string;
  isChildItem?: boolean;
  childItems: ILeftMenu[];
}

export const LeftMenuItem: FC<ILeftMenuItemProps> = ({
  icon,
  title,
  to,
  childItems,
}) => {
  const navigate = useNavigate();

  const [expand, setExpand] = useState<boolean>(false);
  const [active, setActive] = useState<boolean>(false);

  const ToggleIcon = expand ? KeyboardArrowUp : KeyboardArrowDown;

  const handleItemClick = (to: string) => {
    setActive(() => true);

    to !== "" && navigate(to);
  };

  if (childItems.length > 0) {
    return (
      <LeftMenuItemContainer active={active}>
        <LeftMenuItemSection>
          {icon && <ImgComponent src={icon} size="small" />}
          <Text
            text={title}
            size="medium"
            weight="normal"
            onClickCb={() => handleItemClick(to)}
            color={active ? "#096cf2" : ""}
            marginBottom={0.1}
          />
          <ToggleIcon
            fontSize="medium"
            onClick={() => setExpand(!expand)}
            color="action"
            style={{ cursor: "pointer" }}
          />
        </LeftMenuItemSection>

        {expand &&
          childItems.map((child, index) => (
            <LeftMenuChildContent key={index}>
              <LeftMenuItem
                icon={child.icon}
                title={child.title}
                childItems={child.childItems}
                to={child.to}
              />
            </LeftMenuChildContent>
          ))}
      </LeftMenuItemContainer>
    );
  } else {
    return (
      <LeftMenuItemContainer active={active}>
        <LeftMenuItemSection>
          {icon && <ImgComponent src={icon} size="small" />}
          <Text
            text={title}
            size="medium"
            weight="normal"
            onClickCb={() => handleItemClick(to)}
            marginBottom={0.1}
            color={active ? "#096cf2" : ""}
          />
        </LeftMenuItemSection>
      </LeftMenuItemContainer>
    );
  }
};
