import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RootState } from '../store';
import { IAuthResponse } from '../../interfaces/auth-response.interface';


const BASE_URL = process.env.NODE_ENV === 'production' ? (window as any)?.env.REACT_APP_API_BASE_URL : process.env.REACT_APP_API_BASE_URL

export const LoginApiSlice = createApi({
    reducerPath: 'Authentication',
    baseQuery: fetchBaseQuery({
        baseUrl : BASE_URL,
        prepareHeaders : (headers, { getState }) => {
            headers.set('X-Request-ID', 'f640eb45-05ce-443c-8cd4-bdb4d45cad01')
            headers.set('X-Org-Id', `${(getState() as RootState).orgSlice.orgId}`)

            return headers
        }
    }),
    tagTypes: ['Login'],
    endpoints: (builder) => ({
        getAuthToken : builder.query<IAuthResponse, any>({
            query : (payload) => ({
                url: '/auth/login',
                method: 'GET',
                headers: {
                    "Authorization": `Basic ${payload}`
                }  
            })
        }),
    })
})

export const { useLazyGetAuthTokenQuery } = LoginApiSlice
