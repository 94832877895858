import { PlatformApi } from "./platform.api";
import { 
  IConfigResponse,
  IInitUserRequest,
  IAuthResponse,
  IResetPasswordMailRequest,
  IResetPasswordRequest,
} from '../../interfaces/platform.interface';
import { IUserResponse } from "interfaces/user.interface";

export const PlatformAuthApi = PlatformApi.injectEndpoints({
  endpoints: (build) => ({
    platformConfig: build.query<IConfigResponse, void>({
      query: () => ({
        url: "/conf/page",
        method: "GET"
      })
    }),
    initPlatformUser: build.mutation<any, IInitUserRequest>({
      query: (payload) => ({
        url: '/user/init',
        method: 'POST',
        body: payload
      })
    }),
    platformAuthToken: build.query<IAuthResponse, string>({
      query: (payload) => ({
        url: "/auth/login",
        method: "GET",
        headers: {
          "Authorization": `Basic ${payload}`
        }
      })
    }),
    platformResetMail: build.mutation<any, IResetPasswordMailRequest>({
      query: (payload) => ({
        url: '/auth/send-reset-password',
        method: 'POST',
        body: payload,
      })
    }),
    platformResetPassword: build.mutation<any, IResetPasswordRequest>({
      query: (payload) => ({
        url: '/auth/reset-password',
        method: 'POST',
        body: payload,
      })
    }),
    platformUser: build.query<IUserResponse, void>({
      query: () => ({
        url: "/user/info",
        method: "GET",
      })
    }),
  }),
  overrideExisting: false
});

export const { 
  usePlatformConfigQuery, 
  useInitPlatformUserMutation, 
  useLazyPlatformAuthTokenQuery, 
  usePlatformResetMailMutation, 
  usePlatformResetPasswordMutation ,
  usePlatformUserQuery,
} = PlatformAuthApi;
