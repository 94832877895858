import React, { useState } from "react";
import { ControlLabel, CustomCheckBox } from "../data-hub-schema.styles";
import { Text } from "components";
import {
  KeyboardArrowLeftOutlined,
  KeyboardArrowRightOutlined,
} from "@mui/icons-material";

interface SchemaListItemProps {
  table: string;
  onItemClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onTableClick: (table: string) => void;
}

export const SchemaItem: React.FC<SchemaListItemProps> = ({
  table,
  onItemClick,
  onTableClick,
}) => {
  const [showTableDetails, setShowTableDetails] = useState<boolean>(false);
  const VisiblityIcon = showTableDetails
    ? KeyboardArrowLeftOutlined
    : KeyboardArrowRightOutlined;

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "2fr 1fr",
        alignItems: "center",
        gap: "2px",
      }}
    >
      <ControlLabel
        label={
          <Text
            text={table}
            color="#333333"
            weight="normal"
            size="medium"
            marginBottom={0.1}
          />
        }
        control={
          <CustomCheckBox defaultChecked onChange={onItemClick} value={table} />
        }
      />

      <span
        style={{ cursor: "pointer" }}
        onClick={() => {
          setShowTableDetails((prev) => !prev);
          onTableClick(table);
        }}
      >
        <VisiblityIcon color="action" fontSize="small" />
      </span>
    </div>
  );
};
