import React, { FC, useEffect, useState, useMemo } from "react";
import { TableContainer, Pagination } from "@mui/material";
import {
  MultiElementWrapper,
  PaginationWrapper,
  Table,
  Td,
} from "./table.style";
import { Text } from "../text/text.component";
import { EditIcon } from "asset/svg-icon/edit-icon.component";
import { formatData } from "utils/format-data";
import { Tag } from "components/tag/tag.component";

interface ITable {
  title: string | null;
  tableData: any[];
  columns?: {};
  color?: any | null;
  itemsPerPage?: number;
}

export const TableComponent: FC<ITable> = ({
  title,
  tableData,
  color,
  itemsPerPage = 10,
}) => {
  // if(!tableData.length) return <>No Data to Show</>;

  const [page, setPage] = useState<number>(1);
  const [items, setItems] = useState<any[]>(tableData.slice(0, itemsPerPage));

  useEffect(() => {
    const start = (page - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    const updatedItems = tableData.slice(start, end);
    setItems(() => updatedItems);
  }, [page, tableData, itemsPerPage]);

  const headings = Object.keys(tableData[0]).filter((f) => f !== "id");

  const getTableRowElement = (row: any) => {
    if (React.isValidElement(row)) return row;

    if (row instanceof Array) {
      return (
        <MultiElementWrapper>
          {row.map((ro) => (
            <Tag>
              <Text
                size="medium"
                weight="normal"
                text={ro || "-"}
                color={color}
                marginBottom={0.1}
              />
            </Tag>
          ))}
        </MultiElementWrapper>
      );
    } else {
      return (
        <Text
          size="medium"
          weight="normal"
          text={row || "-"}
          color={color}
          marginBottom={0.1}
        />
      );
    }
  };

  const Row = useMemo(() => {
    return items.map((rows: any, index: number) => (
      <tr key={index}>
        {Object.values(rows).map((row: any) => (
          <Td key={row}>{getTableRowElement(row)}</Td>
        ))}
        <Td>
          <EditIcon />
        </Td>
      </tr>
    ));
  }, [items, page]);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    event.preventDefault();
  };

  return (
    <TableContainer>
      {title && (
        <Text
          size="large"
          text={title}
          weight="bold"
          marginBottom={1.5}
          color="#333333"
        />
      )}
      <Table>
        <thead>
          <tr>
            {headings.map((heading, index) => (
              <td key={index}>
                <Text
                  size="small"
                  weight="lighter"
                  text={heading}
                  color="#aaaaaa"
                />
              </td>
            ))}
          </tr>
        </thead>
        <tbody>
          {Row}
          {/* {items.map((rows: any, index: number) => (
            <tr key={index}>
              {Object.values(rows).map((row: any) => (
                <Td key={row}>{getTableRowElement(row)}</Td>
              ))}
              <Td>
                <EditIcon />
              </Td>
            </tr>
          ))} */}
        </tbody>
      </Table>

      <PaginationWrapper>
        <Pagination
          count={Math.ceil(tableData.length / itemsPerPage)}
          variant="outlined"
          color="primary"
          onChange={handleChange}
        />
      </PaginationWrapper>
    </TableContainer>
  );
};
