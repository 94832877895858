import React, { FC, useState, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { LayoutComponent } from "components/layout/layout.component";
import { useAppSelector, useAppDispatch } from "store/hooks";
import { useUserMenu } from "hooks/useUserMenu";
import { useGetUserQuery } from "store/api/user-api.slice";
import { setLogOut } from "store/slice/login.slice";
import { OrganisationLeftMenuItems } from "utils/left-menu-items";

export const HomeComponent: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [skip, setSkip] = useState<boolean>(true);

  const {
    data: userDetails,
    isError,
    isSuccess,
  } = useGetUserQuery(undefined, { skip });
  const { orgId } = useAppSelector((state) => state.orgSlice);

  const userDropdownItems = useUserMenu();
  const idToken = localStorage.getItem("idToken");

  useEffect(() => {
    if (orgId != null) setSkip(() => false);
  }, [orgId]);

  useEffect(() => {
    if (isError || !idToken) {
      dispatch(setLogOut());
      navigate("../auth/login");
    }
  }, [isError, idToken]);


  return (
    <React.Fragment>
      {isSuccess && (
        <LayoutComponent
          menuItems={OrganisationLeftMenuItems}
          userDropdownItems={userDropdownItems}
          userDetails={userDetails}
        >
          <Outlet />
        </LayoutComponent>
      )}
    </React.Fragment>
  );
};
