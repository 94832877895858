import React, { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Loader } from "components/mui-components/mui-components.index";
import { usePlatformConfig } from "hooks/usePlatformConfig";
import PlatformOnboardingFormComponent from "./onboarding.component";

export const PlatformOnboarding: FC = () => {
    const navigate = useNavigate();
    const {
        data: config,
        isLoading,
        isSuccess,
        isError
    } = usePlatformConfig();

    useEffect(() => {
        if (isSuccess && config !== undefined) {
            if (config.status === 'Active') navigate("../login");
        }
    }, [config, isLoading, isSuccess, isError]);

    return isLoading ? <Loader /> : <PlatformOnboardingFormComponent />;
}
