import styled from "@emotion/styled";

export const HomepageWrapper = styled.div`
  min-height : 100vh;
  display: grid;
  grid-template-columns: auto;
  gap: 10px;
  align-items: baseline;
  justify-items: start;
  padding: 8px;
  margin-top: 8px;
  width: 100%;
`;

export const TitleContainer = styled.div`
    display : flex;
    justify-content: center;
    img {
        margin-right: 0.5rem;
    }
`

export const CardWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 500px;
    align-items: center;
    margin-top: 2rem;
`

export const RightIconWrapper = styled.div`
    position: absolute;
    right: -2.5%;
    top: 50%;
    background: #fff;
`