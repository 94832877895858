import { apiSlice } from "./api.slice";
import { ISSOListResponse } from "../../interfaces/sso-provider.interface";

export const OrgSSOApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getSSOList: build.query<ISSOListResponse[], any>({
      query: () => ({
        url: "/org/sso",
        method: "GET",
      }),
    }),
    addSSOProvider: build.mutation<any, any>({
      query: (payload) => ({
        url: "/org/sso",
        method: "POST",
        body: payload,
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useGetSSOListQuery, useAddSSOProviderMutation } = OrgSSOApiSlice;
