export const dataHub = []

export const dataSourceContent = [
    {
        size: 'medium',
        weight: 'normal',
        text: 'Data Sources',
        color: '#676E72',
        marginBottom: '0.1'
    },
    {
        size: 'small',
        weight: 'normal',
        text: 'Connection details including preview, metadata, lineage, statistics and more',
        color: '#676E72',
        marginBottom: '0.1'
    }
]

export const sqlScriptsContent = [
    {
        size: 'medium',
        weight: 'normal',
        text: 'SQL Scripts',
        color: '#676E72',
        marginBottom: '0.1'
    },
    {
        size: 'small',
        weight: 'normal',
        text: 'Develop using SQL',
        color: '#676E72',
        marginBottom: '0.1'
    }
]

export const sqlBuilderContent = [
    {
        size: 'medium',
        weight: 'normal',
        text: 'SQL Builder',
        color: '#676E72',
        marginBottom: '0.1'
    },
    {
        size: 'small',
        weight: 'normal',
        text: 'No-code SQL Builder',
        color: '#676E72',
        marginBottom: '0.1'
    }
]

export const pipelineContent = [
    {
        size: 'medium',
        weight: 'normal',
        text: 'Pipeline',
        color: '#676E72',
        marginBottom: '0.1'
    },
    {
        size: 'small',
        weight: 'normal',
        text: 'Shareable collections of data, with relationshipsStep-by-step transformation builder, real-time or stored',
        color: '#676E72',
        marginBottom: '0.1'
    }
]

export const dataProductContent = [
    {
        size: 'medium',
        weight: 'normal',
        text: 'Data Product',
        color: '#676E72',
        marginBottom: '0.1'
    },
    {
        size: 'small',
        weight: 'normal',
        text: 'Shareable collections of data, with relationships & governance enforced',
        color: '#676E72',
        marginBottom: '0.1'
    }
]