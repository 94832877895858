import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAuthResponse } from "../../interfaces/auth-response.interface";

interface ILogin {
  authToken: IAuthResponse;
  loginMode: string;
  isAuthenticated: boolean;
  enableRefresh: boolean;
  tokenExpiry: Date | null;
}

const initialState: ILogin = {
  authToken: {
    idToken: "",
    refreshToken: "",
  },
  loginMode: "",
  isAuthenticated: false,
  enableRefresh: false,
  tokenExpiry: null,
};

const loginSlice = createSlice({
  name: "loginSlice",
  initialState,
  reducers: {
    setAuthToken: (state, action: PayloadAction<IAuthResponse>) => {
      const { idToken, refreshToken } = action.payload;
      localStorage.setItem("idToken", JSON.stringify(idToken));
      localStorage.setItem("refreshToken", JSON.stringify(refreshToken));

      return { ...state, authToken: action.payload };
    },
    setLoginMode: (state, action: PayloadAction<string>) => ({
      ...state,
      loginMode: action.payload,
    }),
    setIsAuthenticated: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isAuthenticated: action.payload,
    }),
    setTokenExpiry: (state, action: PayloadAction<Date | null>) => ({
      ...state,
      tokenExpiry: action.payload,
    }),
    setEnableRefresh: (state, action: PayloadAction<boolean>) => ({
      ...state,
      enableRefresh: action.payload,
    }),
    setLogOut: () => {
      localStorage.removeItem("idToken");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("loginMode");
      localStorage.removeItem("active");
      localStorage.removeItem("expiry");

      sessionStorage.clear();

      return initialState;
    },
  },
});

export const {
  setAuthToken,
  setLoginMode,
  setIsAuthenticated,
  setLogOut,
  setEnableRefresh,
  setTokenExpiry,
} = loginSlice.actions;

export default loginSlice.reducer;
