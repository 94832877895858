import { apiSlice } from "./api.slice";
import { IUserResponse } from "interfaces/user.interface";
import { IGroupResponse, IInitGroupRequest } from "interfaces/user-group.interface";

export const UserListApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getUserList: build.query<IUserResponse[], any>({
      query: () => ({
        url: "/users",
        method: "GET",
      }),
    }),
    addUser: build.mutation<any, any>({
      query: (payload) => ({
        url: "/users",
        method: "POST",
        body: payload,
      }),
    }),
    getGroupList: build.query<IGroupResponse[], any>({
      query: () => ({
        url: "/roles",
        method: "GET",
      }),
    }),
    addGroup: build.mutation<any, IInitGroupRequest>({
      query: (payload) => ({
        url: "/roles",
        method: "POST",
        body: payload,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetUserListQuery, 
  useAddUserMutation, 
  useGetGroupListQuery, 
  useAddGroupMutation
} = UserListApiSlice;
