import React , { FC } from 'react';
import { BannerWrapper } from './banner.style';
import { ZetarisIcon } from '../../asset/images/zetaris-logo.component';
import { Text } from '../text/text.component';

export const Banner : FC = () => (
    <BannerWrapper>
        <ZetarisIcon />
        <Text size='heading' weight='normal' text='Zetaris' color='#000' />
    </BannerWrapper>
)