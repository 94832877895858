import React, { FC, useState, useEffect } from "react";
import { Text } from "../text/text.component";
import { UserContainer, UserSection } from "../layout/layout.style";
import { NotificationIcon } from "asset/svg-icon/notification-icon.component";
import { HelpIcon } from "asset/svg-icon/help-icon.component";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { MenuComponent } from "../menu/menu.component";

export const UserComponent: FC<any> = ({ menuItems, userDetails }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <UserContainer>
      <NotificationIcon />
      <HelpIcon />
      <UserSection>
        <Text
          text={userDetails.email.toLowerCase() || ""}
          size="small"
          weight="lighter"
          marginBottom={0.1}
          color="#a9a9a9"
        />
        {userDetails?.level === "ADMIN" && (
          <Text text="admin" size="small" weight="bold" marginBottom={0.1} color="#a9a9a9" />
        )}
      </UserSection>
      <IconButton
        aria-label="menu"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{ color: '#a9a9a9' }}
      >
        <KeyboardArrowDownIcon />
      </IconButton>
      <MenuComponent
        items={menuItems}
        open={open}
        anchorEl={anchorEl}
        handleClose={handleClose}
      />
    </UserContainer>
  );
};
