import React, { FC } from "react";
import { SelectWrapper } from "./select.style";
import type { Space } from "utils/style-type";

interface ISelectBox {
  options: { id: string | number, value: string | number; label: string }[];
  value: string | number;
  onChangeCb: (e: any) => void;
  size: Space;
  isInValid?: boolean;
  name?: string;
}

export const SelectBox: FC<ISelectBox> = ({
  options,
  value,
  onChangeCb,
  size,
  isInValid,
  name,
}) => (
  <SelectWrapper
    space={size}
    value={value}
    onChange={onChangeCb}
    isInValid={isInValid}
    name={name}
  >
    {options.map((option) => (
      <option key={option.id} value={option.value}>
        {option.label}
      </option>
    ))}
  </SelectWrapper>
);
