import React, { useState, useMemo, useEffect, FC } from "react";
import { Text } from "../text/text.component";
import { InputBox } from "../input/input.component";
import { IFormTemplate } from "../../interfaces/form-template.interface";
import {
  ButtonSection,
  ZForm,
  InputSection,
  ZFormUserSection,
} from "./form.style";
import { Button } from "../button/button.component";

interface IZForm {
  title: string;
  formArr: IFormTemplate[];
  submitBtn: string;
  onSubmit: (a: any, reset: () => void) => void;
  onCancel: () => void;
  redirect?: any;
}

const prepareForm = (formArr: IFormTemplate[]) =>
  formArr.reduce((r, v) => ({ ...r, [v.name]: "" }), {});

export const ZFormComponent: FC<IZForm> = ({
  title,
  formArr,
  submitBtn,
  onSubmit,
  onCancel,
  redirect,
}) => {
  const initialForm = useMemo(() => prepareForm(formArr), [formArr]);
  const [form, setForm] = useState<any>(initialForm);
  const [isFormValid, setFormValid] = useState<boolean>(true);

  const onChangeHandler = (e: any) => {
    setForm((p: any) => ({ ...p, [e.target.name]: e.target.value }));
  };

  const onSubmitHandler = (e: any) => {
    e.preventDefault();
    onSubmit(form, () => setForm(initialForm));
  };

  useEffect(() => {
    Object.values(form).map((value) => {
      if (value) {
        setFormValid(() => false);
      } else {
        setFormValid(() => true);
      }
    });
  }, [form]);

  const onCancelHandler = () => {
    setForm(initialForm);
    onCancel();
  };

  return (
    <ZForm>
      <Text text={title} size="large" weight="bold" marginBottom={2} />
      {formArr.map(({ label, name, type }, index) => (
        <ZFormUserSection key={index}>
          <Text text={label} size="medium" color="#717171" weight="lighter" />
          <InputSection>
            <InputBox
              value={form[name]}
              placeholder={`Enter ${label}`}
              type={type}
              size="large"
              onChangeCb={onChangeHandler}
              name={name}
            />
          </InputSection>
        </ZFormUserSection>
      ))}
      {submitBtn !== "" && (
        <ButtonSection>
          <Button
            title="cancel"
            buttonType="disabled"
            onClickCb={onCancelHandler}
            space="medium"
            type="button"
          />
          <Button
            title={submitBtn}
            buttonType="info"
            space="medium"
            type="submit"
            onClickCb={onSubmitHandler}
            disabled={isFormValid}
          />
        </ButtonSection>
      )}
    </ZForm>
  );
};
