import React from "react";
import { useNavigate } from "react-router-dom";
import { OktaSignInWidget } from "../okta-sign-in/okta-signin-widget.component";
import { useOktaAuth } from "@okta/okta-react";
import { Loader } from "../../../components/mui-components/mui-components.index";
import { setIsAuthenticated } from "../../../store/slice/login.slice";
import { useAppDispatch } from "../../../store/hooks";

export const OktaLogin = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onSuccess = (tokens: any) => {
    oktaAuth.handleLoginRedirect(tokens).then(() => {
      localStorage.setItem("idToken", tokens);
      dispatch(setIsAuthenticated(true));
      navigate("../../home");
    });
  };

  const onError = (err: any) => {
    console.log("Sign in error:", err);
  };

  if (!authState) {
    return <Loader />;
  }

  return <OktaSignInWidget onSuccess={onSuccess} onError={onError} />;
};
