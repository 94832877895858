import { DataSourceFormFields } from "utils/data-source-form-fields";

export interface IDataSourceItem {
  id: string;
  title: string;
}

const {
  dataSourceName,
  description,
  jdbcUrl,
  connectionString,
  dataBaseName,
  jdbcDriver,
} = DataSourceFormFields;

const dataBaseConnectionFields = [dataSourceName, description, jdbcUrl];

export const DataSources: { [key: string]: any } = {
  "Database Connection": {
    RDBM: [
      {
        id: "ElasticSearch",
        title: "Elastic Search",
        fields: dataBaseConnectionFields,
      },
      {
        id: "Greenplum",
        title: "Green Plum",
        fields: dataBaseConnectionFields,
      },
      { id: "Db2", title: "IBM DB2", fields: dataBaseConnectionFields },
      {
        id: "MsSql",
        title: "Microsoft SQL Server",
        fields: dataBaseConnectionFields,
      },
      { id: "MySql", title: "MySQL", fields: dataBaseConnectionFields },
      { id: "Netezza", title: "Netezza", fields: dataBaseConnectionFields },
      { id: "Oracle", title: "Oracle", fields: dataBaseConnectionFields },
      { id: "Postgres", title: "PostgreSQL", fields: dataBaseConnectionFields },
      { id: "Redshift", title: "Redshift", fields: dataBaseConnectionFields },
      { id: "SnowFlake", title: "Snowflake", fields: dataBaseConnectionFields },
      { id: "Sqlite", title: "SQLite", fields: dataBaseConnectionFields },
      { id: "Teradata", title: "Teradata", fields: dataBaseConnectionFields },
      { id: "Vertica", title: "Vertica", fields: dataBaseConnectionFields },
      { id: "SapHana", title: "SAP HANA", fields: dataBaseConnectionFields },
      {
        id: "GenericJdbc",
        title: "Other JDBC",
        fields: [...dataBaseConnectionFields, jdbcDriver],
      },
    ],
    NoSQL: [
      {
        id: "Cassandra",
        title: "Cassandra",
        fields: [dataSourceName, description, connectionString],
      },
      {
        id: "Mongodb",
        title: "MongoDB",
        fields: [dataSourceName, description, dataBaseName, connectionString],
      },
    ],
  },
  "Object Storage": [
    { id: "AWS", title: "Amazon S3", fields: [] },
    { id: "AzureBlob", title: "Azure Blob", fields: [] },
    { id: "Google", title: "Google Cloud Storage", fields: [] },
    { id: "localFileSystem", title: "Local File System", fields: [] },
    { id: "Hadoop", title: "Hadoop HDFC", fields: [] },
  ],
  "Lakehouse Connection": [
    { id: "Deltaio", title: "Delta io", fields: [] },
    { id: "Iceberg", title: "Iceberg", fields: [] },
  ],
  "Metastore Connection": [
    { id: "Glue", title: "AWS Glue", fields: [] },
    { id: "Hive", title: "Hive", fields: [] },
  ],
  Streaming: [{ id: "Kafka", title: "Kafka", fields: [] }],
  WebApi: [
    { id: "Dynamics", title: "Dynamics", fields: [] },
    { id: "Marketo", title: "Marketo", fields: [] },
    { id: "monday", title: "Monday.com", fields: [] },
    { id: "MyCrm", title: "mycrm", fields: [] },
    { id: "OpenWeatherApi", title: "OpenWeather API", fields: [] },
    { id: "Restapi", title: "Rest API", fields: [] },
    { id: "SalesForce", title: "Salesforce", fields: [] },
    { id: "xero", title: "Xero", fields: [] },
  ],
};
