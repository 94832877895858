import styled from "@emotion/styled";

interface SelectProp {
  space: string;
  isInValid?: boolean;
}

const getWidth = (space: string) => {
  switch (space) {
    case "small":
      return "8rem";
    case "medium":
      return "12rem";
    case "large":
      return "16rem";
    case "full":
      return "100%";
    default:
      return "10rem";
  }
};

export const SelectWrapper = styled.select`
  display: block;
  width: ${(props: SelectProp) => getWidth(props.space)};
  border: 1px solid
    ${(props: SelectProp) => (props.isInValid ? "#e0001b" : "#797979")};
  padding: 0.35rem;
  border-radius: 5px;
  background: #fff;
`;
