import React, { FC, useEffect, useState } from "react";
import { useForm, Controller, SubmitHandler } from "react-hook-form";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import { Button, InputBox, Text } from "components";
import { StatusDialogComponent, StatusType } from "components/status-dialog/status-dialog.component";
import { addUserFields } from 'utils/add-user-fields';
import {
  FormRow,
  AddUserActionsWrapper,
  Field
} from "../user.style";
import { useAddUserMutation, useGetUserListQuery } from "store/api/usersList-api.slice";
import { userRoles } from "utils/constants/roles";

interface IAddUser {
  open: boolean;
  setOpen: (a: boolean) => void;
}

export const AddUser: FC<IAddUser> = ({ open, setOpen }) => {

  const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false);

  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid },
  } = useForm<any>({ defaultValues: { firstname: "", lastname: "", email: "", password: "", level: "" } });

  const [addUser, { isLoading, isError, isSuccess }] =
    useAddUserMutation();

  const {
    refetch
  } = useGetUserListQuery(undefined);

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit: SubmitHandler<any> = (data: any) => {
    const { firstname, lastname, ...rest } = data;
    const formData = { name: `${firstname} ${lastname}`, status: 'Active', isLdap: true, ...rest };
    addUser(formData);
  };

  useEffect(() => {
    if (isSuccess) {
      reset()
      setOpen(false);
      refetch()
    }
    if (isError) setShowErrorDialog(true);
  }, [isSuccess, isError])

  return (
    <>
      {!showErrorDialog &&
        <Dialog
          open={open}
          onClose={handleClose}
          fullWidth
          maxWidth='xs'
        >
          <form
            id="ds-form"
            onSubmit={handleSubmit(onSubmit)}
            style={{ width: "100%" }}
          >
            <DialogContent>
              <Text size='large' weight='bold' text='New User' color='#000' />
              {addUserFields.map(adduserfield => (
                <FormRow key={adduserfield.title}>
                  <Field>
                    {adduserfield.title}
                  </Field>
                  <Controller
                    name={adduserfield.name}
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, ref, ...field } }) => (
                      <InputBox
                        placeholder={adduserfield.placeholder || ''}
                        onChangeCb={onChange}
                        type={adduserfield.inputType}
                        size={adduserfield.inputSize}
                        {...field}
                      />
                    )}
                  />
                </FormRow>
              ))}
              <FormRow>
                <Field>
                  Role:
                </Field>
                <Controller
                  name={'level'}
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, ref, name, value } }) => (
                    <Select
                      onChange={onChange}
                      displayEmpty
                      inputProps={{ 'aria-label': 'Without label' }}
                      sx={{ height: 30, minWidth: 205 }}
                      name={name}
                      value={value}
                    >
                      <MenuItem value="">
                        <span>Select</span>
                      </MenuItem>
                      {
                        userRoles.map(userrole => <MenuItem key={userrole.value} value={userrole.value}>{userrole.name}</MenuItem>)
                      }
                    </Select>
                  )}
                />
              </FormRow>
            </DialogContent>
            <DialogActions>
              <AddUserActionsWrapper>
                <Button
                  type="button"
                  buttonType="disabled-secondary"
                  space="xsmall"
                  title='Cancel'
                  onClickCb={handleClose}
                />
                <Button
                  type="submit"
                  buttonType="primary"
                  space="xsmall"
                  title='Create'
                  disabled={!isValid || isLoading}
                />
              </AddUserActionsWrapper>
            </DialogActions>
          </form>
        </Dialog>
      }
      {showErrorDialog && (
        <StatusDialogComponent
          type={StatusType.ERROR}
          text="Error while creating User"
          onClose={() => setShowErrorDialog(false)}
        />
      )}
    </>
  );
};
