import React, { FC, useEffect, useState } from "react";
import { Text } from "../../../../components";
import { RadioButtonComponent } from "../../../../components/radio-buttons/radio-buttons.component";
import {
  AddContainer,
  AddRadioContainer,
  ProviderFormSection,
} from "../configure-sso.style";
import { IFormTemplate } from "../../../../interfaces/form-template.interface";
import { useAddSSOProviderMutation } from "../../../../store/api/org-sso-api.slice";
import { SSOProviderTemplates } from "../../../../utils/constants/provider-form-fields";
import { ssoProviderType } from "../../../../utils/sso-payload";
import { ZFormComponent } from "../../../../components/form/form.component";

const options = ["Office 365 (Auth)", "Okta Auth"];

interface IAddProvider {
  onRequestCompletion: (isSuccess: boolean, isError: boolean) => void;
}

export const AddProvider: FC<IAddProvider> = ({ onRequestCompletion }) => {
  const [value, setValue] = useState<any>("");
  const [providerFormArr, setProviderFormArr] = useState<IFormTemplate[]>([]);

  useEffect(
    () => setProviderFormArr(() => SSOProviderTemplates[value]),
    [value]
  );

  const [createPost, { isError, isSuccess }] = useAddSSOProviderMutation();

  const onSubmithandler = (form: any, callback: () => void) => {
    createPost({ ...form, provider: ssoProviderType[value] });
    callback();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setValue((event.target as HTMLInputElement).value);

  useEffect(() => {
    onRequestCompletion(isSuccess, isError);
  }, [isError, isSuccess]);

  return (
    <AddContainer>
      <Text
        text="Select Your SSO Identity Provider"
        size="medium"
        weight="lighter"
      />
      <AddRadioContainer>
        <RadioButtonComponent
          value={value}
          options={options}
          onChangeCb={handleChange}
        />
        {value && providerFormArr && (
          <ProviderFormSection>
            <ZFormComponent
              title={`Detais for ${value}`}
              formArr={providerFormArr}
              submitBtn="Configure"
              onCancel={() => console.log("cancelled")}
              onSubmit={onSubmithandler}
            />
          </ProviderFormSection>
        )}
      </AddRadioContainer>
    </AddContainer>
  );
};
