import React, { useState, FC } from "react";
import {
  SelectDataSourceContainer,
  SelectFooterContainer,
  SelectHeader,
} from "pages/data-hub/data-hub.style";
import { InputBox, Text, Button } from "components";
import { DHTypeButtons } from "../create-data-hub.styles";
import { DataSourceCategoryContainer } from "./data-source-category/data-source-category.container";
import { useAppSelector } from "store/hooks";

const categories = [
  "All",
  "Database Connection",
  "Object Storage",
  "Lakehouse Connection",
  "Metastore Connection",
  "Streaming",
  "WebApi",
];

interface ISelectDS {
  onNext: () => void;
  onCancel: () => void;
  onBack: () => void;
}

export const SelectDataSource: FC<ISelectDS> = ({
  onNext,
  onCancel,
  onBack,
}) => {
  const [selectedCategory, setSelectedCategory] = useState<string>(
    categories[0]
  );
  const { selectedDataSource: selectedDB } = useAppSelector(
    (state) => state.dataHub
  );

  return (
    <SelectDataSourceContainer>
      <SelectHeader>
        <Text
          text="New Data Source Connection"
          size="large"
          weight="normal"
          color="#000"
        />
        <InputBox
          type="input"
          placeholder="search"
          size="large"
          onChangeCb={() => {}}
          value=""
        />
      </SelectHeader>
      <DHTypeButtons>
        {categories.map((category, index) => (
          <Button
            key={index}
            title={category}
            type="button"
            buttonType="outline-disabled"
            onClickCb={() => setSelectedCategory(() => category)}
            shape="round"
            space="auto"
            isActive={selectedCategory === category}
          />
        ))}
      </DHTypeButtons>

      <DataSourceCategoryContainer
        selectedDb={selectedDB.title}
        category={selectedCategory}
      />

      <SelectFooterContainer style={{ marginTop: "auto" }}>
        <div style={{ marginRight: "auto" }}>
          <Button
            title={"Back"}
            type={"button"}
            space={"small"}
            buttonType={"outline-disabled"}
            shape={"normal"}
            onClickCb={onBack}
            disabled={true}
          ></Button>
        </div>
        <Button
          title={"Cancel"}
          type={"button"}
          space={"small"}
          buttonType={"outline-disabled"}
          shape={"normal"}
          onClickCb={onCancel}
        ></Button>
        <Button
          title={"Next"}
          type={"button"}
          space={"small"}
          buttonType={"info"}
          shape={"normal"}
          onClickCb={onNext}
          disabled={!selectedDB}
        ></Button>
      </SelectFooterContainer>
    </SelectDataSourceContainer>
  );
};
