import React, { FC, useState } from "react";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Divider from "@mui/material/Divider";
import {
  FilesListBarWrapper,
  FilesListWrapper,
  FileListItem,
  ActionsWrapper
} from "./files-list-bar.style";
import { ImgComponent, Text } from "components";
import DocumentIcon from "asset/images/document.png";

export const FilesListBar: FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const files = [
    {
      name: '2023.11.21 6:32pm',
      icon: DocumentIcon
    },
    {
      name: 'snowflake_queries',
      icon: DocumentIcon
    },
    {
      name: 'yearly_sales',
      icon: DocumentIcon
    }
  ]

  return (
    <React.Fragment>
      <FilesListBarWrapper>
        <FilesListWrapper>
          {files.map(file => (
            <React.Fragment key={file.name}>
              <FileListItem>
                <ImgComponent src={file.icon} size="small" />
                <Text text={file.name} weight="lighter" size="small" marginBottom={0.01} />
                <IconButton
                  aria-label="close"
                  onClick={() => console.log('close')}
                >
                  <CloseIcon sx={{ fontSize: 12 }} />
                </IconButton>
              </FileListItem>
              <Divider orientation="vertical" flexItem />
            </React.Fragment>
          ))}
          <IconButton
            aria-label="add"
            onClick={handleClick}
            color="primary"
          >
            <AddCircleIcon />
          </IconButton>
        </FilesListWrapper>
        <ActionsWrapper>
          <Divider orientation="vertical" flexItem />
          <IconButton
            aria-label="back"
            onClick={() => console.log('close')}
            sx={{ padding: 0 }}
          >
            <ArrowBackIcon />
          </IconButton>
          <IconButton
            aria-label="forward"
            onClick={() => console.log('close')}
            sx={{ padding: 0 }}
          >
            <ArrowForwardIcon />
          </IconButton>
          <IconButton
            aria-label="options"
            onClick={() => console.log('close')}
            sx={{ padding: 0 }}
          >
            <ArrowDropDownIcon fontSize="large" />
          </IconButton>
        </ActionsWrapper>
      </FilesListBarWrapper>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleClose}>SQL Script</MenuItem>
        <MenuItem onClick={handleClose}>SQL Builder</MenuItem>
        <MenuItem onClick={handleClose}>Pipeline</MenuItem>
        <MenuItem onClick={handleClose}>Product</MenuItem>
      </Menu>
    </React.Fragment>
  )
}
