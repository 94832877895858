import styled from "@emotion/styled";
import { ButtonShape } from "./button.component";

interface IButtonProp {
  space: string;
  buttonType: string;
  disabled?: boolean;
  shape?: ButtonShape;
  active?: boolean;
}

type buttonStyle = {
  background: string;
  color: string;
};

const getBorderRadius = (shape?: ButtonShape) =>
  shape === "round" ? "25px" : "5px";

const getStyle = (buttonType: string): buttonStyle => {
  let styleObj: buttonStyle = { background: "", color: "" };

  switch (buttonType) {
    case "primary":
      styleObj = {
        background: "#0298f2",
        color: "#ffffff",
      };
      break;
    case "secondary":
      styleObj = {
        background: "#021d45",
        color: "#ffffff",
      };
      break;
    case "disabled":
      styleObj = {
        background: "#d7d7d7",
        color: "#333333",
      };
      break;
    case "info":
      styleObj = {
        background: "#22a5f7",
        color: "#ffffff",
      };
      break;
    case "outline-primary":
      styleObj = {
        background: "#ffffff",
        color: "#0298f2",
      };
      break;
    case "outline-disabled":
      styleObj = {
        background: "#ffffff",
        color: "#717171",
      };
      break;
    case "disabled-secondary":
      styleObj = {
        background: "#717171",
        color: "#ffffff",
      };
      break;
    case "error":
      styleObj = {
        background: "#DF423D",
        color: "#ffffff",
      };
      break;
    default:
      styleObj = {
        background: "#0298f2",
        color: "#ffffff",
      };
  }

  return styleObj;
};

const getWidth = (size: string) => {
  switch (size) {
    case "xsmall":
      return "6rem";
    case "small":
      return "8rem";
    case "medium":
      return "12rem";
    case "large":
      return "16.75rem";
    case "auto":
      return "auto";
    default:
      return "10rem";
  }
};

const getBorder = (buttonType: string) => {
  switch (buttonType) {
    case "outline-primary":
      return "1px solid #0298f2";
    case "outline-disabled":
      return "1px solid #d7d7d7";
    default:
      return "none";
  }
};

export const ButtonWrapper = styled.button`
  background: ${({ buttonType, disabled }: IButtonProp) =>
    disabled
      ? getStyle("disabled").background
      : getStyle(buttonType).background};
  color: ${({ buttonType, disabled }: IButtonProp) =>
    disabled ? getStyle("disabled").color : getStyle(buttonType).color};
  width: ${(props: IButtonProp) => getWidth(props.space)};
  border: ${({ buttonType, active }: IButtonProp) =>
    active ? getBorder("outline-primary") : getBorder(buttonType)};
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 0.75rem;
  border-radius: ${({ shape }: IButtonProp) => getBorderRadius(shape)};
  cursor: ${({ disabled }: IButtonProp) =>
    disabled ? "not-allowed" : "pointer"};

  &:hover {
    border: ${({ buttonType }: IButtonProp) =>
      buttonType === "outline-disabled" && getBorder("outline-primary")};
    color: ${({ buttonType }: IButtonProp) =>
      buttonType === "outline-disabled" && getStyle("outline-primary").color};
  }
`;
