import React, { FC } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

interface IStepper {
  steps: string[];
  activeStep: number;
}

export const StepperCompoenent: FC<IStepper> = ({ steps, activeStep }) => (
  <Box sx={{ width: "100%" }}>
    <Stepper activeStep={activeStep} alternativeLabel>
      {steps.map((label) => (
        <Step
          key={label}
          sx={{
            "& .MuiStepLabel-root .Mui-completed": {
              color: "#63a103", // circle color (COMPLETED)
            },
            "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
              {
                color: "#63a103", // Just text label (COMPLETED)
              },
            "& .MuiStepLabel-root .Mui-active": {
              color: "#63a103", // circle color (ACTIVE)
            },
            "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel": {
              color: "#63a103", // Just text label (ACTIVE)
            },
            "& .MuiStepLabel-label.Mui-disabled ": {
              color: "grey", // Just text label (DISABLED)
            },
            "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
              fill: "white", // circle's number (ACTIVE)
            },
          }}
        >
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  </Box>
);
