import zetarisLogo from "../asset/images/zetaris-logo.png";
import { SSOConfig } from "../interfaces/org-config.interface";
// import { oktaConfig as config} from "./constants/okta-constants";

export const getOktaConfig = (config: SSOConfig) => {
  return {
    issuer: `https://${config.oktaDomain}/oauth2/default`,
    clientId: `${config.oktaClientId}`,
    redirectUri: `${window.location.origin}/authorization-code/callback`,
    scopes: ["openid", "profile", "email"],
    logo: zetarisLogo,
    theme: {
      tokens: {
        BorderRadiusMain: "24px",
        PalettePrimaryMain: "#D11DCA",
        Spacing5: "3rem",
        TypographyColorBody: "#00297A",
        TypographySizeBase: "100%",
        TypographyLineHeightBody: 1.75,
      },
    },
  };
};
