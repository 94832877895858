import styled from "@emotion/styled";
import TabPanel from '@mui/lab/TabPanel';

export const SearchSection = styled.div`
  width: 70%;
  display: flex;
  align-items: left;
  & img {
    padding: 0 1rem;
    margin-top: 0.2rem;
  }
  & input {
    border : none;
    border-radius: unset;
    border-bottom : 1px solid #797979;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;
  padding: 1rem;
  span:first-child {
    gap: 0.5rem;
  }
  span button{
    padding : 0.3rem;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2rem;
  padding: 0rem 3rem;
  min-height: 100vh;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-left: 2rem;
  margin: 2rem 0;
`;

export const ContentSection = styled.div`
  width: 100%;
  display: block;
  align-items: center;
`;

export const AddContainer = styled.div`
  display: block;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0.5rem;
  width: 100%;
`;
export const AddRadioContainer = styled.div`
  padding-left: 1rem;
  color: #333333;
  width: 100%;
`;

export const ProviderFormSection = styled.div`
  width: 98%;
  border: 1px solid #d7d7d7;
  border-radius: 5px;
`;

export const InputSection = styled.div`
  padding-left: 1rem;
`;

export const ProviderFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1rem 2rem;
  gap: 1rem;
  width: 100%;
`;

export const FormTextArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.05rem;
`;

export const ButtonSection = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 98%;
  gap: 0.5rem;
`;

export const AddUserActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px 16px;
`;

export const FormRow = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-bottom: 0.5rem;
  padding-right: 20px;
`;

export const Field = styled.span`
  position: relative;
  left: -15px;
`;

export const Select = styled.div`
  width: 205px;
  height: 30px;
`;

export const AddButton = styled.span`
  margin-top: 14px;
`;

