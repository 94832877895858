import React, { FC } from "react";
import {
  SsoBorder,
  SsoHeader,
  SsoHeaderText,
  SsoWrapper,
} from "./sso-login.style";
import { Text } from "../../../components/text/text.component";
import { Button } from "../../../components";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { RootState } from "../../../store/store";
import { getSSoTitle } from "../../../utils/get-ssoconfig-title";
import { SSOLoginInfo } from "../../../interfaces/org-config.interface";
import {
  setOktaConfig,
  setOutlookConfig,
} from "../../../store/slice/org-config.slice";
import {
  setIsAuthenticated,
  setLoginMode,
} from "../../../store/slice/login.slice";
import { Loader } from "../../../components/mui-components/mui-components.index";
import { LoginMODE } from "../../../utils/constants/login-mode";

export const SsoLogin: FC = () => {
  const navigate = useNavigate();
  const { ssoLoginInfos } = useAppSelector(
    (state: RootState) => state.orgSlice
  );
  const dispatch = useAppDispatch();

  const handleSSOClick = (sso: SSOLoginInfo) => {
    const { config, provider } = sso;
    localStorage.setItem("loginMode", provider.toLowerCase());
    dispatch(setLoginMode(provider.toLowerCase()));

    if (provider.toLowerCase() === LoginMODE.OKTA) {
      dispatch(setOktaConfig(config));
    }

    if (provider.toLowerCase() === LoginMODE.OUTLOOK) {
      dispatch(setOutlookConfig(config));
    }

    navigate(getSSoTitle(sso).to);
  };

  return (
    <SsoWrapper>
      <SsoHeader>
        <SsoBorder />
        <SsoHeaderText>
          <Text text="or" size="medium" weight="lighter" />
        </SsoHeaderText>
      </SsoHeader>
      {!ssoLoginInfos.length && <Loader />}
      {ssoLoginInfos.map((sso: SSOLoginInfo, index: number) => (
        <Button
          key={index}
          title={getSSoTitle(sso).title}
          type="button"
          space="large"
          buttonType="secondary"
          onClickCb={() => handleSSOClick(sso)}
        />
      ))}
    </SsoWrapper>
  );
};
