import styled from "@emotion/styled";

export const FilesListBarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #d9d9d9;
  background-color: #e2e2e2;
  font-size: 0.7rem;
`;

export const FilesListWrapper = styled.div`
  display: flex;
`;

export const FileListItem = styled.div`
  display: flex;
  align-items: center;
  padding: 3px;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
`;
