export const developMockData = [
  {
    'Name': '2023.11.22   4:22PM',
    'Type': 'SQL SCRIPT',
    'Container': '',
    'Date Modified': '1:10pm,  14/11/2023',
    'Owner': 'Ben Tzannes'
  },
  {
    'Name': 'region_sales',
    'Type': 'SQL VIEW',
    'Container': '',
    'Date Modified': '5:20pm,   08/11/2023',
    'Owner': 'Raina Thomas'
  },
  {
    'Name': 'sales_regions_yearly',
    'Type': 'SQL BUILDER',
    'Container': 'Enterprise',
    'Date Modified': '3:40pm,   08/11/2023',
    'Owner': 'Ben Tzannes'
  },
  {
    'Name': 'customer_master',
    'Type': 'PIPELINE',
    'Container': 'Financials',
    'Date Modified': '1:10pm,  14/11/2023',
    'Owner': 'Vinay Samuel'
  }
]
