import styled from "@emotion/styled";

export const Table = styled.table`
  width: 100%;
  overflow-y: auto;
`;

export const Td = styled.td`
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`;

export const MultiElementWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export const PaginationWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: 1rem;
  padding: 0.5rem;
`;
