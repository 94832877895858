import React, { FC, useEffect, useState } from "react";
import { InputBox, Button, Text } from "../../../components";
import { loginFields } from "../../../utils/login-fields";
import {
  LoginHeader,
  LoginForm,
  LoginInput,
  LoginLink,
  LoginButton,
} from "../login.style";
import { encode } from "base-64";
import { useLazyGetAuthTokenQuery } from "../../../store/api/login-api.slice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  setAuthToken,
  setLoginMode,
  setIsAuthenticated,
} from "../../../store/slice/login.slice";
import { useNavigate } from "react-router-dom";
import { Loader } from "../../../components/mui-components/mui-components.index";
import { SsoLogin } from "../sso-login/sso-login.component";
import { LoginMODE } from "../../../utils/constants/login-mode";
import { setIsError } from "../../../store/slice/error.slice";

export const LoginFormComponent: FC = () => {
  const [userName, setUserName] = useState<string>("");
  const [passWord, setPassWord] = useState<string>("");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { forgetPasswordEnabled } = useAppSelector((state) => state.orgSlice);

  const getValue = (title: string) =>
    title === "Username"
      ? { value: userName, fn: setUserName }
      : { value: passWord, fn: setPassWord };

  const [
    getAuthToken,
    { data: authToken, isLoading, isSuccess, isError, error },
  ] = useLazyGetAuthTokenQuery();

  useEffect(() => {
    if (isSuccess && authToken !== undefined) {
      dispatch(setAuthToken(authToken));
      dispatch(setIsAuthenticated(true));
      navigate("../../home");
    }
    if (isError) dispatch(setIsError(isError));
  }, [authToken, isLoading, isSuccess, isError, error, dispatch, navigate]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    dispatch(setLoginMode(LoginMODE.CREDS));
    localStorage.setItem("loginMode", LoginMODE.CREDS);
    let encodedValue = encode(`${userName}:${passWord}`);
    getAuthToken(encodedValue);
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <LoginHeader>
            <Text size="medium" weight={200} text="Sign in to Zetaris" />
          </LoginHeader>

          <LoginForm onSubmit={handleSubmit} align="center">
            {loginFields.map((field) => (
              <LoginInput key={field.title}>
                <Text
                  size={field.textSize}
                  weight={field.weight}
                  text={field.title}
                />
                <InputBox
                  type={field.inputType}
                  size={field.inputSize}
                  placeholder=""
                  value={getValue(field.title).value}
                  onChangeCb={(e) =>
                    getValue(field.title).fn(() => e.target.value)
                  }
                />
              </LoginInput>
            ))}
            {forgetPasswordEnabled && (
              <LoginLink to="../forget-password">
                <Text
                  size="small"
                  weight="lighter"
                  color="#0298f2"
                  text="Forgot Password?"
                />
              </LoginLink>
            )}

            <LoginButton>
              <Button
                type="submit"
                buttonType="primary"
                title="Login"
                space="large"
              />
            </LoginButton>
            <SsoLogin />
          </LoginForm>
        </>
      )}
    </>
  );
};
