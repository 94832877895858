import { configureStore, combineReducers } from "@reduxjs/toolkit";
import type { PreloadedState } from "@reduxjs/toolkit";
import loginSlice from "./slice/login.slice";
import orgSlice from "./slice/org-config.slice";
import errorSlice from "./slice/error.slice";
import datahubSlice from "./slice/data-hub.slice";
import { apiSlice } from "./api/api.slice";
import { LoginApiSlice } from "./api/login-api.slice";
import { OrgApiSlice } from "./api/orgconfig-api.slice";
import { ResetPasswordSlice } from "./api/reset-password-api.slice";
import { PlatformApi } from "./api/platform.api";
import { PlatformAuthApi } from "./api/platform-auth.api";
import { OrganisationsApiSlice } from "./api/organisations-api.slice";
import { UserApiSlice } from "./api/user-api.slice";
import { OrgSSOApiSlice } from "./api/org-sso-api.slice";
import { UserListApiSlice } from "./api/usersList-api.slice";
import { DataSourceListSlice } from "./api/dataSource.slice";
import { SqlEditorApi } from "./api/sql-editor.api";

const rootReducer = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  [PlatformApi.reducerPath]: PlatformApi.reducer,
  [LoginApiSlice.reducerPath]: LoginApiSlice.reducer,
  [OrgApiSlice.reducerPath]: OrgApiSlice.reducer,
  [ResetPasswordSlice.reducerPath]: ResetPasswordSlice.reducer,
  loginHeader: loginSlice,
  orgSlice: orgSlice,
  error: errorSlice,
  dataHub: datahubSlice,
});

export type RootState = ReturnType<typeof rootReducer>;

export const setupStore = (preloadedState?: PreloadedState<RootState>) =>
  configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat([
        LoginApiSlice.middleware,
        ResetPasswordSlice.middleware,
        OrgApiSlice.middleware,
        PlatformApi.middleware,
        PlatformAuthApi.middleware,
        OrganisationsApiSlice.middleware,
        UserApiSlice.middleware,
        OrgSSOApiSlice.middleware,
        UserListApiSlice.middleware,
        DataSourceListSlice.middleware,
        SqlEditorApi.middleware,
      ]),
    preloadedState,
  });

export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];
