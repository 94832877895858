import { Space } from "./style-type";

interface IAddOrganisationFields {
  title: string
  weight: number
  textSize: Space
  inputSize: Space
  inputType: string
  placeholder?: string
}

export const addOrganisationFields: IAddOrganisationFields[] = [
  {
    title: 'Name',
    weight: 200,
    textSize: 'small',
    inputSize: 'medium',
    inputType: 'email',
    placeholder: 'Please enter something'
  },
  {
    title: 'Email',
    weight: 200,
    textSize: 'small',
    inputSize: 'medium',
    inputType: 'text',
    placeholder: 'Please enter something'
  },
  {
    title: 'Address',
    weight: 200,
    textSize: 'small',
    inputSize: 'medium',
    inputType: 'text',
    placeholder: 'Please enter something'
  },
  {
    title: 'Country Code',
    weight: 200,
    textSize: 'small',
    inputSize: 'medium',
    inputType: 'text',
    placeholder: 'Please enter something'
  },
  {
    title: 'Mobile Number',
    weight: 200,
    textSize: 'small',
    inputSize: 'medium',
    inputType: 'text',
    placeholder: 'Please enter something'
  },
  {
    title: 'Phone Number',
    weight: 200,
    textSize: 'small',
    inputSize: 'medium',
    inputType: 'text',
    placeholder: 'Please enter something'
  },
  {
    title: 'Post Code',
    weight: 200,
    textSize: 'small',
    inputSize: 'medium',
    inputType: 'text',
    placeholder: 'Please enter something'
  },
  {
    title: 'Slug',
    weight: 200,
    textSize: 'small',
    inputSize: 'medium',
    inputType: 'text',
    placeholder: 'Please enter something'
  }
]
