import styled from "@emotion/styled";

export const CardWrapper = styled.div`
  min-width: 20rem;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid rgba(215, 215, 215, 0.4);
  background-color: rgba(245, 245, 245, 0.6);
  box-sizing: border-box;
`;

export const CardBody = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.7rem;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 12px;
`;
