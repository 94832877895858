import styled from "@emotion/styled";

export const PageHeadingContainer = styled.span`
  display: flex;
  box-sizing: border-box;
  gap: 1.5rem;
  padding: 0.15rem;
  align-items: center;
  justify-content: center;
`;
