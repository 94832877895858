import React, { FC, useEffect, useState } from "react";
import {
  ContentContainer,
  ContentSection,
  SearchSection
} from "../user.style";
import { Text, InputBox, ImgComponent } from "components";
import { useGetUserListQuery } from "store/api/usersList-api.slice";
import { Loader } from "components/mui-components/mui-components.index";
import FiltersIcon from 'asset/images/filters.png';
import { MultiElementTable } from "components/multi-element-table/multi-element-table.component";

export const UserList: FC = () => {
  const [searchedVal, setSearchedVal] = useState<string>("");
  const [dataSourceList, setDataSourceList] = useState<any>([]);

  const onChangeHandler = (e: any) => {
    setSearchedVal(e.target.value)
  };

  const {
    data: userData,
    isLoading,
    isSuccess,
    isError,
  } = useGetUserListQuery(undefined);

  useEffect(() => {
    if (isSuccess) {
      setDataSourceList(userData);
    }
  }, [userData, isSuccess]);

  const UserListColumns = Object.keys(dataSourceList[0] || {}).map((key) => ({
    title: key,
  }));

  const userActionMenuList = [
    {
      id: 'reset_password',
      name: 'Reset Password',
      onClickCb: (e:{[key: string] : string}) => {
        console.log('clicked', e)
      }
    },
    {
      id: 'edit_role',
      name: 'Edit Role',
      onClickCb: (e:{[key: string] : string}) => {
        console.log('clicked', e)
      }
    },
    {
      id: 'disable_user',
      name: 'Disable User',
      onClickCb: (e:{[key: string] : string}) => {
        console.log('clicked', e)
      }
    },
    {
      id: 'drop_user',
      name: 'Drop User',
      onClickCb: (e:{[key: string] : string}) => {
        console.log('clicked', e)
      }
    }
  ];

  return (
    <ContentContainer style={{ padding: 0 }}>
      {
        isSuccess && (
          <SearchSection>
            <InputBox
              value={searchedVal}
              placeholder={`Search`}
              type={'input'}
              size="large"
              onChangeCb={onChangeHandler}
              name={"Search"}
            />
            <ImgComponent src={FiltersIcon} size="small" />
            <Text
              text="select user or group to assign permissions"
              size="medium"
              weight="normal"
              color="#333"
            />
          </SearchSection>
        )
      }
      <ContentSection>
        {
          isLoading ? (
            <Loader />
          ) : (
            isSuccess && (
              <MultiElementTable
                itemsPerPage={15}
                data={dataSourceList}
                columns={UserListColumns}
                customActionElement
                actionList={userActionMenuList}
              />
            )
          )}
        {isError && (
          <Text
            text="Unable to fetch User List ! Try Again"
            size="medium"
            weight="normal"
            color="#333"
          />
        )
        }
      </ContentSection>
    </ContentContainer>
  );
};
