import React, { FC, useState, useEffect } from "react";
import { AuthWrapper, LoginWrapper } from "./login.style";
import { Card, Banner } from "components/index";
import { Outlet, useNavigate } from "react-router-dom";
import { Loader } from "components/mui-components/mui-components.index";

export const LoginContainer: FC = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('idToken');
    setLoading(false);
    if (token) {
      // Redirect to home page if token is present
      navigate('../home');
    }
  }, [navigate]);

  return (
    <AuthWrapper>
      {loading ? 
        <Loader /> :
        <Card>
          <LoginWrapper>
            <Banner />
            <Outlet />
          </LoginWrapper>
        </Card>
      }
    </AuthWrapper>
  )
};
