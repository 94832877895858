import styled from "@emotion/styled";
import ListItem from "@mui/material/ListItem";

export const StyledListItem = styled(ListItem)`
  gap: 24px;
`;

export const Status = styled.div`
  background-color: #2fa940;
  color: #ffffff;
  padding: 2px 5px;
`;

export const Time = styled.div``;

export const Sql = styled.div``;
