import React, { ReactNode, useState, useEffect } from "react";
import { TableContainer, Pagination } from "@mui/material";
import {
  PaginationWrapper,
  Table,
  MultiElementWrapper,
  ActionWrapper
} from "./multi-element-table.styles";
import { Text } from "components/text/text.component";
import { Tag } from "components/tag/tag.component";
import { EditIcon } from "asset/svg-icon/edit-icon.component";
import { Space } from "utils/style-type";
import { MenuComponent } from "components/menu/menu.component";

interface TableColumn<T> {
  //   key: keyof T;
  title: string;
}

interface TableProps<T> {
  data: T[];
  columns: TableColumn<T>[];
  itemsPerPage: number;
  textSize?: Space;
  customActionElement?: ReactNode | string;
  actionList?: any
}

/*  
    Generic Table Component
    Going Forward we Will use this Table Component for all the tables and remove the old TableComponent
*/

export const MultiElementTable: React.FC<TableProps<any>> = ({
  data,
  columns,
  itemsPerPage,
  textSize = 'medium',
  customActionElement,
  actionList
}) => {
  const [page, setPage] = useState<number>(1);
  const [items, setItems] = useState<any[]>(data.slice(0, itemsPerPage));
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    const start = (page - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    const updatedItems = data.slice(start, end);
    setItems(() => updatedItems);
  }, [page, data, itemsPerPage]);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    event.preventDefault();
  };

  const getRowElement = (row: any) => {
    if (React.isValidElement(row)) return row;

    if (typeof row === "string" || typeof row === "number")
      return (
        <Text
          size={textSize}
          weight="normal"
          text={row ? `${row}` : "-"}
          marginBottom={0.1}
        />
      );

    if (row instanceof Array && row.length > 1) {
      return (
        <MultiElementWrapper>
          {row.map((ro) => (
            <Tag>
              <Text
                size={textSize}
                weight="normal"
                text={ro || "-"}
                marginBottom={0.1}
              />
            </Tag>
          ))}
        </MultiElementWrapper>
      );
    } else return "-";
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handlePopupClose = () => setAnchorEl(null);

  return (
    <TableContainer>
      <Table>
        <thead>
          <tr>
            {columns.map(({ title }, index) => (
              <td key={index} style={{ textTransform: "capitalize" }}>
                <Text
                  size={textSize}
                  weight="lighter"
                  text={title}
                  color="#aaaaaa"
                />
              </td>
            ))}
          </tr>
        </thead>
        <tbody>
          {items.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {columns.map((column, colIndex) => (
                <td key={colIndex}>{getRowElement(row[column.title])}</td>
              ))}
              {
                !customActionElement && <td>
                  <EditIcon />
                </td>
              }

              {
                customActionElement &&
                <td>
                  <ActionWrapper onClick={handleClick}>
                    <EditIcon />
                  </ActionWrapper>
                  <MenuComponent
                    items={actionList}
                    open={open}
                    row={row}
                    anchorEl={anchorEl}
                    handleClose={handlePopupClose}
                  />
                 
                </td>
              }
            </tr>
          ))}
        </tbody>
      </Table>

      <PaginationWrapper>
        <Pagination
          count={Math.ceil(data.length / itemsPerPage)}
          variant="outlined"
          color="primary"
          onChange={handleChange}
        />
      </PaginationWrapper>
    </TableContainer>
  );
};
