import React, { FC } from 'react'
import { Button, Text } from 'components/index'
import { RESET } from 'utils/constants/message'
import { LoginButton, ResetSuccessWrapper } from 'pages/login/login.style'
import { useNavigate } from 'react-router-dom'

export const PlatformResetPasswordSuccess: FC = () => {
    const navigate = useNavigate();
    return (
        <ResetSuccessWrapper>
            <Text text={RESET.RESET_SUCCESS} weight='bold' size='medium' />
            <Text text={RESET.NAVIGATE_MSG} weight='normal' size='medium' />
            <LoginButton>
                <Button
                    type="button"
                    buttonType="primary"
                    title="Go to Login"
                    space="large"
                    onClickCb={() => navigate('../login')}
                />
            </LoginButton>
        </ResetSuccessWrapper>
    )
}