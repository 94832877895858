import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISchemaInterface } from "interfaces/data-source.interface";

interface IDatahubSlice {
  isLoading: boolean;
  category: string;
  selectedDataSource: {
    id: string;
    title: string;
    fields: any[];
  };
  dbPayload: {
    [key: string]: string | any;
  };
  schemaDetails: ISchemaInterface;
}

type dsType = {
  id: string;
  title: string;
  fields: any[];
};

const initialState: IDatahubSlice = {
  isLoading: false,
  category: "All",
  selectedDataSource: {
    id: "",
    title: "",
    fields: [],
  },
  dbPayload: {},
  schemaDetails: {
    schemaName: "",
    tables: [],
  },
};

const datahubSlice = createSlice({
  name: "datahubSlice",
  initialState,
  reducers: {
    setCategory: (state, action: PayloadAction<string>) => ({
      ...state,
      category: action.payload,
    }),
    setSelectedDataSource: (state, action: PayloadAction<dsType>) => ({
      ...state,
      selectedDataSource: action.payload,
    }),
    setDbPayload: (state, action: PayloadAction<any>) => ({
      ...state,
      dbPayload: {
        ...state.dbPayload,
        ...action.payload,
      },
    }),
    setIsLoading: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isLoading: action.payload,
    }),
    resetDBcreate: () => initialState,
  },
});

export const {
  setCategory,
  setSelectedDataSource,
  setDbPayload,
  setIsLoading,
  resetDBcreate,
} = datahubSlice.actions;
export default datahubSlice.reducer;
