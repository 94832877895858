import React from "react";
import { Grid } from "@mui/material";
import { Text } from "components";

export const SchemaPageTitle = () => (
  <Grid container spacing={1}>
    <Grid item xs={12}>
      <Text
        text="Select schemas to include in the connection."
        color="#aaaaaa"
        marginBottom={0.2}
        size="medium"
        weight="normal"
      />
    </Grid>
    <Grid item xs={12}>
      <Text
        text="Select specific tables by drilling down into the schema, otherwise all tables will be included."
        color="#aaaaaa"
        marginBottom={0.2}
        size="medium"
        weight="normal"
      />
    </Grid>
  </Grid>
);
