import React, { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Loader } from "components/mui-components/mui-components.index";
import { usePlatformConfig } from "hooks/usePlatformConfig";
import { PlatformLoginFormComponent } from "./login-form/login-form.component";
import { useAppDispatch } from "store/hooks";
import { setIsError } from "store/slice/error.slice";

export const PlatformLogin: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    data: config,
    isLoading,
    isSuccess,
    isError
  } = usePlatformConfig();

  useEffect(() => {
    if (isSuccess && config !== undefined) {
      if (config.status === 'Init') navigate("/platform/onboarding");
    }
    if (isError) dispatch(setIsError(isError));
  }, [config, isLoading, isSuccess, isError, dispatch, navigate]);

  return isLoading ? <Loader /> : <PlatformLoginFormComponent config={config} />;
}
