import styled from "@emotion/styled";

export const UserContainer = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.15rem;
`;
export const UserSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;
export const LayoutContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background: #f9f9fa;
  width: 100%;
`;

export const MainContentWrapper = styled.div`
  height: calc(100% - 2.5rem);
`;

export const MainContent = styled.div`
  width: 100%;
  height: 100%;
`;

export const SplitContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  overflow: hidden;
  width: 100%;
  height: 100%;
`;

export const MainContentArea = styled.div`
  height: 100%;
  width: 100%;
  background: #fff;
  border: 1px solid #d7d7d7;
  overflow-x: hidden;
  overflow-y: auto;
`;
