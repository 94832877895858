import styled from "@emotion/styled";
import { Checkbox, FormControlLabel, Grid } from "@mui/material";
import { TransitionGroup } from "react-transition-group";

export const ControlLabel = styled(FormControlLabel)(() => ({
  "& .MuiFormControlLabel-label": {
    color: "#333333",
    textTransform: "capitalize",
  },
}));

export const CustomCheckBox = styled(Checkbox)(() => ({
  "& .MuiCheckbox-root": {
    borderRadius: "5px",
  },
  "&.Mui-checked": {
    color: "#27a7d2",
  },
  color: "#27a7d2",
}));

export const TransitionGroupContainer = styled(TransitionGroup)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "baseline",
  paddingLeft: "40px",
  paddingTop: "0px",
  marginTop: "0px",
}));

export const SchemaListGrid = styled(Grid)(() => ({
  overflowY: "scroll",
  minHeight: "38rem",
  borderRight: "1px solid #d7d7d7",
}));

export const SchemaListItem = styled.div`
  display: inline-flex;
  gap: 4px;
  align-items: center;
`;

export const SchemaContainer = styled.div`
  display: grid;
  grid-template-rows: auto;
  gap: 10px;
  align-items: baseline;
  justify-items: start;
  padding: 8px;
  margin-top: 8px;
`;
