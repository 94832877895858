import React, { FC } from "react";
import { SearchWrapper } from "./my-recent.style";
import { InputBox, ImgComponent } from "components";
import { TableComponent } from "components/table/table.component";
import FiltersIcon from 'asset/images/filters.png';
import { developMockData } from "utils/develop-mock-data";

export const MyRecent: FC = () => {

  return (
    <React.Fragment>
      <SearchWrapper>
        <InputBox
          placeholder="Search"
          onChangeCb={() => console.log("change")}
          type="input"
          value=""
          size="medium"
        />
        <ImgComponent src={FiltersIcon} size="small" />
      </SearchWrapper>
      <TableComponent title="" tableData={developMockData} />
    </React.Fragment>
  )
}
