import { FC, useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Editor as MonacoEditor } from '@monaco-editor/react';
import { EditorWrapper, EditorContainer } from "./editor.style";
import { FilesListBar } from "./files-list-bar.component";
import { MenuBar } from "./menu-bar.component";
import { SaveSql } from "./save-dialog.component";
import { IContainersResponse } from "interfaces/sql-editor.interface";
import { useUpdateSqlScriptMutation } from "store/api/sql-editor.api";
import { QueryResultDock } from "./query-result-dock/query-result-dock.component";

interface EditorProps {
  containers: IContainersResponse[]
}

export const Editor: FC<EditorProps> = ({ containers }) => {
  const [value, setValue] = useState<string>('');
  const [showResult, setShowResult] = useState<boolean>(false);
  const [showSaveDialog, setShowSaveDialog] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [updateSqlScript] = useUpdateSqlScriptMutation();

  const handleEditorChange = (value: string | undefined) => setValue(value || '');

  useEffect(() => {
    const editorId = searchParams.get('id');
    if (editorId) {
      // LOAD THE SCRIPT HERE
      console.log(editorId)
    }
  }, []);

  const onSave = () => {
    const editorId = searchParams.get('id');

    if (editorId) {
      const data = {
        id: editorId,
        payload: {
          sql: value
        }
      }
      updateSqlScript(data)
    } else setShowSaveDialog(true)
  }

  const onSaveAs = () => setShowSaveDialog(true)

  return (
    <EditorWrapper component="main">
      <EditorContainer>
        <FilesListBar />
        <MenuBar
          onSave={onSave}
          onSaveAs={onSaveAs}
          setShowResult={setShowResult}
        />
        <MonacoEditor
          height="50vh"
          defaultLanguage="mysql"
          defaultValue={value}
          onChange={handleEditorChange}
        />
      </EditorContainer>
      {showResult && <QueryResultDock sql={value} />}
      <SaveSql
        containers={containers}
        open={showSaveDialog}
        setOpen={setShowSaveDialog}
        sqlScript={value}
      />
    </EditorWrapper>
  )
}
