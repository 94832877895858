import React, { FC, useState, useEffect, useMemo } from "react";
import { DataSourcIconList } from "pages/data-hub/data-source-icon";
import { DataSources } from "pages/data-hub/data-source-list";
import { TabsComponent } from "components/tabs/tabs.component";
import { DSItemContainer } from "../../create-data-hub.styles";
import { DataSourceItem } from "../data-source-item/data-source-item.component";
import { useAppDispatch } from "store/hooks";
import { setSelectedDataSource } from "store/slice/data-hub.slice";

interface IDataSourceCategoryContainer {
  category: string;
  selectedDb: string;
}

const allDataSources = Object.keys(DataSources).reduce((acc, key) => {
  if (Array.isArray(DataSources[key])) {
    return acc.concat(DataSources[key]);
  }
  return acc.concat(Object.values(DataSources[key]).flat());
}, [] as any[]);

const getIcon = (id: string) => DataSourcIconList[id];

export const DataSourceCategoryContainer: FC<IDataSourceCategoryContainer> = ({
  category,
  selectedDb,
}) => {
  const dispatch = useAppDispatch();
  const [dataSourceList, setDataSourceList] = useState<any[] | any>(
    allDataSources
  );

  useEffect(() => {
    if (category == "All") {
      setDataSourceList(() => allDataSources);
    } else {
      setDataSourceList(() => DataSources[category]);
    }
  }, [category]);

  const DataSourceOptions = useMemo(() => {
    if (Array.isArray(dataSourceList)) {
      return (
        <DSItemContainer>
          {dataSourceList.map(({ id, title, fields }: any, index: number) => (
            <DataSourceItem
              key={id}
              imgSrc={getIcon(id)}
              title={title}
              onClickCb={() =>
                dispatch(setSelectedDataSource({ id, title, fields }))
              }
              selected={title === selectedDb}
            />
          ))}
        </DSItemContainer>
      );
    } else if (typeof dataSourceList === "object") {
      const tabs = Object.keys(DataSources[category]).map((key) => ({
        title: key,
        content: (
          <DSItemContainer>
            {dataSourceList[key].map(
              ({ id, title, fields }: any, index: any) => (
                <DataSourceItem
                  key={id}
                  imgSrc={getIcon(id)}
                  title={title}
                  onClickCb={() =>
                    dispatch(setSelectedDataSource({ id, title, fields }))
                  }
                  selected={title === selectedDb}
                />
              )
            )}
          </DSItemContainer>
        ),
      }));

      return <TabsComponent tabs={tabs} />;
    }

    return null;
  }, [dataSourceList, selectedDb]);

  return <>{DataSourceOptions}</>;
};
