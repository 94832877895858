import React, { FC } from "react";
import {
  MainContentWrapper,
  MainContent,
  SplitContent,
  MainContentArea
} from "./layout.style";
import { ILayout } from "interfaces/layout.interface";
import { MainHeader } from "./header/header.component";
import { Sidebar } from "./sidebar/sidebar.compenent";

export const LayoutComponent: FC<ILayout> = ({ 
  menuItems,
  userDropdownItems,
  userDetails,
  children 
}) => (
  <React.Fragment>
    <MainHeader menuItems={userDropdownItems} userDetails={userDetails} />
    <MainContentWrapper>
      <MainContent>
        <SplitContent>
          <Sidebar menuItems={menuItems} />
          <MainContentArea>{children}</MainContentArea>
        </SplitContent>
      </MainContent>
    </MainContentWrapper>
  </React.Fragment>
);
