import styled from '@emotion/styled'

export const CardWrapper = styled.div`
    min-height: 20rem;
    min-width: 18rem;
    max-width: 40rem;
    width: auto;
    padding: 5px;
    background-color: #ffffff;
    box-sizing: border-box;
    border-radius: 10px;
    border: 1px solid #d7d7d7;
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.35);
    display: flex;
    align-items: center;
    justify-content: center;
`

export const FeatureCardWrapper = styled.div<{ bgColor: string | undefined }>`
    min-width: 16rem;
    padding: 10px 20px;
    background-color: ${(props) => props.bgColor || '#ffffff'};
    box-sizing: border-box;
    border-radius: 10px;
    border: 1px solid #d7d7d7;
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.35);
    width : 22rem;
`;

export const CardBody = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.7rem;
`;

export const CardRightSideIconWrapper = styled.div`
    display : flex;
    button {
        width: 2.25rem;
        margin-right: 0.8rem;
        background: #fff;
        border-radius: 0.3rem;
        border-width: 1px;
        border-style: solid;
        border-color: rgba(215, 215, 215, 1);
        :last-child {
            margin-right: 0;
        }
        svg > g > path{
            fill : #59BBF9;
        }
    }
`


export const FooterActionWrapper = styled.div`
    margin : 1rem 0;
    button {
        width: fit-content;
        color: #59BBF9;
        background: #fff;
        padding: 0.5rem;
        img {
            width : 18px;
            height: 18px;
            padding-right: 0.3rem;
        }
    }
`