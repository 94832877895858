import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { CardWrapper, CardBody, ButtonWrapper } from './card.style';
import { Button, Text, ImgComponent } from 'components';
import { AddIcon, FolderIcon } from 'asset/svg-icon';

interface ICard {
  icon: string;
  footerTextPrimary: string;
  footerTextSecondary: string;
  link: string;
}

export const Card: FC<ICard> = (props) => {
  const navigate = useNavigate();
  const { icon, footerTextPrimary, footerTextSecondary, link } = props;

  return (
    <CardWrapper>
      <CardBody>
        <ImgComponent src={icon} size='large' />
        <ButtonWrapper>
          <Button
            type="button"
            buttonType="primary"
            title={<AddIcon />}
            space="auto"
            onClickCb={() => navigate(link)}
          />
          <Button
            type="button"
            buttonType="outline-primary"
            title={<FolderIcon />}
            space="auto"
            onClickCb={() => console.log(true)}
          />
        </ButtonWrapper>
      </CardBody>
      <Text size='large' weight='normal' text={footerTextPrimary} color='#000' marginBottom={0.5} />
      <Text size='small' weight='normal' text={footerTextSecondary} color='#000' marginBottom={0.1} />
    </CardWrapper>
  )
}