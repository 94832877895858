import { FC, useMemo } from "react";
import { StatusDialog } from "./staus-dialog.style";
import { VerifiedRounded, WarningOutlined } from "@mui/icons-material";
import { Button, ButtonType, Text } from "components";
import { DialogContent, DialogTitle, DialogActions } from "@mui/material";

export enum StatusType {
  SUCCESS = "Success",
  ERROR = "Error",
  WARNING = "Warning",
  INFO = "Info",
}

interface IStatusDialog {
  type: StatusType;
  text: string;
  onClose: () => void;
}
export const StatusDialogComponent: FC<IStatusDialog> = ({
  type,
  text,
  onClose,
}) => {
  const StatusData = useMemo(() => {
    switch (type) {
      case StatusType.SUCCESS:
        return {
          icon: <VerifiedRounded color="success" />,
          color: "#F7FCF6",
          title: "Success",
          buttonText: "Ok",
          buttonType: "info",
        };
      case StatusType.ERROR:
        return {
          icon: <WarningOutlined sx={{ color: "#CB1B03" }} />,
          color: "#FEF8F8",
          title: "Error",
          buttonText: "Try Again",
          buttonType: "error",
        };
      case StatusType.WARNING:
        return {
          icon: <WarningOutlined />,
          color: "orange",
          title: "Warning",
          buttonText: "Ok",
          buttonType: "info",
        };
      case StatusType.INFO:
        return {
          icon: <WarningOutlined />,
          color: "blue",
          title: "Info",
          buttonText: "Ok",
          buttonType: "info",
        };
      default:
        return {
          icon: <WarningOutlined />,
          color: "black",
          title: "Info",
          buttonText: "Ok",
          buttonType: "info",
        };
    }
  }, [type, text]);

  return (
    <StatusDialog open onClose={onClose} type={StatusData.color}>
      {StatusData.icon}
      <DialogTitle>
        <Text text={StatusData.title} size="large" weight="bold" color="#000" />
      </DialogTitle>
      <DialogContent>
        <Text text={text} size="medium" weight="normal" color="#000" />
      </DialogContent>
      <DialogActions>
        <Button
          title={StatusData.buttonText}
          type="button"
          buttonType={StatusData.buttonType as ButtonType}
          onClickCb={onClose}
          space="small"
        />
      </DialogActions>
    </StatusDialog>
  );
};
