import { FC } from "react";
import { Button, InputBox } from "components";
import { AddOrganisationActionsWrapper, FormRow } from "./organisation.style";
import { addOrganisationFields } from "utils/add-organisation-fields";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

interface IProps {
  open: boolean;
  setOpen: (a: boolean) => void;
}

export const AddOrganisation: FC<IProps> = ({ open, setOpen }) => {
  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const formJson = Object.fromEntries((formData as any).entries());
    const email = formJson.email;
    console.log(email);
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth='sm'
      PaperProps={{
        component: 'form',
        onSubmit: handleSubmit,
      }}
    >
      <DialogContent>
        {addOrganisationFields.map(field => (
          <FormRow key={field.title}>
            <span>
              {field.title}
            </span>
            <InputBox
              placeholder={field.placeholder || ''}
              onChangeCb={() => console.log("change")}
              type={field.inputType}
              value=""
              size={field.inputSize}
            />
          </FormRow>
        ))}
      </DialogContent>
      <DialogActions>
        <AddOrganisationActionsWrapper>
          <Button
            type="button"
            buttonType="outline-primary"
            space="xsmall"
            title='Cancel'
            onClickCb={handleClose}
          />
          <Button
            type="button"
            buttonType="primary"
            space="xsmall"
            title='Save'
          />
        </AddOrganisationActionsWrapper>
      </DialogActions>
    </Dialog>
  )
}
