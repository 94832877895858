import React, { FC } from "react";
import { Outlet } from "react-router-dom";
import { useAppSelector } from "store/hooks";
import { ErrorWrapper } from "components/error/error-wrapper.component";

export const App: FC = () => {
  const { isError } = useAppSelector((state) => state.error);

  return (
    <React.Fragment>
      {isError && <ErrorWrapper />}
      <Outlet />
    </React.Fragment>
  );
};
