import React, { FC, useMemo, useState } from "react";
import { FormGroup, Grid, Collapse } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import {
  CustomCheckBox,
  ControlLabel,
  TransitionGroupContainer,
  SchemaListItem,
} from "../data-hub-schema.styles";
import { SchemaItem } from "./schema-list-item.component";

interface ISchemaListItemProps {
  schemaName: string;
  tables: string[];
  allChecked: boolean;
  onItemClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onTableSelect: (table: string, schemaName: string) => void;
}

export const SchemaList: FC<ISchemaListItemProps> = ({
  schemaName,
  tables,
  allChecked,
  onItemClick,
  onTableSelect,
}) => {
  const [showTables, setShowTables] = useState<boolean>(false);

  const ToggleButton = useMemo(
    () => (showTables ? KeyboardArrowUp : KeyboardArrowDown),
    [showTables]
  );

  return (
    <Grid item xs={12}>
      <FormGroup>
        <SchemaListItem key={schemaName}>
          <span
            onClick={() => setShowTables((prev) => !prev)}
            style={{ cursor: "pointer" }}
          >
            <ToggleButton color="action" />
          </span>
          <ControlLabel
            label={schemaName}
            control={<CustomCheckBox checked={allChecked} />}
          />
        </SchemaListItem>

        <Collapse in={showTables}>
          <TransitionGroupContainer>
            {tables.map((table, index) => (
              <SchemaItem
                key={index}
                table={table}
                onItemClick={onItemClick}
                onTableClick={() => onTableSelect(table, schemaName)}
              />
            ))}
          </TransitionGroupContainer>
        </Collapse>
      </FormGroup>
    </Grid>
  );
};
