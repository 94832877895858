import React, { FC, useState, useEffect } from "react";
import { Loader } from "components/mui-components/mui-components.index";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import MoreVert from "@mui/icons-material/MoreVert";
import ChevronRight from "@mui/icons-material/ChevronRight";
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useLazyGetSqlScriptsByContainerQuery } from "store/api/sql-editor.api";

interface ItemProps {
  id: string;
  name: string;
  icon?: JSX.Element;
}

export const PanelItem: FC<ItemProps> = ({ id, name, icon }) => {
  const [isListOpen, setIsListOpen] = useState<boolean>(false);
  const [
    getSqlScriptByContainer,
    {
    data: sqlScripts,
    isLoading,
  }] = useLazyGetSqlScriptsByContainerQuery();

  const showIcon = () => {
    if (isListOpen) {
      return <ExpandMore />
    } else if (isLoading) {
      return <Loader size={16} sx={{ mx: '4px' }} />
    }
    return <ChevronRight />
  }

  const handleExpandList = (id: string) => {
    if (isListOpen || (sqlScripts && sqlScripts.length)) {
      setIsListOpen(!isListOpen);
    } else {
      getSqlScriptByContainer(id);
    }
  };

  useEffect(() => {
    if (sqlScripts && sqlScripts.length > 0) {
      setIsListOpen(true);
    }
  }, [sqlScripts]);

  return (
    <React.Fragment>
      <ListItem key={id} disablePadding disableGutters sx={{ px: 1.5 }}>
        <IconButton
          aria-label="actions"
          onClick={() => handleExpandList(id)}
          sx={{ padding: '3px' }}
          disabled={isLoading}
        >
          {showIcon()}
        </IconButton>
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: 1,
            justifyContent: 'center',
          }}
        >
          {icon && icon}
        </ListItemIcon>
        <ListItemText
          primary={name}
          sx={{ opacity: 1 }}
          primaryTypographyProps={{ fontSize: 12, fontWeight: 'medium' }}
        />
        <IconButton
          aria-label="actions"
          onClick={() => console.log('actions')}
          sx={{ padding: '3px' }}
        >
          <MoreVert />
        </IconButton>
      </ListItem>
      <Collapse in={isListOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {sqlScripts && sqlScripts.map(sqlScript => (
            <ListItem key={sqlScript.id} disablePadding disableGutters sx={{ pr: 1.5, pl: 6.5 }}>
              <ListItemText
                primary={sqlScript.name}
                primaryTypographyProps={{ fontSize: 12, fontWeight: 'medium' }}
              />
              <IconButton
                aria-label="actions"
                onClick={() => console.log('actions')}
                sx={{ padding: '3px' }}
              >
                <MoreVert />
              </IconButton>
            </ListItem>
          ))}
        </List>
      </Collapse>
    </React.Fragment>
  )
}