export enum RESET {
    EMAIL_CONFIRMATION = "Check your email for link to reset password. Link expires in 30 minutes.",
    EMAIL_HEADER = "Enter email to reset password",
    PASSWORD_VALIDATION_MSG = "Password must be a minimum length of 8, and contain at least 1 number, 1 special character ($@!%*?&), 1 lowercase and 1 uppercase letter.",
    EMAIL_DOES_NOT_EXIST = "Entered email doesn't exist.",
    RESET_SUCCESS="Password Reset Success!",
    NAVIGATE_MSG="Go to login screen to login with newly created password"
}



