import { IUserResponse } from "../../interfaces/user.interface";
import { apiSlice } from "./api.slice";

export const UserApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getUser: build.query<IUserResponse, any>({
      query: () => ({
        url: "/users/describe",
        method: "GET",
      }),
      keepUnusedDataFor: 0,
    }),
  }),
  overrideExisting: false,
});

export const { useGetUserQuery } = UserApiSlice;
