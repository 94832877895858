export const JdbcUrl: { [key: string]: string } = {
  Db2: "jdbc:db2://<hostname>:<port>/databaseinstance",
  Postgres: "jdbc:postgresql://<hostname>:<port>/databaseinstance",
  Mysql: "jdbc:mysql://<hostname>:<port>/databaseinstance",
  ElasticSearch: "jdbc:postgresql://<hostname>:<port>/databaseinstance",
  Greenplum: "jdbc:pivotal:greenplum://<hostname>:<port>/databaseinstance",
  MsSql: "jdbc:sqlserver://<hostname>:<port>;databaseName=<dbName>",
  Netezza: "jdbc:netezza://<hostname>:<port>/databaseinstance",
  Oracle: "jdbc:oracle:thin:@<hostname>:<port>/databaseinstance",
  GenericJdbc: "jdbc:postgresql://<hostname>:<port>/databaseinstance", //url was not present
  Redshift: "jdbc:redshift://<hostname>:<port>/databaseinstance",
  SapHana: "jdbc:postgresql://<hostname>:<port>/databaseinstance", //sap hana url not present, icon also
  SnowFlake: "jdbc:snowflake://<hostname>:<port>/databaseinstance",
  Sqlite: "jdbc:sqlite://<hostname>:<port>/databaseinstance",
  Teradata: "jdbc:teradata://<hostname>:<port>/databaseinstance",
  Vertica: "jdbc:vertica://<hostname>:<port>/databaseinstance",
  Mongodb: "mongodb+srv://[username:password@]host",
  Cassandra: "",
};
