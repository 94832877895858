import React, { FC, useEffect, useState } from "react";
import { InputBox, Button, Text } from "components";
import { platformLoginFields } from "utils/login-fields";
import {
  LoginHeader,
  LoginForm,
  LoginInput,
  LoginLink,
  LoginButton,
} from "pages/login/login.style";
import { encode } from "base-64";
import { useLazyPlatformAuthTokenQuery } from "store/api/platform-auth.api";
import { useAppDispatch } from "store/hooks";
import { setAuthToken, setIsAuthenticated } from "store/slice/login.slice";
import { useNavigate } from "react-router-dom";
import { Loader } from "components/mui-components/mui-components.index";
import { setIsError } from "store/slice/error.slice";

interface IContext {
  config: any
}

export const PlatformLoginFormComponent: FC<IContext> = ({ config }) => {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const getValue = (title: string) =>
    title === "Zetaris Administrator Username" ? username : password;

  const handleInput = (e: any, title: string) => {
    const { value } = e.target;

    if (title === "Zetaris Administrator Username") {
      setUsername(() => value);
    } else {
      setPassword(() => value);
    }
  };

  const [
    getAuthToken,
    {
    data: authToken,
    isLoading,
    isSuccess,
    isError,
    error,
  }] = useLazyPlatformAuthTokenQuery();

  useEffect(() => {
    if (isSuccess && authToken !== undefined) {
      dispatch(setAuthToken(authToken));
      dispatch(setIsAuthenticated(true));
      navigate("/platform/organisations");
    }
    if (isError) dispatch(setIsError(isError));
  }, [authToken, isLoading, isSuccess, isError, error, dispatch, navigate]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    let encodedValue = encode(`${username}:${password}`);
    getAuthToken(encodedValue);
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <LoginHeader>
            <Text size="medium" weight={200} text="Sign in to Zetaris Platform" />
          </LoginHeader>

          <LoginForm onSubmit={handleSubmit}>
            {platformLoginFields.map((field) => (
              <LoginInput key={field.title}>
                <Text
                  size={field.textSize}
                  weight={field.weight}
                  text={field.title}
                />
                <InputBox
                  type={field.inputType}
                  size={field.inputSize}
                  placeholder=""
                  value={getValue(field.title)}
                  onChangeCb={(e) => handleInput(e, field.title)}
                />
              </LoginInput>
            ))}
            {config?.forgetPasswordEnabled &&
              <LoginLink to='../forget-password'>
                <Text
                  size="small"
                  weight="lighter"
                  color="#0298f2"
                  text="Forgot Password?"
                />
              </LoginLink>
            }

            <LoginButton>
              <Button
                type="submit"
                buttonType="primary"
                title="Login"
                space="large"
              />
            </LoginButton>
          </LoginForm>
        </>
      )}
    </>
  );
};
