interface RolesValue {
    name: string;
    value: string;
}

type UserGroupLevels = RolesValue[];

export const userRoles: UserGroupLevels = [{
    name: 'Admin',
    value: 'ADMIN'
},
{
    name: 'General',
    value: 'GENERAL'
},
]