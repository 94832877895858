import { FC, useMemo } from "react";
import { JdbcUrl } from "utils/constants/jdbc-url";
import { Text, InputBox, Button } from "components";
import { useAppSelector, useAppDispatch } from "store/hooks";
import { Grid } from "@mui/material";
import { IFormTemplate } from "interfaces/form-template.interface";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { DHFormContainer, DHFormField } from "./data-hub-form.styles";
import { SelectFooterContainer } from "pages/data-hub/data-hub.style";
import { setDbPayload } from "store/slice/data-hub.slice";
import { IDSPreviewPayload } from "interfaces/data-source.interface";

interface IDSForm {
  selectedDB: { id: string; title: string; fields: IFormTemplate[] };
  selectedCategory?: string;
  onNext: (payload: any) => void;
  onCancel: () => void;
  onBack: () => void;
}

const getInitValue = (name: string, db: string): string =>
  name === "jdbcUrl" || name === "connectionString" ? JdbcUrl[db] : "";

export const DataHubFormComponent: FC<IDSForm> = ({
  selectedDB,
  selectedCategory,
  onNext,
  onBack,
  onCancel,
}) => {
  const dispatch = useAppDispatch();
  const { id, title, fields: formArr } = selectedDB;
  const { dbPayload } = useAppSelector((state) => state.dataHub);
  console.log("select db", selectedDB);
  const providerFormArr = useMemo(() => {
    return formArr.reduce(
      (r, v) => ({
        ...r,
        [v.name]: dbPayload[v.name] || getInitValue(v.name, id),
      }),
      {}
    );
  }, [formArr, selectedDB]);

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<any>({
    defaultValues: providerFormArr,
  });

  const onSubmit: SubmitHandler<any> = (data: any) => {
    const payload: IDSPreviewPayload = {
      ...data,
      connectionType: id,
      includeTables: [],
    };
    dispatch(setDbPayload(payload));
    onNext(payload);
  };

  return (
    <DHFormContainer>
      <Text
        text={title}
        weight="lighter"
        size="large"
        marginBottom={1}
        color="#333333"
      />

      <form
        id="ds-form"
        onSubmit={handleSubmit(onSubmit)}
        style={{ width: "100%" }}
      >
        {formArr.map(({ label, type, xs = "full", name }) => (
          <DHFormField key={label}>
            <Grid item xs={8}>
              <Text
                text={label}
                size="medium"
                weight="normal"
                marginBottom={0.2}
                color="#7f7f7f"
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={name}
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, ref, ...field } }) => (
                  <InputBox
                    type={type}
                    size={xs}
                    placeholder={`Enter ${label}`}
                    onChangeCb={onChange}
                    {...field}
                  />
                )}
              />
            </Grid>
          </DHFormField>
        ))}
      </form>

      <SelectFooterContainer style={{ marginTop: "auto" }}>
        <div style={{ marginRight: "auto" }}>
          <Button
            title={"Back"}
            type={"button"}
            space={"small"}
            buttonType={"outline-disabled"}
            shape={"normal"}
            onClickCb={onBack}
          ></Button>
        </div>
        <Button
          title={"Cancel"}
          type={"button"}
          space={"small"}
          buttonType={"outline-disabled"}
          shape={"normal"}
          onClickCb={onCancel}
        ></Button>
        <Button
          title={"Next"}
          type={"submit"}
          space={"small"}
          buttonType={"info"}
          shape={"normal"}
          form="ds-form"
          disabled={!isValid}
        ></Button>
      </SelectFooterContainer>
    </DHFormContainer>
  );
};
