import { FC } from "react";
import { ButtonWrapper } from "./button.style";
import type { Space } from "../../utils/style-type";

export type ButtonType =
  | "primary"
  | "secondary"
  | "success"
  | "error"
  | "warning"
  | "disabled"
  | "info"
  | "outline-primary"
  | "outline-disabled"
  | "disabled-secondary";

export type ButtonShape = "round" | "normal";
interface IButton {
  title: JSX.Element | string;
  type: "button" | "submit" | "reset";
  onClickCb?: (e?: any) => void;
  space: Space;
  buttonType: ButtonType;
  disabled?: boolean;
  shape?: ButtonShape;
  isActive?: boolean;
  ref?: any;
  form?: string;
}

export const Button: FC<IButton> = ({
  title,
  type,
  space,
  buttonType,
  disabled,
  shape,
  ref,
  form,
  isActive,
  onClickCb,
}) => (
  <ButtonWrapper
    ref={ref}
    type={type}
    space={space}
    buttonType={buttonType}
    disabled={disabled}
    form={form}
    onClick={onClickCb}
    shape={shape}
    active={isActive}
  >
    {title}
  </ButtonWrapper>
);
