import { FC, ReactNode } from "react";
import { MenuBarWrapper, ActionsWrapper, ActionButton } from "./menu-bar.style";
import { UndoIcon } from "asset/svg-icon/undo.component";
import { RedoIcon } from "asset/svg-icon/redo.component";
import { SaveIcon } from "asset/svg-icon/save.component";
import { SaveAsIcon } from "asset/svg-icon/save-as.component";
import { PencilIcon } from "asset/svg-icon/pencil-icon.component";
import { BookIcon } from "asset/svg-icon/book.component";
import { DocumentIcon } from "asset/svg-icon/document.component";
import { PlayIcon } from "asset/svg-icon/play.component";

interface MenuBarProps {
  onSave: () => void,
  onSaveAs: () => void,
  setShowResult: (showResult: (prevState: boolean) => boolean) => void
}

interface ActionItem {
  icon: ReactNode,
  action: () => void
}

export const MenuBar: FC<MenuBarProps> = ({ onSave, onSaveAs, setShowResult }) => {
  const leftIcons = [
    {
      icon: <UndoIcon />,
      action: () => console.log('undo')
    },
    {
      icon: <RedoIcon />,
      action: () => console.log('redo')
    },
    {
      icon: <SaveIcon />,
      action: onSave
    },
    {
      icon: <SaveAsIcon />,
      action: onSaveAs
    },
    {
      icon: <PencilIcon />,
      action: () => console.log('edit')
    }
  ];

  const rightIcons = [
    {
      icon: <BookIcon />,
      action: () => window.open('https://kbase.zetaris.com/knowledge/sql-manual', '_blank')
    },
    {
      icon: <DocumentIcon />,
      action: () => console.log('clicked')
    },
    {
      icon: <PlayIcon />,
      action: () => setShowResult((prevState: boolean) => !prevState)
    }
  ];

  function renderIcons(actionItems: ActionItem[]) {
    return actionItems.map((actionItem: ActionItem, index: number) => (
      (
        <ActionButton key={index} onClick={actionItem.action}>
          {actionItem.icon}
        </ActionButton>
      )
    ))
  }

  return (
    <MenuBarWrapper>
      <ActionsWrapper>
        {renderIcons(leftIcons)}
      </ActionsWrapper>
      <ActionsWrapper>
        {renderIcons(rightIcons)}
      </ActionsWrapper>
    </MenuBarWrapper>
  )
}
