import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { setIsAuthenticated, setLogOut } from "../store/slice/login.slice";
import { useMsal } from "@azure/msal-react";
import { AccountInfo, BrowserUtils } from "@azure/msal-browser";

export const useSignOut = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { org_slug } = useParams();
  const isPlatform = window.location.href.indexOf("platform") > -1;
  const path = isPlatform
    ? "/platform/auth/login"
    : `/org/${org_slug}/auth/login`;

  const { instance } = useMsal();

  const handleOutlookSignOut = async () => {
    const activeAccount = instance.getActiveAccount();

    dispatch(setLogOut());
    if (activeAccount != null) {
      const logoutRequest = {
        account: instance.getAccountByHomeId(
          activeAccount?.homeAccountId || ""
        ),
        onRedirectNavigate: () => !BrowserUtils.isInIframe(),
        postLogoutRedirectUri: `${window.location.origin}${path}`,
      };
      await instance.logoutRedirect(logoutRequest);
    } else {
      navigate(path);
    }

    sessionStorage.clear();

    return;
  };

  const handleSignOut = async () => {
    const loginMode = localStorage.getItem("loginMode");

    if (loginMode === "outlook") {
      handleOutlookSignOut();
    } else {
      dispatch(setIsAuthenticated(false));
      dispatch(setLogOut());
      navigate(path);
    }
  };

  return {
    id: "sign_out",
    name: "Sign Out",
    onClickCb: handleSignOut,
  };
};
