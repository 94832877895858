import React from "react";
import { createRoot } from 'react-dom/client';
import "./index.css";
import { setupStore } from "./store/store";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { router } from "./routes/route";

const store = setupStore();

const container = document.createElement("div");
container.id = "root";
document.body.appendChild(container);

const root = createRoot(container!);
root.render(
  <Provider store={store}>
    <RouterProvider router={router} />
  </Provider>
);
