import React, { FC, useCallback, useState, useEffect } from "react";
import { Button } from "components";
import { SelectFooterContainer } from "pages/data-hub/data-hub.style";
import { SchemaListContainer as SchemaList } from "./schema-list/schema-list.container";
import { SchemaDetails } from "./schema-details/schema-details.component";
import { SchemaContainer } from "./data-hub-schema.styles";
import { SchemaPageTitle } from "./schema-page-title/schema-page-title.component";
import {
  IPreviewData,
  ISchemaInterface,
  ITableColumn,
} from "interfaces/data-source.interface";
import { useAppSelector } from "store/hooks";
import { useCreateDataSourceMutation } from "store/api/dataSource.slice";
import { Loader } from "components/mui-components/mui-components.index";
import {
  StatusDialogComponent,
  StatusType,
} from "components/status-dialog/status-dialog.component";

interface IDatahubSchema {
  onNext: () => void;
  onCancel: () => void;
  onBack: () => void;
  schemaDetails: ISchemaInterface[];
}

export const DatahubSchemaComponent: FC<IDatahubSchema> = ({
  onNext,
  onBack,
  onCancel,
  schemaDetails,
}) => {
  const [showSchema, setShowSchema] = useState<boolean>(false);
  const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false);
  const schemas = schemaDetails.map((schema) => ({
    schemaName: schema.schemaName,
    tables: schema.tables.map((table) => table.tableName),
  }));

  const [createPost, { isError, isSuccess, isLoading, error }] =
    useCreateDataSourceMutation();

  const { dbPayload } = useAppSelector((state) => state.dataHub);

  const initialIncludeTables = schemas
    .map(({ schemaName, tables }) =>
      tables.map((table) => ({ schemaName, tableName: table }))
    )
    .flat();

  const [includeTables, setIncludeTables] = useState<any>(initialIncludeTables);

  const [tableView, setTableView] = useState<{
    schemaDetails: ITableColumn[];
    tableDetails: IPreviewData;
  }>({
    schemaDetails: [],
    tableDetails: {
      columns: [],
      rows: [],
    },
  });

  const onTableSelect = useCallback(
    (table: string, schemaName: string) => {
      const selectedSchema = schemaDetails.find(
        (schema) => schema.schemaName === schemaName
      );

      const selectedTable = selectedSchema?.tables.find(
        (t) => t.tableName === table
      );

      setTableView({
        schemaDetails: selectedTable?.columns || [],
        tableDetails: selectedTable?.data || { columns: [], rows: [] },
      });
    },
    [schemaDetails]
  );

  const handleItemClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = event.target;

    if (checked) {
      setIncludeTables((prev: any) => [
        ...prev,
        initialIncludeTables.find((table: any) => table.tableName === value),
      ]);
    } else {
      setIncludeTables((prev: any) =>
        prev.filter((table: any) => table.tableName !== value)
      );
    }
  };

  const handleSubmit = () => {
    createPost({ ...dbPayload, includeTables });
  };

  useEffect(() => {
    if (isSuccess) {
      onNext();
    }

    if (isError) {
      setShowErrorDialog(true);
    }
  }, [isSuccess, isError]);

  return (
    <SchemaContainer>
      <SchemaPageTitle />
      {isLoading ? (
        <Loader />
      ) : (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 3fr",
            alignItems: "center",
            gap: "4px",
          }}
        >
          <div>
            <SchemaList
              onItemClick={handleItemClick}
              schemas={schemas}
              onTableSelect={(table, schemaName) => {
                onTableSelect(table, schemaName);
                setShowSchema((prev) => !prev);
              }}
            />
          </div>
          <div>{showSchema && <SchemaDetails {...tableView} />}</div>
        </div>
      )}

      {showErrorDialog && (
        <StatusDialogComponent
          type={StatusType.ERROR}
          text={"Error creating data source"}
          onClose={() => setShowErrorDialog(false)}
        />
      )}

      <SelectFooterContainer style={{ marginTop: "auto" }}>
        <div style={{ marginRight: "auto" }}>
          <Button
            title={"Back"}
            type={"button"}
            space={"small"}
            buttonType={"outline-disabled"}
            shape={"normal"}
            onClickCb={onBack}
          ></Button>
        </div>
        <Button
          title={"Cancel"}
          type={"button"}
          space={"small"}
          buttonType={"outline-disabled"}
          shape={"normal"}
          onClickCb={onCancel}
        ></Button>
        <Button
          title={"Create"}
          type={"button"}
          space={"small"}
          buttonType={"info"}
          shape={"normal"}
          form="ds-form"
          onClickCb={handleSubmit}
        ></Button>
      </SelectFooterContainer>
    </SchemaContainer>
  );
};
