import React, { FC, useState } from "react";
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

interface PanelHeaderProps {
  text: string;
  icon: React.ReactNode;
  open: boolean;
}

export const PanelHeader: FC<PanelHeaderProps> = ({ icon, text, open }) => (
  <ListItem disablePadding disableGutters>
    <ListItemButton
      sx={{
        minHeight: 32,
        justifyContent: open ? 'initial' : 'center',
        py: 0,
      }}
    >
      <ListItemIcon
        sx={{
          minWidth: 0,
          mr: open ? 1 : 'auto',
          justifyContent: 'center',
        }}
      >
        {icon}
      </ListItemIcon>
      {open && (
        <ListItemText
          primary={text}
          primaryTypographyProps={{ fontSize: 14, fontWeight: 'light' }}
        />
      )}
    </ListItemButton>
  </ListItem>
);