import React, { FC, useEffect, useState } from "react";
import {
  ContentContainer,
  ContentSection,
  SearchSection
} from "../user.style";
import { Text, InputBox, ImgComponent } from "components";
import { useGetGroupListQuery } from "store/api/usersList-api.slice";
import { Loader } from "components/mui-components/mui-components.index";
import FiltersIcon from 'asset/images/filters.png';
import { MultiElementTable } from "components/multi-element-table/multi-element-table.component";
import { formatDate } from 'utils/format-date';

export const GroupList: FC = () => {
  const [searchedVal, setSearchedVal] = useState<string>("");

  const [dataSourceList, setDataSourceList] = useState<any>([]);

  const onChangeHandler = (e: any) => {
    //TODO: ONCE query API will be added to endpoint then will implement this.
    setSearchedVal(e.target.value)
  };
  const {
    data: groupData,
    isLoading,
    isSuccess,
    isError,
  } = useGetGroupListQuery(undefined);

  const GroupListColumns = Object.keys(dataSourceList[0] || {}).map((key) => ({
    title: key,
  }));

  const userGroupActionMenuList = [
    {
      id: 'disable_group',
      name: 'Disable Group',
      onClickCb: (e:{[key: string] : string}) => {
        console.log('clicked', e)
      }
    },
    {
      id: 'drop_group',
      name: 'Drop Group',
      onClickCb: (e:{[key: string] : string}) => {
        console.log('clicked', e)
      }
    }
  ];

  useEffect(() => {
    if (isSuccess) {
      setDataSourceList(() =>
        groupData.map(({ name, level, createdAt }) => ({
          name, level, createdAt : formatDate(createdAt)
        }))
      );
    }
  }, [groupData, isSuccess]);


  return (
    <ContentContainer style={{ padding: 0 }}>
      {isSuccess && (
        <SearchSection>
          <InputBox
            value={searchedVal}
            placeholder={`Search`}
            type={'input'}
            size="large"
            onChangeCb={onChangeHandler}
            name={"Search"}
          />
          <ImgComponent src={FiltersIcon} size="small" />
        </SearchSection>
      )}
      <ContentSection>
        {isLoading ? (
          <Loader />
        ) : (
          isSuccess && (
            <MultiElementTable
              itemsPerPage={15}
              data={dataSourceList}
              columns={GroupListColumns}
              customActionElement
              actionList={userGroupActionMenuList}
            />
          )
        )}
        {isError && (
          <Text
            text="Unable to fetch Group List ! Try Again"
            size="medium"
            weight="normal"
            color="#333"
          />
        )}
      </ContentSection>
    </ContentContainer>
  );
};
