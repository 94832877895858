import React, { FC, useState } from "react";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { PanelHeader } from "./panel-header.component";
import { PanelItem } from "./panel-item.component";
import { InputBox } from "components";

interface ItemProps {
  id: string;
  name: string;
  icon?: JSX.Element;
}

interface PanelProps {
  text: string;
  icon: React.ReactNode;
  open: boolean;
  items: ItemProps[];
}

export const Panel: FC<PanelProps> = ({ text, icon, open, items }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const filteredItems = items.filter(item =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <React.Fragment>
      <List>
        <PanelHeader icon={icon} text={text} open={open} />
        {open && (
          <ListItem>
            <InputBox
              placeholder="Search"
              onChangeCb={e => setSearchTerm(e.target.value)}
              type="input"
              value={searchTerm}
              size="full"
            />
          </ListItem>
        )}
        {open &&
        filteredItems.map((item) => (
          <PanelItem key={item.id} {...item} />
        ))}
      </List>
    </React.Fragment>
  );
}