import React, { FC } from "react";
import { DHContainer } from "./data-hub.style";
import { DHLeftMenuComponent } from "pages/data-hub/components/left-menu/left-menu.component";
import { DHListComponent } from "pages/data-hub/components/data-hub-list/data-hub-list.component";

export const DataHubComponent: FC = () => {
  return (
    <DHContainer>
      {/* <DHLeftMenuComponent /> */}
      <DHListComponent />
    </DHContainer>
  );
};
