import React, { FC, useState } from "react";
import {
  HeaderContainer,
  ContentContainer,
  AddButton
} from "./user.style";
import {
  UserGroupIcon
} from "asset/svg-icon/user-group.component";
import { AddIcon } from 'asset/svg-icon/add-icon.component';
import { PageHeading } from "components/page-heading/page-heading.component";
import { Button } from "components";
import { AddUser } from "./add-user-group/add-user.component";
import { AddGroup } from "./add-user-group/add-group.component";
import Grid from '@mui/material/Grid';
import { UserList } from "./list/user-list.component";
import { GroupList } from "./list/group-list.component";
import { TabsComponent } from "components/tabs/tabs.component";

export const UserGroup: FC = () => {
  const [value, setValue] = useState(0);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const tabs = [
    {
      title: "Users",
      content: (
        <UserList />
      ),
    },
    {
      title: "Groups",
      content: (
        <GroupList />
      ),
    },
  ];

  const handleSelectedTab = (selectedTab: number) => {
    setValue(selectedTab);
  }

  return (
    <span>
      <HeaderContainer>
        <PageHeading
          title="Users & Groups"
          Icon={UserGroupIcon}
          iconProps={{ fontSize: "large", color: "disabled" }}
        />

        <AddButton>
          <Button
            type="button"
            buttonType="primary"
            title={<><AddIcon /> Add {!value ? 'Users' : 'Groups'}</>}
            space="small"
            shape="round"
            onClickCb={() => setModalOpen(true)}
          />
          {!value ? <AddUser open={modalOpen} setOpen={setModalOpen} /> :
            <AddGroup open={modalOpen} setOpen={setModalOpen} />}
        </AddButton>
      </HeaderContainer>

      <ContentContainer>
        <Grid container sx={{ padding: "8px" }}>
          <Grid item xs={12}>
            <TabsComponent tabs={tabs} onSelectedTab={handleSelectedTab} />
          </Grid>
        </Grid>
      </ContentContainer>
    </span >
  );
};
