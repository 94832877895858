import React, { FC } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

interface IRadioButtonGroup {
  options: string[];
  value: string;
  onChangeCb: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isRow?: boolean;
}

export const RadioButtonComponent: FC<IRadioButtonGroup> = ({
  options,
  value,
  onChangeCb,
  isRow = false,
}) => (
  <FormControl>
    <RadioGroup
      row={isRow}
      aria-labelledby="demo-controlled-radio-buttons-group"
      name="controlled-radio-buttons-group"
      value={value}
      onChange={onChangeCb}
    >
      {options.map((option, index) => (
        <FormControlLabel
          key={index}
          value={option}
          control={<Radio />}
          label={option}
        />
      ))}
    </RadioGroup>
  </FormControl>
);
