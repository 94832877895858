import styled from "@emotion/styled";
import { imgSize } from "./img.component";

interface IImgProp {
  src: string;
  size: imgSize;
}

const imgSpace = {
  small: { w: "24px", h: "24px" },
  medium: { w: "36px", h: "36px" },
  large: { w: "48px", h: "48px" },
  xLarge: { w: "86px", h: "40px" },
  default: { w: "auto", h: "auto" },
};

export const Image = styled.img`
  src: ${({ src }: IImgProp) => src};
  object-fit: contain;
  width: ${({ size }: IImgProp) => imgSpace[size].w};
  height: ${({ size }: IImgProp) => imgSpace[size].h};
`;
