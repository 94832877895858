import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { ZetarisIcon } from "asset/images/zetaris-logo.component";
import { Header, Logo } from "./header.style";
import { Text, InputBox, UserComponent } from "components";

export const MainHeader: FC<any> = ({ menuItems, userDetails }) => {
  const navigate = useNavigate();
  const handleIconClick = () => {
    navigate('../home');
  }
  return (
    <Header>
      <Logo onClick={handleIconClick}>
        <ZetarisIcon />
        <Text
          text="Zetaris"
          color="#25a7d2"
          size="large"
          weight="normal"
          marginBottom={0.1}
        />
      </Logo>
      <InputBox
        placeholder="search for tables, columns, tag, views etc"
        onChangeCb={() => console.log("change")}
        type="input"
        value=""
        size="large"
      />
      <UserComponent menuItems={menuItems} userDetails={userDetails} />
    </Header>
  )
}
