import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RootState } from '../store';

const BASE_URL = process.env.NODE_ENV === 'production' ? (window as any)?.env.REACT_APP_API_BASE_URL : process.env.REACT_APP_API_BASE_URL

export const ResetPasswordSlice = createApi({
    reducerPath: 'ResetPassword',
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL,
        prepareHeaders: (headers, { getState }) => {
            headers.set('X-Request-ID', 'f640eb45-05ce-443c-8cd4-bdb4d45cad01')
            headers.set('X-Org-Id', `${(getState() as RootState).orgSlice.orgId}`)

            return headers
        }
    }),
    tagTypes: ['Reset'],
    endpoints: (builder) => ({
        resetMail: builder.mutation({
            query: (payload) => ({
                url: '/auth/send-reset-password',
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ['Reset']
        }),
        ResetPassword : builder.mutation({
            query: (payload) => ({
                url: '/auth/reset-password',
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ['Reset']
        })
    })
})

export const { useResetMailMutation, useResetPasswordMutation, } = ResetPasswordSlice;