import React, { FC, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { Button, Text, InputBox, TextareaBox } from 'components';
import { FieldWrapper } from './create-container-dialog.style';
import { useCreateContainerMutation } from 'store/api/sql-editor.api';
import { setIsError } from 'store/slice/error.slice';
import { useAppDispatch } from 'store/hooks';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

interface CreateContainerProps {
  open: boolean;
  setOpen: (open: boolean) => void
}

type Inputs = {
  name: string
  description: string
}

export const CreateContainer: FC<CreateContainerProps> = ({ open, setOpen }) => {
  const dispatch = useAppDispatch();
  const handleClose = () => {
    setOpen(false);
  };

  const [createContainer, { isLoading, isError, isSuccess }] = useCreateContainerMutation();

  const {
    control,
    handleSubmit,
  } = useForm<Inputs>({
    defaultValues: {
      name: "",
      description: "",
    },
  })

  const onSubmit: SubmitHandler<Inputs> = (data) => createContainer(data)

  useEffect(() => {
    if (isSuccess) handleClose();
    if (isError) dispatch(setIsError(isError));
  }, [isSuccess, isError])

  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth='xs'
      >
        <DialogTitle id="customized-dialog-title" sx={{ textAlign: 'center' }}>
          <Text size='large' weight='normal' text='Create Container' marginBottom={0.01} className='align-center' />
        </DialogTitle>
        <DialogContent dividers>
          <form id='create-container-form' onSubmit={handleSubmit(onSubmit)}>
            <FieldWrapper>
              <Text
                size='medium'
                weight='normal'
                text='Container Name'
              />
              <Controller
                name="name"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, ref, ...field } }) => (
                  <InputBox
                    type='text'
                    size='full'
                    placeholder=""
                    onChangeCb={onChange}
                    {...field}
                  />
                )}
              />
            </FieldWrapper>
            <FieldWrapper>
              <Text
                size='medium'
                weight='normal'
                text='Description'
              />
              <Controller
                name="description"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, ref, ...field } }) => (
                  <TextareaBox
                    size='full'
                    placeholder=""
                    rows={5}
                    onChangeCb={onChange}
                    {...field}
                  />
                )}
              />
            </FieldWrapper>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            type="button"
            buttonType="outline-disabled"
            space="xsmall"
            title='Cancel'
            onClickCb={handleClose}
          />
          <Button
            type="submit"
            buttonType="primary"
            space="xsmall"
            title='Create'
            disabled={isLoading}
            form='create-container-form'
          />
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}
