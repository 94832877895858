import { FC } from "react";
import { ImgComponent, Text } from "components";
import { TabsComponent } from "components/tabs/tabs.component";
import { DevelopWrapper, Heading, CardWrapper } from "./workbench.style";
import { Card } from "./card/card.component";
import { MyRecent } from "./my-recent/my-recent.component";
import { OrgRecent } from "./org-recent/org-recent.component";
import DevelopIcon from "asset/images/develop.png";
import DocumentIcon from "asset/images/document.png";
import BuilderIcon from "asset/images/builder.png";
import PipelineIcon from "asset/images/pipeline.png";

export const WorkbenchComponent: FC = () => (
  <DevelopWrapper>
    <Heading>
      <ImgComponent src={DevelopIcon} size="small" />
      <Text size="large" weight='normal' text='Develop' color='#000' marginBottom={0.1} />
    </Heading>
    <CardWrapper>
      <Card
        icon={DocumentIcon}
        footerTextPrimary='SQL Scripts and Views'
        footerTextSecondary='Develop using SQL'
        link='sql-editor'
      />
      <Card
        icon={BuilderIcon}
        footerTextPrimary='SQL Builder'
        footerTextSecondary='No-code SQL Builder'
        link='sql-builder'
      />
      <Card
        icon={PipelineIcon}
        footerTextPrimary='Pipeline and Views'
        footerTextSecondary='Step-by-Step transformation builder'
        link='sql-editor'
      />
    </CardWrapper>
    <TabsComponent
      tabs={[
        { title: "My Recent", content: <MyRecent /> },
        { title: "Org Recent", content: <OrgRecent /> }
      ]}
    />
  </DevelopWrapper>
);
