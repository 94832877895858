import styled from "@emotion/styled";

interface IStyleProp {
  size: string;
  weight: string | number;
  color?: string;
  marginBottom?: number;
}

const getSize = (size: string): string => {
  switch (size) {
    case "xsmall":
      return "0.5rem";
    case "small":
      return "0.75rem";
    case "medium":
      return "1rem";
    case "large":
      return "1.25rem";
    case "xlarge":
      return "1.5rem";
    case "heading":
      return "2rem";
    default:
      return "1.75rem";
  }
};

export const TextWrapper = styled.div`
  font-size: ${(props: IStyleProp) => getSize(props.size)};
  font-weight: ${(props: IStyleProp) => props.weight};
  color: ${(props: IStyleProp) => (props.color ? props.color : "#333333")};
  margin-bottom: ${(props: IStyleProp) =>
    props.marginBottom ? props.marginBottom : 0.5}rem;
`;
