import { useOutletContext } from "react-router-dom";

type ContextType = {
    data: {
        forgetPasswordEnabled: boolean,
        status: string
    },
    isLoading: boolean,
    isSuccess: boolean,
    isError: boolean
};

export function usePlatformConfig() {
    return useOutletContext<ContextType>();
}
