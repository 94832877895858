import { PlatformApi } from "./platform.api";
import { IOrganisationsListResponse } from "interfaces/organisations.interface";

export const OrganisationsApiSlice = PlatformApi.injectEndpoints({
  endpoints: (build) => ({
    organisationsList: build.query<IOrganisationsListResponse[], void>({
      query: () => ({
        url: "/org",
        method: "GET"
      })
    })
  }),
  overrideExisting: false
});

export const { useOrganisationsListQuery } = OrganisationsApiSlice;
