import { Space } from "./style-type";

interface IAddUserFields {
  title: string,
  name : string,
  weight: number,
  textSize: Space,
  inputSize: Space,
  inputType: string,
  placeholder?: string
}

export const addUserFields: IAddUserFields[] = [
  {
    title: 'First Name:',
    name: 'firstname',
    weight: 200,
    textSize: 'small',
    inputSize: 'medium',
    inputType: 'text',
    placeholder: 'Please enter firstname'
  },
  {
    title: 'Last Name:',
    name: 'lastname',
    weight: 200,
    textSize: 'small',
    inputSize: 'medium',
    inputType: 'text',
    placeholder: 'Please enter lastname'
  },
  {
    title: 'Email:',
    name: 'email',
    weight: 200,
    textSize: 'small',
    inputSize: 'medium',
    inputType: 'email',
    placeholder: 'Please enter email'
  },
  {
    title: 'Password:',
    name: 'password',
    weight: 200,
    textSize: 'small',
    inputSize: 'medium',
    inputType: 'password',
    placeholder: 'Please enter password'
  }
]
