import React, { FC, useEffect, useState } from "react";
import {
  ConfigureSSOContainer,
  TextContainer,
  ContentSection,
} from "./configure-sso.style";
import { PageHeading } from "../../../components/page-heading/page-heading.component";
import { Text, Button, ImgComponent } from "../../../components";
import { AddProvider } from "./add-provider/add-provider.component";
import { useGetSSOListQuery } from "../../../store/api/org-sso-api.slice";
import { Loader } from "../../../components/mui-components/mui-components.index";
import { PROVIDER_TEXT } from "../../../utils/constants/provider-form-fields";
import { setIsError, setErrorMessage } from "../../../store/slice/error.slice";
import { useAppDispatch } from "../../../store/hooks";
import ssoImg from "asset/images/sso_enabled.png";
import { MultiElementTable } from "components/multi-element-table/multi-element-table.component";

const headingIcon = <ImgComponent src={ssoImg} size="large" />;

export const ConfigureSSO: FC = () => {
  const dispatch = useAppDispatch();
  const [addProvider, setAddProvider] = useState<boolean>(false);

  const [buttonText, setButtonText] = useState<string>("Add Provider");

  const {
    data: tableData,
    isLoading,
    isSuccess,
    isError,
    refetch,
  } = useGetSSOListQuery(undefined);

  useEffect(() => {
    setButtonText(() =>
      addProvider ? "Show Existing Providers" : "Add Provider"
    );
  }, [addProvider]);

  const handleProviderCreate = (isSuccess: boolean, isError: boolean) => {
    if (isSuccess) {
      refetch();
      setAddProvider(false);
      return;
    }

    if (isError) {
      dispatch(setIsError(true));
      dispatch(setErrorMessage("Unable to Add SSO Provider"));
    }
  };

  return (
    <ConfigureSSOContainer>
      <PageHeading title="Sigle Sign On" Icon={headingIcon} isImg={true} />
      <TextContainer>
        <Text
          text={PROVIDER_TEXT.TEXT1}
          size="medium"
          weight="normal"
          color="#333"
        />
        <Text
          text={PROVIDER_TEXT.TEXT2}
          size="medium"
          weight="normal"
          color="#333"
        />
        <Button
          type="button"
          buttonType="info"
          space="medium"
          onClickCb={() => setAddProvider((prev) => !prev)}
          title={buttonText}
        />
      </TextContainer>

      <ContentSection>
        {addProvider ? (
          <AddProvider onRequestCompletion={handleProviderCreate} />
        ) : isLoading ? (
          <Loader />
        ) : (
          isSuccess && (
            // <TableComponent title="SSO Enabled" tableData={tableData} />
            <MultiElementTable
              data={tableData}
              itemsPerPage={10}
              columns={Object.keys(tableData[0])
                .filter((i) => i != "id")
                .map((item) => ({
                  title: item,
                }))}
            />
          )
        )}
        {isError && (
          <Text
            text="Unable to fetch SSO List ! Try Again"
            size="medium"
            weight="normal"
            color="#333"
          />
        )}
      </ContentSection>
    </ConfigureSSOContainer>
  );
};
