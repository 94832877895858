import styled from "@emotion/styled";

export const DevelopWrapper = styled.div`
  padding: 2rem;
`;

export const Heading = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const CardWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 2.5rem 0;
`;
