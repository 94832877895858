import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { IOrgResponse } from "../../interfaces/org-config.interface";

const BASE_URL = process.env.NODE_ENV === 'production' ? (window as any)?.env.REACT_APP_API_BASE_URL : process.env.REACT_APP_API_BASE_URL

export const OrgApiSlice = createApi({
  reducerPath: "Organisation",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers) => {
      headers.set("X-Request-ID", "f640eb45-05ce-443c-8cd4-bdb4d45cad01");

      return headers;
    },
  }),
  tagTypes: ["Org"],
  endpoints: (builder) => ({
    getOrgConfig: builder.query<IOrgResponse, any>({
      query: ({ org_slug }) => ({
        url: `/org/init-config/${org_slug}`,
        method: "GET",
      }),
    }),
  }),
});

export const { useGetOrgConfigQuery } = OrgApiSlice;
