import styled from "@emotion/styled";

export const NewOrganisationButton = styled.div`
  margin-bottom: 30px;
`;

export const OrganisationWrapper = styled.div`
  padding: 3em;
`;

export const AddOrganisationActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 16px;
`;

export const FormRow = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 0.5rem;
`;
