import styled from "@emotion/styled";

export const DHFormContainer = styled.div`
  display: grid;
  grid-template-rows: auto;
  gap: 10px;
  padding: 16px;
  align-items: baseline;
  justify-items: start;
`;

export const DHFormField = styled.div`
  display: grid;
  grid-template-rows: auto 1fr; /* 4 rows */
  gap: 4px;
  padding: 8px;

  & > .MuiGrid-item > input {
    padding: 12px;
  }
`;
