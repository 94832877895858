import { useSignOut } from "./useSignOut";

export const useUserMenu = () => {
  const isPlatform = window.location.href.indexOf("platform") > -1;
  const signOutItem = useSignOut();

  const organisationsMenu = [
    {
      id: 'user_settings',
      name: 'User Settings',
      onClickCb: () => {
        console.log('clicked')
      }
    },
    {
      id: 'api_token',
      name: 'API Token',
      onClickCb: () => {
        console.log('clicked')
      }
    },
    {
      id: 'version_info',
      name: 'Version Info',
      onClickCb: () => {
        console.log('clicked')
      }
    },
    {
      id: 'org_management',
      name: 'Org Management',
      onClickCb: () => {
        console.log('clicked')
      }
    },
    {
      id: 'access_points',
      name: 'Access Points',
      onClickCb: () => {
        console.log('clicked')
      }
    },
    signOutItem
  ];

  const platformMenu = [
    signOutItem
  ];

  return isPlatform ? platformMenu : organisationsMenu;
};
