import styled from "@emotion/styled";

export const ConfigureSSOContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2rem;
  padding: 1rem;
  min-height: 100vh;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-left: 2rem;
  margin: 2rem 0;
`;

export const ContentSection = styled.div`
  border: 1px solid #d7d7d7;
  border-radius: 5px;
  width: 98%;
  display: flex;
  align-items: center;
  padding: 1rem;
`;

export const AddContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0.5rem;
  width: 100%;
`;
export const AddRadioContainer = styled.div`
  padding-left: 1rem;
  color: #333333;
  width: 100%;
`;

export const ProviderFormSection = styled.div`
  width: 98%;
  border: 1px solid #d7d7d7;
  border-radius: 5px;
`;

export const InputSection = styled.div`
  padding-left: 1rem;
`;

export const ProviderFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1rem 2rem;
  gap: 1rem;
  width: 100%;
`;

export const FormTextArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.05rem;
`;

export const ButtonSection = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 98%;
  gap: 0.5rem;
`;
