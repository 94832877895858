import styled from "@emotion/styled";
import IconButton from "@mui/material/IconButton";

export const MenuBarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 0.7rem;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  gap: 8px;
`;

export const ActionButton = styled(IconButton)`
  border-radius: 5px;
  border: 1px solid #d7d7d7;
  background-color: #ffffff;
`;
