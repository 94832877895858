import React, { FC } from "react";
import { InputWrapper } from "./input.style";
import type { Space } from "../../utils/style-type";

interface IInputBox {
  type: any;
  placeholder: string;
  value: string | number;
  onChangeCb: (e: any) => void;
  size: Space;
  onBlurCb?: (e: any) => void;
  isInValid?: boolean;
  name?: string;
}

export const InputBox: FC<IInputBox> = ({
  type,
  placeholder,
  value,
  onChangeCb,
  size,
  onBlurCb,
  isInValid,
  name
}) => (
  <InputWrapper
    type={type}
    space={size}
    placeholder={placeholder}
    value={value}
    onChange={onChangeCb}
    onBlur={onBlurCb}
    isInValid={isInValid}
    name={name}
  />
);
