import React, { FC, ReactNode } from "react";
import { TagWrapper } from "./tag.style";

interface ITag {
  children: ReactNode;
}

export const Tag: FC<ITag> = ({ children }) => (
  <TagWrapper>{children}</TagWrapper>
);
