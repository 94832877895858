import React, { FC } from "react";
import { ErrorMessage } from "components/error/error.style";

interface Props {
  message: string;
}

export const ErrorComponent: FC<Props> = ({ message }) => (
  <ErrorMessage>{message}</ErrorMessage>
);
