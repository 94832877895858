import { FC, useEffect } from 'react';
import { QueryPlanWrapper } from './query-plan.style';
import { Text } from 'components';
import { useExplainSqlScriptMutation } from 'store/api/sql-editor.api';

interface QueryPlanProps {
  sql: string
}

export const QueryPlan: FC<QueryPlanProps> = ({ sql }) => {
  const queryPlan = `== Analyzed Logical Plan ==
  
  c_custkey: bigint, c_name: string, c_address: string, c_nationkey: string, c_phone: string, c_acctbal: string, c_mktsegment: string, c_comment: string
  
  Project [c_custkey#2645L, c_name#2646, c_address#2647, c_nationkey#2648, c_phone#2649, c_acctbal#2650, c_mktsegment#2651, c_comment#2652]
  
  +- SubqueryAlias bb.tpcds.meharban.test.qa.customer
  
     +- RelationV2[c_custkey#2645L, c_name#2646, c_address#2647, c_nationkey#2648, c_phone#2649, c_acctbal#2650, c_mktsegment#2651, c_comment#2652] Iceberg bb.tpcds.meharban.test.qa.customer
  
  == Optimized Logical Plan ==
  
  RelationV2[c_custkey#2645L, c_name#2646, c_address#2647, c_nationkey#2648, c_phone#2649, c_acctbal#2650, c_mktsegment#2651, c_comment#2652] Iceberg bb.tpcds.meharban.test.qa.customer
  
  == Physical Plan ==
  
  *(1) ColumnarToRow
  
  +- BatchScan[c_custkey#2645L, c_name#2646, c_address#2647, c_nationkey#2648, c_phone#2649, c_acctbal#2650, c_mktsegment#2651, c_comment#2652] bb.tpcds.meharban.test.qa.customer (branch=null) [filters=, groupedBy=] RuntimeFilters: []`;

  const [
    explainSqlScript,
    {
      data,
      isLoading,
      isSuccess,
      isError
    }
  ] = useExplainSqlScriptMutation();

  useEffect(() => {
    explainSqlScript({
      sql: sql
    })
  }, []);

  console.log(data);

  return (
    <QueryPlanWrapper>
      <pre>
        <Text text={data?.plan || ''} size="small" color="#717171" marginBottom={0.01} weight="normal" />
      </pre>
    </QueryPlanWrapper>
  )
};
