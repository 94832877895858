import React, { ReactNode, FC, useMemo } from "react";
import { OktaAuthProvider } from "./okta-provider/okta-provider.component";
import { MsalOutlookProvider } from "./msal-provider/msal-provider.component";
import { LoginMODE } from "../../../utils/constants/login-mode";
import { ZetProvider } from "./zet-provider/zet-provider.component";

interface IAuthProvider {
  children: ReactNode;
}

export const AuthProvider: FC<IAuthProvider> = ({ children }) => {
  const loginMode = localStorage.getItem("loginMode");

  const ProviderComponent = useMemo(() => {
    switch (loginMode) {
      case LoginMODE.OUTLOOK:
        return MsalOutlookProvider;
      case LoginMODE.OKTA:
        return OktaAuthProvider;
      default:
        return ZetProvider;
    }
  }, [loginMode]);

  const CustomAuthProvider = ProviderComponent || ZetProvider;

  return <CustomAuthProvider>{children}</CustomAuthProvider>;
};
