import React, { FC, useEffect, useState } from 'react';
import { Button, InputBox, Text } from 'components/index';
import { LoginButton, LoginForm, LoginHeader, LoginInput } from 'pages/login/login.style';
import { passwordReset } from 'utils/login-fields';
import { RESET } from 'utils/constants/message';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { usePlatformResetPasswordMutation } from 'store/api/platform-auth.api';
import { Loader } from 'components/mui-components/mui-components.index';
import { PWD_REGEX } from 'utils/constants/validation';

export const PlatformResetPassword: FC = () => {
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [ createPost, {isError, isLoading, isSuccess}] = usePlatformResetPasswordMutation();

  useEffect(() =>{
      if(isSuccess) {
        navigate('../reset-password-success')
      }
  }, [isError, isSuccess])

  const [searchParams] = useSearchParams();
  const token = searchParams.get('t')?.replaceAll(' ', '+') || '';

  const setUserValue = (title: string) => title === 'New Password' ? setNewPassword : setConfirmPassword
  const getValue = (title: string) =>
    title === "New Password" ? newPassword : confirmPassword;


  const checkValidation = (type: string): boolean => {
    if (type === "Confirm Password") {
      return (newPassword !== confirmPassword) && !!confirmPassword
    } else {
      return !PWD_REGEX.test(newPassword) && !!newPassword
    }
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()

    if(newPassword === confirmPassword) createPost({token: token, password: confirmPassword})
  }

  return (
    <>
      <LoginHeader>
        <Text size="medium" weight={200} text="Password Reset" />
      </LoginHeader>

      {isLoading && <Loader />}

      <LoginForm onSubmit={handleSubmit}>
        {passwordReset.map((field) => (
          <LoginInput key={field.title}>
            <Text
              size={field.textSize}
              weight={field.weight}
              text={field.title}
            />
            <InputBox
              type={field.inputType}
              size={field.inputSize}
              placeholder=""
              value={getValue(field.title)}
              onChangeCb={(e) => setUserValue(field.title)(() => e.target.value)}
              isInValid={checkValidation(field.title)}
            />
            {field.title === 'New Password' && (
              <Text size="small" weight={200} text={RESET.PASSWORD_VALIDATION_MSG} />
            )}
          </LoginInput>
        ))}

        <LoginButton>
          <Button
            type="submit"
            buttonType="primary"
            title="Reset"
            space="large"
          />
        </LoginButton>
      </LoginForm>
    </>
  )
}