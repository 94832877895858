import { LogLevel } from "@azure/msal-browser";
import { SSOConfig } from "../interfaces/org-config.interface";

export const getOutlookConfig = (config: SSOConfig, org_slug: string) => ({
  auth: {
    clientId: config.outlookClientId,
    authority: `https://login.microsoftonline.com/${config.outlookTenantId}`,
    redirectUri: `${window.location.protocol}//${window.location.host}/org/${org_slug}/auth/outlook`,
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: any, message: string, containsPii: any) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
    allowNativeBroker: true,
    tokenRenewalOffsetSeconds: 300,
  },
});

export const loginRequest = {
  scopes: ["User.Read"],
};

export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};
