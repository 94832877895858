import React, { FC, ReactNode } from "react";
import { CardWrapper } from "./card.style";

interface ICard {
  children?: ReactNode;
  style?: any;
}

export const Card: FC<ICard> = ({ children, style }) => (
  <CardWrapper style={style}>
    {children}
  </CardWrapper>
);
