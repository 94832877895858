import React, { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { Text, ImgComponent, FeatureCard } from "components";
import { useAppDispatch } from "store/hooks";
import { setLogOut } from "store/slice/login.slice";
import { HomepageWrapper, TitleContainer, CardWrapper, RightIconWrapper } from "./landingpage.style";
import PipelineIcon from "asset/images/pipeline.png";
import DataSource from "asset/images/landing-page-icons/datahub_blue.png";
import SqlScriptBlueIcon from "asset/images/landing-page-icons/sqlscript_blue.png";
import SqlBuilderBlueIcon from "asset/images/landing-page-icons/sqlbuilder_blue.png";
import SearchBlueIcon from "asset/images/landing-page-icons/search_blue.png";
import UnifyBlueIcon from "asset/images/landing-page-icons/unify_select_blue.png";
import UnifyGreyIcon from "asset/images/landing-page-icons/unify_grey.png";
import DevelopGreyIcon from "asset/images/landing-page-icons/develop_grey.png";
import PipelineBlueIcon from "asset/images/landing-page-icons/pipeline_blue.png";
import { AddIcon, FolderIcon } from 'asset/svg-icon';
import { dataSourceContent, sqlScriptsContent, sqlBuilderContent, pipelineContent, dataProductContent } from 'utils/home-page-fields';

export const LandingPageComponent: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const idToken = localStorage.getItem("idToken");

  useEffect(() => {
    if (!idToken) {
      dispatch(setLogOut());
      navigate("../auth/login");
    }
  }, [idToken]);

  const DataSourceData = {
    leftSideIcon: DataSource,
    rightSideIcon: [
      {
        name: 'add',
        icon: <AddIcon />,
        text: 'Add',
        onClickCb: () => console.log('add data source')
      },
    ],
    content: dataSourceContent,
    footerAction: [
      {
        icon: <ImgComponent src={SearchBlueIcon} size={'small'} />,
        text: 'Explore Data',
        onClickCb: () => console.log('Explore data source')
      }
    ]
  }

  const SQLScriptsData = {
    leftSideIcon: SqlScriptBlueIcon,
    rightSideIcon: [
      {
        name: 'folder',
        icon: <FolderIcon />,
        text: 'Folder',
        onClickCb: () => console.log('folder sqlscripts')
      },
      {
        name: 'add',
        icon: <AddIcon />,
        text: 'Add',
        onClickCb: () => console.log('add sqlscripts')
      },

    ],
    content: sqlScriptsContent,
  }

  const SQLBuilderData = {
    leftSideIcon: SqlBuilderBlueIcon,
    rightSideIcon: [
      {
        name: 'folder',
        icon: <FolderIcon />,
        text: 'Folder',
        onClickCb: () => console.log('folder sqlbuilder')
      },
      {
        name: 'add',
        icon: <AddIcon />,
        text: 'Add',
        onClickCb: () => console.log('add sqlbuilder')
      },

    ],
    content: sqlBuilderContent,
  }

  const PipelineData = {
    leftSideIcon: PipelineBlueIcon,
    rightSideIcon: [
      {
        name: 'folder',
        icon: <FolderIcon />,
        text: 'Folder',
        onClickCb: () => console.log('folder pipeline')
      },
      {
        name: 'add',
        icon: <AddIcon />,
        text: 'Add',
        onClickCb: () => console.log('add pipeline')
      },

    ],
    content: pipelineContent,
  }

  const DataProductData = {
    leftSideIcon: UnifyBlueIcon,
    rightSideIcon: [
      {
        name: 'add',
        icon: <AddIcon />,
        text: 'Add',
        onClickCb: () => console.log('Add data product')
      },
    ],
    content: dataProductContent,
    footerAction: [
      {
        icon: <ImgComponent src={SearchBlueIcon} size={'small'} />,
        text: 'Explore Products',
        onClickCb: () => console.log('Explore data product')
      }
    ]
  }

  return (
    <HomepageWrapper>
      <Grid container sx={{ padding: "8px" }}>
        <Grid item xs={4} style={{ borderRight: '1px solid #F4F3F3', position: 'relative', padding: '8px' }}>
          <TitleContainer>
            <ImgComponent src={PipelineIcon} size="small" />
            <Text size='medium' weight='bold' text='Datahub' color='#676E72' marginBottom={0.1} />
          </TitleContainer>
          <CardWrapper>
            <FeatureCard
              leftSideIcon={DataSourceData.leftSideIcon}
              contents={DataSourceData.content}
              cardBgColor="#F9F9F9"
              lefeSideIconSize="small"
              cardRightSideIcons={DataSourceData.rightSideIcon}
              footerActionContent={DataSourceData.footerAction}
            />
          </CardWrapper>
          <RightIconWrapper><KeyboardDoubleArrowRightIcon sx={{ color: '#9F9F9F' }} /></RightIconWrapper>

        </Grid>
        <Grid item xs={4} style={{ borderRight: '1px solid #F4F3F3', position: 'relative', padding: '8px' }}>
          <TitleContainer>
            <ImgComponent src={DevelopGreyIcon} size="small" />
            <Text size='medium' weight='bold' text='Workbench' color='#676E72' marginBottom={0.1} />
          </TitleContainer>
          <CardWrapper>
            <FeatureCard
              leftSideIcon={SQLScriptsData.leftSideIcon}
              contents={SQLScriptsData.content}
              cardBgColor="#F9F9F9"
              lefeSideIconSize="small"
              cardRightSideIcons={SQLScriptsData.rightSideIcon}
            />
            <FeatureCard
              leftSideIcon={SQLBuilderData.leftSideIcon}
              contents={SQLBuilderData.content}
              cardBgColor="#F9F9F9"
              lefeSideIconSize="small"
              cardRightSideIcons={SQLBuilderData.rightSideIcon}
            />
            <FeatureCard
              leftSideIcon={PipelineData.leftSideIcon}
              contents={PipelineData.content}
              cardBgColor="#F9F9F9"
              lefeSideIconSize="small"
              cardRightSideIcons={PipelineData.rightSideIcon}
            />
          </CardWrapper>
          <RightIconWrapper><KeyboardDoubleArrowRightIcon sx={{ color: '#9F9F9F' }} /></RightIconWrapper>
        </Grid>
        <Grid item xs={4} style={{ padding: '8px' }}>
          <TitleContainer>
            <ImgComponent src={UnifyGreyIcon} size="small" />
            <Text size='medium' weight='bold' text='Data Products' color='#676E72' marginBottom={0.1} />
          </TitleContainer>
          <CardWrapper>
            <FeatureCard
              leftSideIcon={DataProductData.leftSideIcon}
              contents={DataProductData.content}
              cardBgColor="#F9F9F9"
              lefeSideIconSize="small"
              cardRightSideIcons={DataProductData.rightSideIcon}
              footerActionContent={DataProductData.footerAction}
            />
          </CardWrapper>
        </Grid>
      </Grid>

    </HomepageWrapper>
  );
};
