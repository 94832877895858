import styled from "@emotion/styled";

export const Header = styled.div`
  position: sticky;
  top: 0;
  height: 2.5rem;
  display: flex;
  align-items: center;
  background-color: rgba(1, 13, 20, 0.8);
  padding: 0.25rem;
  z-index: 999;
`;

export const Logo = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-right: 3rem;
  cursor: pointer;
`;
