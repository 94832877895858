import React, { FC, useEffect } from "react";
import { Loader } from "components/mui-components/mui-components.index";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import { InteractionStatus } from "@azure/msal-browser";

export const OutlookAuth: FC<any> = () => {
  const { inProgress, instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();

  const getToken = async () => {
    await instance.initialize();
    const account = accounts[0];
    const tokenRequest = {
      account: account,
      scopes: ["User.read"],
    };

    try {
      const token = await instance.acquireTokenSilent(tokenRequest);
      console.log("Token Acquired:", token.accessToken);
      localStorage.setItem("idToken", JSON.stringify(token.idToken));
    } catch (error) {
      console.error("Failed to refresh token:", error);
    }
  };

  const authRedirect = async () => {
    try {
      await instance.initialize();
      const activeAccount = instance.getActiveAccount();

      if (activeAccount != null || !isAuthenticated) {
        const loginResult = await instance.loginPopup();
        await instance.handleRedirectPromise();
        localStorage.setItem("idToken", JSON.stringify(loginResult.idToken));

        const tokenExpiry = new Date(loginResult.expiresOn || 0).toISOString();
        localStorage.setItem("expiry", tokenExpiry);
      }
      await instance.handleRedirectPromise();
    } catch (error) {
      console.log("error while login in with msal", error);
      // navigate("auth/login");
    }
  };

  useEffect(() => {
    const activeAccount = instance.getActiveAccount();
    console.log({ inProgress, activeAccount, isAuthenticated });
    if (inProgress === InteractionStatus.None && activeAccount != null) {
      localStorage.setItem("active", JSON.stringify(activeAccount));
      getToken();
    } else {
      authRedirect();
    }

    isAuthenticated && redirectOnSuccess();
  }, [inProgress, instance, isAuthenticated]);

  const redirectOnSuccess = () => navigate("../../home");
  return <Loader />;
};
