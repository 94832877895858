import { apiSlice } from "./api.slice";
import {
  IContainersResponse,
  IContainerRequest,
  ISqlScriptRequest,
  ISqlScriptResponse,
  ISqlScriptUpdateRequest,
  ISqlScriptHistoryResponse
} from "interfaces/sql-editor.interface";

export const SqlEditorApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getContainersList: build.query<IContainersResponse[], any>({
      query: () => ({
        url: "/containers",
        method: "GET",
      }),
      providesTags: ['Containers'],
    }),
    createContainer: build.mutation<any, IContainerRequest>({
      query: (payload) => ({
        url: '/containers',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['Containers'],
    }),
    getSqlScriptsByContainer: build.query<ISqlScriptResponse[], string>({
      query: (ContainerId) => ({
        url: `/sql-editor/scripts/container/${ContainerId}`,
        method: "GET",
      }),
    }),
    saveSqlScript: build.mutation<any, ISqlScriptRequest>({
      query: (payload) => ({
        url: '/sql-editor/scripts',
        method: 'POST',
        body: payload,
      }),
    }),
    updateSqlScript: build.mutation<any, ISqlScriptUpdateRequest>({
      query: ({ id, payload }) => ({
        url: `/sql-editor/scripts/${id}`,
        method: 'PATCH',
        body: payload,
      }),
    }),
    getSqlScriptsHistory: build.query<ISqlScriptHistoryResponse[], void>({
      query: () => ({
        url: '/sql-editor/sqls/history',
        method: "GET",
      }),
    }),
    runSqlScript: build.mutation<any, any>({
      query: (payload) => ({
        url: '/sql-editor/sqls/run',
        method: "POST",
        body: payload,
      }),
    }),
    explainSqlScript: build.mutation<any, any>({
      query: (payload) => ({
        url: '/sql-editor/sqls/explain',
        method: "POST",
        body: payload,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetContainersListQuery,
  useCreateContainerMutation,
  useLazyGetSqlScriptsByContainerQuery,
  useSaveSqlScriptMutation,
  useUpdateSqlScriptMutation,
  useGetSqlScriptsHistoryQuery,
  useRunSqlScriptMutation,
  useExplainSqlScriptMutation
} = SqlEditorApi;
