import React, { FC } from "react";
import { ImgComponent, Text } from "components";
import { DataSourceItemContainer } from "../../create-data-hub.styles";

interface IDataSourceItem {
  imgSrc: string;
  title: string;
  selected: boolean;
  onClickCb: () => void;
}

export const DataSourceItem: FC<IDataSourceItem> = ({
  imgSrc,
  title,
  selected,
  onClickCb,
}) => (
  <DataSourceItemContainer onClick={onClickCb} selected={selected}>
    <ImgComponent src={imgSrc} alt={title} size="xLarge" />
    <Text text={title} size="small" weight="normal" />
  </DataSourceItemContainer>
);
