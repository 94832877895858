import styled from "@emotion/styled";

export const BannerWrapper = styled.div`
  position: fixed;
  font-family: "HighlandGothicFLF", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 100%;
  padding: 1rem;
  top: 0;
`;
