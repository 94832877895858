import { FC, useState, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Card, Banner } from "components/index";
import { AuthWrapper, LoginWrapper } from "pages/login/login.style";
import { usePlatformConfigQuery } from 'store/api/platform-auth.api';
import { Loader } from "components/mui-components/mui-components.index";

export const PlatformAuthContainer: FC = () => {
  const [loading, setLoading] = useState(true);
  const idToken = localStorage.getItem("idToken");
  const navigate = useNavigate();
  const platformConfig = usePlatformConfigQuery(undefined);

  useEffect(() => {
    setLoading(false);
    if(idToken) {
      navigate('/platform/organisations');
    }
  }, [idToken, navigate]);

  return (
    <AuthWrapper>
      {loading ? <Loader /> : 
      <Card>
        <LoginWrapper>
          <Banner />
          <Outlet context={platformConfig} />
        </LoginWrapper>
      </Card>}
    </AuthWrapper>
  );
}
