import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IErrorState {
  isError: boolean;
  errorMessage: string;
}

const initialState: IErrorState = {
  isError: false,
  errorMessage: "Oops! Something went wrong.",
};

const errorSlice = createSlice({
  name: "errorSlice",
  initialState,
  reducers: {
    setErrorMessage: (state, action: PayloadAction<string>) => ({
      ...state,
      errorMessage: action.payload,
    }),
    setIsError: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isError: action.payload,
    }),
  },
});

export const { setErrorMessage, setIsError } = errorSlice.actions;
export default errorSlice.reducer;
