import { Space } from "./style-type";

interface IAddGroupFields {
  title: string
  name: string
  weight: number
  textSize: Space
  inputSize: Space
  inputType: string
  placeholder?: string
  value: string
}

export const addGroupFields: IAddGroupFields[] = [
  {
    title: 'Group Name:',
    name: 'name',
    weight: 200,
    textSize: 'small',
    inputSize: 'medium',
    inputType: 'text',
    placeholder: 'Please enter something',
    value: ''
  }
]
