import styled from "@emotion/styled";

export const ButtonIconStyle = {
  backgroundColor: "#0298f2",
  textTransform: "none",
  width: "135px",
  height: "35px",
};

export const DHContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const DHListContainer = styled.div`
  flex: 5;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 1rem;
  width: auto;
`;

export const DHListHeader = styled.div`
  display: flex;
  width: 100%;
  padding-right: 1.5rem;
`;
export const IconWrap = styled.div`
  border: 1px solid #d7d7d7;
  border-radius: 5px;
  padding: 9px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const DHLeftMenuContainer = styled.div`
  flex: 1;
  border-right: 1px solid #d7d7d7;
  height: 100vh;
`;

export const DHButtonWrap = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  gap: 1rem;
`;

export const SelectHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  margin-bottom: 1rem;
`;

export const SelectDataSourceContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  width: auto;
  padding: 1rem 2rem;
  min-width: 55rem;
  min-height: 40rem;
  overflow-y: auto;
  gap: 1rem;
`;

export const SelectFooterContainer = styled.div`
  border-top: 1px solid #d7d7d7;
  margin-top: auto;
  padding: 1rem;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 0.5rem;
  width: 100%;
`;

export const TitleWrapper = styled.span`
  display: inline-flex;
  gap: 10px;
`;

export const TableWrapper = styled.div`
  overflow-y: auto;
  width: 100%;
`;
