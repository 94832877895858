import React, { FC, useEffect, useRef } from "react";
import OktaSignIn from "@okta/okta-signin-widget";
import { useAppSelector } from "../../../store/hooks";
import { getOktaConfig } from "../../../utils/okta-config";
import { RootState } from "../../../store/store";
import { initOktaConfig } from "../../../utils/constants/okta-constants";

interface IOktaSign {
  onSuccess: (token: any) => void;
  onError: (token: any) => void;
}

export const OktaSignInWidget: FC<IOktaSign> = ({ onSuccess, onError }) => {
  const widgetRef = useRef<HTMLDivElement | null>(null);
  const { orgOktaConfig } = useAppSelector(
    (state: RootState) => state.orgSlice
  );

  useEffect(() => {
    if (!widgetRef.current) {
      return;
    }

    const widget: any = new OktaSignIn(
      getOktaConfig(orgOktaConfig || initOktaConfig)
    );

    // Search for URL Parameters to see if a user is being routed to the application to recover password
    var searchParams = new URL(window.location.href).searchParams;
    widget.otp = searchParams.get("otp");
    widget.state = searchParams.get("state");
    widget
      .showSignInToGetTokens({
        el: widgetRef.current,
      })
      .then(onSuccess)
      .catch(onError);

    return () => widget.remove();
  }, [onSuccess, onError, orgOktaConfig, widgetRef]);

  return <div ref={widgetRef} />;
};
