import { FC } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { StyledListItem, Status } from './history.style';
import { Text } from 'components';
import { useGetSqlScriptsHistoryQuery } from 'store/api/sql-editor.api';

export const History: FC = () => {
  const {
    data: SqlScripts,
    isLoading,
    isError,
    isSuccess,
  } = useGetSqlScriptsHistoryQuery(undefined);

  return (
    <List dense={true}>
      {isSuccess && SqlScripts && SqlScripts.map(sqlScript => (
        <StyledListItem divider key={sqlScript.id}>
          <Status>
            <Text text={sqlScript.status} size="small" color="#ffffff" marginBottom={0.01} weight="normal" />
          </Status>
          <Text text={sqlScript.time.toLocaleString()} size="small" color="#717171" marginBottom={0.01} weight="normal" />
          <Text text={sqlScript.sql} size="small" color="#717171" marginBottom={0.01} weight="normal" />
        </StyledListItem>
      ))}
    </List>
  )
};
