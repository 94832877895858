import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { setAuthToken, setLogOut } from "../slice/login.slice";
import { IAuthResponse } from "interfaces/auth-response.interface";

const BASE_URL = process.env.NODE_ENV === 'production' ? (window as any)?.env.REACT_APP_API_BASE_URL : process.env.REACT_APP_API_BASE_URL

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL + '/platform',
  prepareHeaders: (headers, { getState, endpoint }) => {    
    headers.set("X-Request-ID", "f640eb45-05ce-443c-8cd4-bdb4d45cad01");

    // WILL UPDATE THIS LOGIC SOMETIME LATER WITH
    // SOMETHING MORE CLEANER THAN THIS - 
    // FOR NOW NEED TO WAIT ANF CHECK WHICH
    // APIs NEED WHAT HEADERS EXACTLY
    if (
      endpoint !== 'platformConfig' 
      && endpoint !== 'platformAuthToken' 
      && endpoint !== 'platformResetMail' 
      && endpoint !== 'platformResetPassword'
    )
      headers.set("Authorization", `Bearer ${JSON.parse(localStorage.getItem("idToken") || "")}`);

    return headers;
  },
});

const baseRefreshQuery = fetchBaseQuery({
  baseUrl: BASE_URL + '/platform',
  prepareHeaders: (headers) => {
    headers.set("X-Request-ID", "f640eb45-05ce-443c-8cd4-bdb4d45cad01");
    headers.set("Authorization", `Bearer ${JSON.parse(localStorage.getItem("refreshToken") || "")}`);

    return headers;
  },
});

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result?.error?.status === 404) {
    // send refresh token to get new access token
    const refreshResult = await baseRefreshQuery("auth/refresh", api, extraOptions);
    
    if (refreshResult?.data) {
      // store the new token
      api.dispatch(setAuthToken(refreshResult?.data as IAuthResponse));

      // retry the original query with new access token
      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(setLogOut());
    }
  }

  return result;
};

export const PlatformApi = createApi({
  reducerPath: 'api/platform',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({}),
});
