import { SSOLoginInfo } from "../interfaces/org-config.interface";

interface ISSORoute {
    title : string,
    to: string
}

export const getSSoTitle = (configDetails : SSOLoginInfo) : ISSORoute => {
    const {name, provider} = configDetails;
    return { 
        title: `Login via ${provider} - ${name}`,
        to: `../${provider.toLowerCase()}`
    };
}