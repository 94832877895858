import React, { FC } from "react";
import { Image } from "./img.style";

export type imgSize = "small" | "medium" | "large" | "default" | "xLarge";

interface IImg {
  src: any;
  size: imgSize;
  alt?: string;
}

export const ImgComponent: FC<IImg> = ({ src, size, alt }) => (
  <Image src={src} size={size} alt={alt} />
);
