import { FC, useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { useAppDispatch } from 'store/hooks';
import { SqlEditorPageWrapper } from './sql-editor.style';
import { Sidebar } from './sidebar/sidebar.component';
import { Editor } from './editor/editor.component';
import { useGetContainersListQuery } from 'store/api/sql-editor.api';
import { setIsError } from 'store/slice/error.slice';

export const SqlEditor: FC = () => {
  const dispatch = useAppDispatch();
  const {
    data: containers,
    isLoading: isContainersLoading,
    isError: isContainersError,
  } = useGetContainersListQuery(undefined);

  useEffect(() => {
    dispatch(setIsError(isContainersError))
  }, [isContainersError])

  return (
    <SqlEditorPageWrapper>
      <CssBaseline />
      {!isContainersLoading && <Sidebar containers={containers || []} />}
      {!isContainersLoading && <Editor containers={containers || []} />}
    </SqlEditorPageWrapper>
  );
}
