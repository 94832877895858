import React, { FC, useEffect, useState } from "react";
import { PageHeading } from "components/page-heading/page-heading.component";
import {
  DHListContainer,
  DHListHeader,
  IconWrap,
  DHButtonWrap,
  TitleWrapper,
  TableWrapper,
} from "pages/data-hub/data-hub.style";
import dataHubLogo from "asset/images/data-hub.png";
import { Button, ImgComponent } from "components";
import { RefreshIcon, OutlinePlusIcon } from "asset/svg-icon";
import { CreateDataSourceContainer } from "../create-data-hub/create-data-hub.container";
import { useGetDataSourceListQuery } from "store/api/dataSource.slice";
import { Loader } from "components/mui-components/mui-components.index";
import { Tooltip } from "@mui/material";
import { DataSourcIconList } from "pages/data-hub/data-source-icon";
import { MultiElementTable } from "components/multi-element-table/multi-element-table.component";
import {
  StatusDialogComponent,
  StatusType,
} from "components/status-dialog/status-dialog.component";

const Img = <ImgComponent src={dataHubLogo} size="large" />;

export const DHListComponent: FC<any> = () => {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [showStatus, setShowStatus] = useState<boolean>(false);
  const [dataSourceList, setDataSourceList] = useState<any>([]);
  const {
    data: tableData,
    isLoading,
    isSuccess,
    isError,
    refetch,
  } = useGetDataSourceListQuery(undefined);

  useEffect(() => {
    if (isSuccess) {
      setDataSourceList(() =>
        tableData.map(({ dataSourceId, ...rest }) => ({
          source: (
            <ImgComponent
              src={DataSourcIconList[rest.connectionType]}
              size="small"
            />
          ),
          ...rest,
        }))
      );
    }
  }, [tableData, isSuccess]);

  const DhColumns = Object.keys(dataSourceList[0] || {}).map((key) => ({
    title: key,
  }));

  return (
    <DHListContainer>
      {openDialog && (
        <CreateDataSourceContainer
          onCancel={() => setOpenDialog(false)}
          onComplete={() => {
            setOpenDialog(false);
            setShowStatus(true);
            refetch();
          }}
        />
      )}
      {showStatus && (
        <StatusDialogComponent
          type={StatusType.SUCCESS}
          text="Successfully Created Data Source"
          onClose={() => setShowStatus(false)}
        />
      )}

      <DHListHeader>
        <PageHeading title="DataHub" Icon={Img} isImg={true} />
        <DHButtonWrap>
          <Button
            type="button"
            buttonType="info"
            title={
              <TitleWrapper>
                <OutlinePlusIcon /> Data Source
              </TitleWrapper>
            }
            space="small"
            onClickCb={() => setOpenDialog((prev) => !prev)}
          />
          <Tooltip title="Refetch" arrow placement="top">
            <IconWrap onClick={() => refetch()}>
              <RefreshIcon />
            </IconWrap>
          </Tooltip>
        </DHButtonWrap>
      </DHListHeader>
      <TableWrapper id="ListSections">
        {isLoading && <Loader />}
        {isSuccess && dataSourceList.length && (
          <MultiElementTable
            itemsPerPage={15}
            data={dataSourceList}
            columns={DhColumns}
          />
        )}

        {isError && <div> Unable to load Data Sources </div>}
      </TableWrapper>
    </DHListContainer>
  );
};
