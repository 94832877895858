import React, { FC } from "react";
import { Button, Text, ImgComponent, Card } from 'components';
import { FeatureCardWrapper, CardBody, CardRightSideIconWrapper, FooterActionWrapper } from "./card.style";
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

type ContectType = {
    text: string;
    weight: string;
    size: string;
    color: string;
    marginBottom: string;
}

type RightSideIconType = {
    name: string;
    icon: React.ReactNode;
    text: string;
    onClickCb: () => void;
}

type FooterActionType = {
    icon: React.ReactNode;
    text: string;
    onClickCb: () => void;
}

interface ICard {
    cardBgColor?: string;
    leftSideIcon?: string;
    lefeSideIconSize?: string | any;
    contents?: Array<ContectType>;
    cardRightSideIcons?: Array<RightSideIconType>;
    footerActionContent?: Array<FooterActionType>;
}

export const FeatureCard: FC<ICard> = ({ cardBgColor, leftSideIcon, lefeSideIconSize, contents, cardRightSideIcons, footerActionContent }) => {
    return (
        <FeatureCardWrapper bgColor={cardBgColor}>
            <CardBody>
                <ImgComponent src={leftSideIcon} size={lefeSideIconSize} />
                {
                    cardRightSideIcons &&
                    <CardRightSideIconWrapper>
                        {
                            cardRightSideIcons.map((cardRightSideIcon: any) => {
                                const Icon = cardRightSideIcon.icon
                                return (
                                    <Tooltip title={cardRightSideIcon.text} key={cardRightSideIcon.text}>
                                        <IconButton onClick={cardRightSideIcon.onClickCb}>
                                            {Icon}
                                        </IconButton>
                                    </Tooltip>)
                            })
                        }
                    </CardRightSideIconWrapper>
                }

            </CardBody>
            {
                contents && contents.map((content: any) =>
                    <Text key={content.text} size={content.size} weight={content.weight} text={content.text} color={content.color} marginBottom={content.marginBottom} />)
            }

            {
                footerActionContent &&
                <FooterActionWrapper>
                    {
                        footerActionContent.map((footerActionContent: any) => {
                            return (
                                <Button
                                    type="button"
                                    buttonType="secondary"
                                    title={<>{footerActionContent.icon} {footerActionContent.text}</>}
                                    space="xsmall"
                                    onClickCb={footerActionContent.onClickCb}
                                    key={footerActionContent.text}
                                />)
                        })
                    }

                </FooterActionWrapper>
            }
        </FeatureCardWrapper>
    )
};
