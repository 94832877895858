import React, { FC, useState, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { Loader } from 'components/mui-components/mui-components.index';
import { LayoutComponent } from 'components/layout/layout.component';
import { PlatformLeftMenuItems } from 'utils/left-menu-items';
import { useUserMenu } from 'hooks/useUserMenu';
import { useAppSelector } from 'store/hooks';
import { usePlatformUserQuery } from 'store/api/platform-auth.api';

export const Platform: FC = () => {
  const [loading, setLoading] = useState(true);
  const idToken = localStorage.getItem("idToken");
  const navigate = useNavigate();
  const authToken = useAppSelector(state => state.loginHeader.authToken);
  const userDropdownItems = useUserMenu();
  const {
    data: userDetails,
    isLoading,
    isSuccess,
    isError
  } = usePlatformUserQuery(undefined);

  useEffect(() => {
    setLoading(false);
    if(!idToken || isError) {
      navigate('/platform/auth/login');
    }
  }, [authToken, idToken, isError, navigate]);

  return (
    <React.Fragment>
      {(loading || isLoading) && <Loader />}
      {isSuccess && <LayoutComponent
        menuItems={PlatformLeftMenuItems}
        userDropdownItems={userDropdownItems}
        userDetails={userDetails}
      >
        <Outlet />
      </LayoutComponent>}
    </React.Fragment>
  )
}
