import { FC } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { ErrorComponent } from "./error-component/error.component";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { setIsError } from "store/slice/error.slice";

export const ErrorWrapper: FC = () => {
  const dispatch = useAppDispatch();
  const { errorMessage } = useAppSelector((state) => state.error);

  const handleClose = () => {
    dispatch(setIsError(false));
  };

  return (
    <Dialog onClose={handleClose} open={true}>
      <DialogTitle>Error Occurred</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{ position: "absolute", right: 8, top: 12 }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <ErrorComponent message={errorMessage} />
      </DialogContent>
    </Dialog>
  );
};
