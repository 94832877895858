export const DataSourceFormFields: { [key: string]: any } = {
  dataSourceName: {
    label: "Data Source Name",
    name: "dataSourceName",
    type: "input",
    xs: "half",
  },
  description: {
    label: "Description",
    name: "description",
    type: "input",
    xs: "half",
  },
  connectionString: {
    label: "Connection URL",
    name: "connectionString",
    type: "input",
    xs: "full",
  },
  jdbcUrl: {
    label: "JDBC",
    name: "jdbcUrl",
    type: "input",
    xs: "full",
  },
  dataBaseName: {
    label: "Database Name",
    name: "dataBaseName",
    type: "input",
    xs: "half",
  },
  jdbcDriver: {
    label: "JDBC Driver",
    name: "jdbcDriver",
    type: "file",
    xs: "half",
  },
};
