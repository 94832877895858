import React, { FC } from "react";
import { TextWrapper } from "./text.style";
import type { Space } from "../../utils/style-type";

export type Weight = "lighter" | "normal" | "bold" | number;

interface IText {
  text: string;
  size: Space;
  weight: Weight;
  color?: string;
  className?: string;
  marginBottom?: number;
  onClickCb?: () => void;
}

export const Text: FC<IText> = ({
  text,
  size,
  weight,
  color,
  className,
  marginBottom,
  onClickCb,
}) => (
  <TextWrapper
    size={size}
    weight={weight}
    color={color}
    className={className}
    marginBottom={marginBottom}
    onClick={onClickCb}
  >
    {text}
  </TextWrapper>
);
