import React, { FC, useState } from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import { TabName } from "./tab.component";
import { TabPanel } from "./tab-panel.component";

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

interface ITabs {
  tabs: {
    title: string;
    content: JSX.Element;
  }[];
  onSelectedTab?: (selectedTab: number) => any;
}

export const TabsComponent: FC<ITabs> = ({ tabs, onSelectedTab }) => {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    onSelectedTab && onSelectedTab(newValue);
  };

  return (
    <React.Fragment>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={handleChange} aria-label="tabs">
          {tabs.map((tab, index) => (
            <TabName key={index} label={tab.title} {...a11yProps(index)} />
          ))}
        </Tabs>
      </Box>
      {tabs.map((tab, index) => (
        <TabPanel key={index} value={value} index={index}>
          {tab.content}
        </TabPanel>
      ))}
    </React.Fragment>
  );
};
