import { ILeftMenu } from "../interfaces/left-menu-item.interface";
import admin_grey from "asset/images/landing-page-icons/admin_grey.png";
import data_hub_grey from "asset/images/landing-page-icons/datahub2_grey.png";
import data_products_grey from "asset/images/landing-page-icons/unify_grey.png";
import genz_grey from "asset/images/landing-page-icons/genai_grey.png";
import governance_grey from "asset/images/landing-page-icons/security_grey.png";
import develop_grey from "asset/images/landing-page-icons/develop_grey.png";
import lakehouse_grey from "asset/images/landing-page-icons/lakehouse_grey.png";
import tools_grey from "asset/images/landing-page-icons/tools_grey.png";

export const OrganisationLeftMenuItems: ILeftMenu[] = [
  {
    icon: data_hub_grey,
    title: "Data Hub",
    to: "data-hub",
    childItems: [],
  },
  {
    icon: develop_grey,
    title: "Workbench",
    to: "develop",
    showChildSection: false,
    childItems: [
      { title: "SQL Editor", to: "develop/sql-editor", childItems: [] },
      { title: "SQL Builder", to: "develop/sql-builder", childItems: [] },
      { title: "Pipeline", to: "pipe-line", childItems: [] },
    ],
  },
  {
    icon: data_products_grey,
    title: "Data Products",
    to: "data-products",
    childItems: [],
  },
  {
    icon: governance_grey,
    title: "Governance",
    to: "governance",
    showChildSection: false,
    childItems: [
      {
        title: "Data Security",
        to: "governance/data-security",
        childItems: [
          {
            title: "Data Access",
            to: "governance/data-security/access",
            childItems: [],
          },
          {
            title: "Row & Column",
            to: "governance/data-security/row-column",
            childItems: [],
          },
        ],
      },

      { title: "Tag Control", to: "/governance/tag-control", childItems: [] },
    ],
  },
  {
    icon: lakehouse_grey,
    title: "LakeHouse",
    to: "lakehouse",
    childItems: [],
  },
  {
    icon: genz_grey,
    title: "GenZ",
    to: "genz",
    childItems: [],
  },
  {
    icon: admin_grey,
    title: "Admin",
    to: "",
    showChildSection: false,
    childItems: [
      { title: "Dashboard", to: "/admin/dashboard", childItems: [] },
      {
        title: "User, Groups & Permission",
        to: "admin/user-groups",
        childItems: [],
      },
      { title: "Query Logs", to: "/admin/query-log", childItems: [] },
      {
        title: "Authentication",
        to: "",
        showChildSection: false,
        childItems: [
          {
            title: "Single Sign On",
            to: "admin/sso",
            childItems: [],
          },
          {
            title: "LDAP",
            to: "",
            childItems: [],
          },
        ],
      },
      { title: "Resource Usage", to: "/admin/resource", childItems: [] },
    ],
  },
  {
    icon: tools_grey,
    title: "Tools",
    to: "tools",
    childItems: [],
  },
];

export const PlatformLeftMenuItems: any = [
  {
    icon: admin_grey,
    title: "",
    to: "",
    childItems: [],
  },
  {
    icon: governance_grey,
    title: "",
    to: "",
    childItems: [],
  },
];
