import { Space } from "./style-type"

interface ILoginFields {
    title: string
    weight: number
    textSize: Space
    inputSize: Space
    inputType: string
    isValid?: boolean,
    placeholder?: string,
    isTooltip?: boolean,
    tooltipText?: string
}

export const loginFields: ILoginFields[] = [
    {
        title: 'Username',
        weight: 200,
        textSize: 'small',
        inputSize: 'large',
        inputType: 'email'
    },
    {
        title: 'Password',
        weight: 200,
        textSize: 'small',
        inputSize: 'large',
        inputType: 'password'
    }
]

export const emailConfirmationFields: ILoginFields[] = [
    {
        title: 'Email',
        weight: 200,
        textSize: 'small',
        inputSize: 'large',
        inputType: 'email'
    }
]

export const passwordReset: ILoginFields[] = [
    {
        title: 'New Password',
        weight: 200,
        textSize: 'small',
        inputSize: 'large',
        inputType: 'password',
        isValid: false
    },
    {
        title: 'Confirm Password',
        weight: 200,
        textSize: 'small',
        inputSize: 'large',
        inputType: 'password',
        isValid: false
    }
]

export const platformOnboarding: ILoginFields[] = [
    {
        title: 'Administrator Username',
        weight: 200,
        textSize: 'small',
        inputSize: 'large',
        inputType: 'email',
        isValid: false,
        isTooltip: true,
        tooltipText: 'Administrator can perform platform configurations, like create and remove organisations.'
    },
    {
        title: 'Administrator Password',
        weight: 200,
        textSize: 'small',
        inputSize: 'large',
        inputType: 'password',
        isValid: false,
        isTooltip: true,
        tooltipText: 'Password must be a minimum length of 8, and contain at least 1 number, 1 special character ($@!%*?&), 1 lowercase and 1 uppercase letter.'
    },
    {
        title: '',
        weight: 200,
        textSize: 'small',
        inputSize: 'large',
        inputType: 'password',
        isValid: false,
        placeholder: 'confirm password'
    }
]

export const platformLoginFields: ILoginFields[] = [
    {
        title: 'Zetaris Administrator Username',
        weight: 200,
        textSize: 'small',
        inputSize: 'large',
        inputType: 'email'
    },
    {
        title: 'Zetaris Administrator Password',
        weight: 200,
        textSize: 'small',
        inputSize: 'large',
        inputType: 'password'
    }
]
